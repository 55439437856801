<template>
  <div>
    <div class="container">
      <div class="item" v-for="item in list" :key="item.apartmentId">
        <div class="top-tit">{{item.apartmentName}}</div>
        <div class="device" v-for="device in item.deviceSockets" :key="device.id">
          <div class="device-name">{{device.deviceName}}</div>
          <!-- 插座 -->
          <div class="sockets-outer">
              <div class="sockets" v-for="(socket,index) in device.sockets" :key="index">
                <!-- 模拟socketStatus 2 为 故障 -->
                <div class="sockets-item">
                  {{socket.socketNo}}
                </div>
              </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import {brokenSockets} from "@/api/device";
export default {
  data(){
    const route = useRoute();
    const params = route.query.deviceSns;
    return{
      list:[],
      params
    }
  },
  methods:{
    init(){
      console.log(this.params)
      brokenSockets(this.params).then(res =>{
        console.log(res.data)
        this.list = res.data;
      });
      //赋值 模拟数据结构
      let data = [
        {
          apartmentId: 2290,
          apartmentName: '景明佳园春景苑充电站',
          deviceList:[
            {
              id: 10890,
              deviceName:'景明佳园春景苑1号机',
              sockets:[
                {
                  socketStatus:2
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:0
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:2
                }
              ]
            },
            {
              id: 10891,
              deviceName:'景明佳园春景苑2号机',
              sockets:[
                {
                  socketStatus:0
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:0
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:0
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:2
                }
              ]
            },
          ]
        },
        {
          apartmentId: 2292,
          apartmentName: '景明佳园赋景苑充电站',
          deviceList:[
            {
              id: 10890,
              deviceName:'景明佳园赋景苑1号机',
              sockets:[
                {
                  socketStatus:0
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:0
                },
                {
                  socketStatus:0
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:2
                },
                {
                  socketStatus:0
                }
              ]
            }
          ]
        },
      ]
      // this.list = data;

    }
  },
  created(){
    //请求数据
    this.init();
  }

}
</script>

<style lang="scss" scoped>
.container{
  margin: 0 12px;
  padding-bottom: 80px;
  .item{
    border-radius: 8px;
    font-size: 12px;
    color: #333;
    border: 1px solid #e0e0e0;
    margin-top: 12px;
      font-weight: bold;

    .top-tit{
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      border-bottom: 1px solid #e0e0e0;
      padding-left: 12px;
    }
    .device{
      height: auto;
      margin: 0 12px;
      .device-name{
        font-size: 12px;
        height: 32px;
        line-height: 32px;
      }
      .sockets-outer{
         display: flex;
         flex-wrap: wrap;

        .sockets{
          .sockets-item{
            background-color:#ff3552;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border-radius: 16px;
            color: #fff;
            font-size: 12px;
            margin: 0 12px 12px 0;
          }
        }
      }
      
    }
    
  }
}  
</style>>
