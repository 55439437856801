import request from '../utils/request.js'
import { jsonp } from 'vue-jsonp'


// 查询省列表
export function listProvinceList() {
  return request({
    url: '/shared/geo/province/list',
    method: 'GET'
  })
}

// 查询市列表
export function listCityList(data) {
  return request({
    url: '/shared/geo/city/list',
    method: 'GET',
    params: data
  })
}

// 查询区列表
export function listCountyList(data) {
  return request({
    url: '/shared/geo/county/list',
    method: 'GET',
    params: data
  })
}
//逆地址解析
export function getLocation(data) {
  return jsonp("https://apis.map.qq.com/ws/geocoder/v1/", data)
}