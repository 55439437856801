<template>
  <div>
    <PullRefresh v-model="refreshing" @refresh="onRefresh">
      <List
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          v-if="!!list"
      >
        <div
            v-for="(item, index) in list"
            :key="item.cardId"
            style="margin-top: 12px"
            @click="clickItem(item, index)"
        >
          <!-- <van-icon name="passed" color='rgb(8, 223, 134)' size="25" style="margin:30px 20px "
                    v-if='currentIndex === index'
                    /> -->

          <div
              class="item-content"
              :class="currentIndex === index ? 'item-content-checked' : ''"
          >
            <div class="item-name">
              {{ item ? `卡号：${item.cardNo}` : null }}
            </div>
            <div class="item-name">
              {{ item ? `手机号：${item.phone}` : null }}
            </div>
            <div class="item-phone">
              <span class="count-text">{{
                  item.account.userAmount !== undefined
                      ? `账户余额：${item.account.userAmount / 100}`
                      : null
                }}</span>
              <span class="count-text">{{
                  item.account.presentAmount !== undefined
                      ? `赠送余额：${item.account.presentAmount / 100}`
                      : null
                }}</span>
            </div>
            <div class="item-phone">
              {{ item ? `所属人：${item.tag}` : null }}
            </div>
          </div>
        </div>
      </List>
      <Empty
          description="暂时没有数据"
          v-if="(!list || list.length === 0) && !loading"
      />
      <div class="button-wrapper" @click="handleSurplus(itemContent)" v-if="isOperatorShow">
        <van-button v-if="visibleButton" type="primary" block class="button"
        >余额增减
        </van-button
        >
      </div>
    </PullRefresh>
  </div>
  <Dialog v-model:show="show" title="标题" show-cancel-button></Dialog>
</template>

<script lang="jsx">
import {onMounted, ref} from "vue";
import {Card, Cell, CellGroup, Dialog, Field, List, PullRefresh} from "vant";
import {queryCardList, reloadCardBalance,} from "@/api/consumer";
import {getAgentInfo} from "@/api/stat";

export default {
  components: {
    List,
    Cell,
    Card,
    Field,
    CellGroup,
    PullRefresh,
    [Dialog.Component.name]: Dialog.Component,
  },

  setup() {
    const isOperatorShow = ref(false);
    const show = ref(false);
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const visibleButton = ref(false);
    const itemContent = ref("");
    const totalNum = ref(0);
    const currentIndex = ref(-1);
    const params = {
      pageSize: 10,
      pageNum: 0,
    };
    onMounted(() => {
      getAgentInfo().then(res => {
        if (res.data.isOperator) {
          isOperatorShow.value = true;
        }
      })
    });
    const queryList = (params) => {
      queryCardList(params)
          .then((res) => {
            if (res.code === 200) {
              loading.value = false;
              totalNum.value = res.total;
              list.value = list.value.concat(res.rows);
              if (list.value.length >= totalNum.value) {
                finished.value = true;
              }
            }
          })
          .catch((err) => {
          });
    };

    const onLoad = () => {
      setTimeout(() => {
        if (refreshing.value) {
          refreshing.value = false;
        }
        params.pageNum += 1;
        queryList(params);
      }, 300);
    };

    const onRefresh = (arg) => {
      visibleButton.value = false;
      currentIndex.value = -1;
      // 清空列表数据
      finished.value = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      if (!arg) {
        list.value = [];
        params.pageNum = 0;
        onLoad();
      } else {
        list.value = [];
        console.log("电卡用户搜索", arg);
        let params = {cardNo: Number(arg)};
        queryList(params);
      }
    };

    const clickItem = (item, index) => {
      visibleButton.value = true;
      itemContent.value = item;
      currentIndex.value = index;
    };

    const handleSurplus = (item) => {
      show.value = true;
      let inputContent = ["", ""];

      Dialog.confirm({
        messageAlign: "left",
        allowHtml: "true",
        message: () => {
          return (<div>
                <div>卡号：{item.cardNo}</div>
                <div style="margin-top:10px">手机号：{item.phone}</div>
                <div style="margin-top:10px"> 所属人：{item.tag}</div>
                <div style="margin-top:10px">
                  账户余额：
                  <input
                      id="account"
                      autocomplete="off"
                      style="border:0px; border-bottom:0.5px gray solid;margin-left:10px;width:100px; text-align:center "
                      type="number"
                      // value= {item.account.totalAmount}
                      value={0}
                      onBlur={(e) => {
                        inputContent[0] = Number(e.target.value);
                      }}
                  />
                  元
                </div>
                <div style="margin-top:10px">
                  赠送余额：
                  <input
                      style="border:0px ; border-bottom:0.5px gray solid;margin-left:10px;width:100px; text-align:center"
                      autocomplete="off"
                      type="number"
                      // value= {item.account.presentAmount}
                      value={0}
                      onBlur={(e) => {
                        inputContent[1] = Number(e.target.value);
                      }}
                  />{" "}
                  元
                </div>
              </div>
          );
        },
      })
          .then((args) => {
            // on confirm
            visibleButton.value = false;
            currentIndex.value = -1;

            if (inputContent[0] === "") {
              inputContent[0] = 0;
            }
            if (inputContent[1] === "") {
              inputContent[1] = 0;
            }

            let data = {
              balance: inputContent[0] * 100,
              cardNo: item.cardNo,
              distributorId: item.distributorId,
              giftBalance: inputContent[1] * 100,
            };

            reloadCardBalance(data)
                .then((res) => {
                  if (res.code === 200) {
                    item.account.userAmount =
                        item.account.userAmount + inputContent[0] * 100;
                    item.account.presentAmount =
                        item.account.presentAmount + inputContent[1] * 100;
                  }
                })
                .catch(() => {
                });
          })
          .catch(() => {
            // on cancel
            visibleButton.value = false;
            currentIndex.value = -1;
          });
    };

    return {
      visibleButton,
      show,
      list,
      onLoad,
      loading,
      finished,
      onRefresh,
      refreshing,
      clickItem,
      handleSurplus,
      itemContent,
      currentIndex,
      isOperatorShow
    };
  },
};
</script>

<style lang="scss" scoped>
.item-content {
  position: relative;
  padding: 12px;
  margin: 0 12px 12px 12px;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 25%);
  border-radius: 5px;
  /* border-bottom: 0.5px solid rgb(196, 191, 191); */
  .item-name {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
  }

  .item-phone {
    font-size: 13px;
    color: #666666;
    margin-top: 8px;
  }
}

.item-content-checked {
  background-color: #00d655;

  .item-name {
    color: #fff;
  }

  .item-phone {
    color: #fff;
  }
}

.cell-wrapper {
  display: flex;
}

.cell-content {
  margin-left: 20px;
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  margin: 5px 0;
  width: 200px;
}

.surplus-wrapper {
  display: flex;
  // justify-content: space-around;
}

.count-text {
  font-size: 14px;
  margin-right: 20px;
  // color:rgb(132, 133, 138)
}

.attribute-text {
  color: black;
  font-size: 14px;
  margin: 5px 0;
}

.button-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.button {
  background-color: rgb(8, 223, 134);
  border: 0px black solid;
  color: white;
}

.input-wrapper {
  border: 0px black solid;
}
</style>