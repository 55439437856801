import {createRouter, createWebHistory} from "vue-router";
import login from "../views/Login/login";
import Index from "../views/Index/index";
import addStation from "../views/rechargeStation/createStation";
import user from "../views/user/index";
import client from "../views/clientManage";
import addClient from "../views/clientManage/addClient";
import distributionStation from '../views/clientManage/distributionStation'
import consumer from '../views/consumerManage';
import rechargeStation from "@/views/rechargeStation";
import priceTemplate from "@/views/priceTemplate";
import programmeAdd from "@/views/priceTemplate/programmeAdd/programmeAdd";
import ladderAdd from "@/views/priceTemplate/ladderAdd/ladderAdd";
import schemeAdd from "@/views/priceTemplate/schemeAdd/schemeAdd";
import rechargeStationAdd from "@/views/rechargeStation/createStation";
import brokenStation from "@/views/rechargeStation/brokenStation";
import withDrawal from "@/views/user/withdrawal/index";
import withDrawalAdd from "@/views/user/withdrawal/add";
import withDrawalList from "@/views/withdrawalList/index";
import withDrawalDetail from "@/views/withdrawalList/detail";
import OrderList from "@/views/Order";
import BlackList from "@/views/rechargeStation/blackList";
import addBlackList from "@/views/rechargeStation/addBlackList";
import OrderDetail from "@/views/Order/orderDetail";
import FinancialDetails from "@/views/withdrawalList/financialDetails";
import DeviceList from '@/views/Device'
import DeviceDetail from "@/views/Device/deviceDetail";
import DeviceEdit from "@/views/Device/deviceEdit";
import DeviceReplace from "@/views/DeviceReplace";
import DeviceBind from "@/views/DeviceBind";
import UpdateLocation from "@/views/DeviceBind/updateLocation";
import withdrawAccount from "@/views/withdrawAccount/index";
import withdrawAccountAdd from "@/views/withdrawAccount/add";
import updateRechargeStation from "@/views/rechargeStation/update";
import schemeApplication from "@/views/priceTemplate/schemeApplication";
import ladderUpdate from "@/views/priceTemplate/ladderAdd/ladderUpdate";
import schemeUpdate from "@/views/priceTemplate/schemeAdd/schemeUpdate";
import programmeUpdate from "@/views/priceTemplate/programmeAdd/programmeUpdate";
import More from "@/views/More/index";
import renewal from "@/views/withdrawalList/renewal";//renewal
import ensurePay from "@/views/withdrawalList/ensurePay";//ensurePay




const routes = [
    {
        path: "/",
        name: "index",
        component: Index,
        meta: {
            title: "首页",
        },
    },
    {
        path: "/renewal",
        name: "renewal",
        component: renewal,
        meta: {
            title: "设备续费",
        },
    },
    {
        path: "/ensurePay",
        name: "ensurePay",
        component: ensurePay,
        meta: {
            title: "确认支付",
        },
    },
    {
        path: "/more",
        name: "more",
        component: More,
        meta: {
            title: "更多",
        },
    },
    {
        path: "/login",
        name: "login",
        component: login,
        meta: {
            title: "登录",
        },
    },
    {
        path: "/addStation",
        name: "addStation",
        component: addStation,
        meta: {
            title: "创建充电站",
        },
    },
    {
        path: "/user",
        name: "user",
        component: user,
        meta: {
            title: "我的",
        },
    },
    {
        path: '/client',
        name: 'client',
        component: client,
        meta: {
            title: '客户管理'
        }
    },
    {
        path: '/client/add',
        name: 'addClient',
        component: addClient,
        meta: {
            title: '新增客户'
        }
    },
    {
        path: '/client/distributionStation/:id',
        name: 'distributionStation',
        component: distributionStation,
        meta: {
            title: '客户管理'
        }
    },
    {
        path: '/consumer',
        name: 'consumer',
        component: consumer,
        meta: {
            title: '用户管理'
        }
    },
    {
        path: "/user/withdrawal",
        name: "userWithdrawal",
        component: withDrawal,
        meta: {
            title: "提现",
        },
    },
    {
        path: "/withdrawAccount",
        name: "withdrawAccount",
        component: withdrawAccount,
        meta: {
            title: "提现账号",
        },
    },
    {
        path: "/withdrawAccountAdd",
        name: "withdrawAccountAdd",
        component: withdrawAccountAdd,
        beforeEnter(to, from, next) {
            if (to.query.type === 'modify') {
                to.meta.title = "修改提现账号";
            } else {
                to.meta.title = "新增提现账号";
            }
            next()
        },
    },
    {
        path: "/user/withdrawal/add",
        name: "userWithdrawalAdd",
        component: withDrawalAdd,
        meta: {
            title: "提现账号",
        },
    },
    {
        path: "/withdrawal",
        name: "withdrawal",
        component: withDrawalList,
        meta: {
            title: "提现列表",
        },
    },
    {
        path: "/withdrawal/detail",
        name: "withdrawalDetail",
        component: withDrawalDetail,
        meta: {
            title: "提现详情",
        },
    },
    {
        path: "/rechargeStation",
        name: "rechargeStation",
        component: rechargeStation,
        meta: {
            title: "充电站",
        },
    },
    {
        path: "/brokenStation",
        name: "brokenStation",
        component: brokenStation,
        meta: {
            title: "故障插座",
        },
    },
    {
        path: "/rechargeStation/add",
        name: "rechargeStationAdd",
        component: rechargeStationAdd,
        meta: {
            title: "添加充电站",
        },
    }, {
        path: "/rechargeStation/update",
        name: "updateRechargeStation",
        component: updateRechargeStation,
        meta: {
            title: "修改充电站",
        },
    },
    {
        path: "/rechargeStation/blackList",
        name: "blackList",
        component: BlackList,
        meta: {
            title: "黑白名单",
        },
    },
    {
        path: "/rechargeStation/addBlackList",
        name: "addBlackList",
        component: addBlackList,
        beforeEnter(to,from,next){
            let type = to.query.type;
            if (type === '0') {
              to.meta.title ="新增用户白名单";
            } else if(type === '1'){
              to.meta.title="新增用户黑名单";
            } else if(type === '2'){
              to.meta.title="新增电卡白名单";
            } else if(type === '3'){
              to.meta.title="新增电卡黑名单";
            } else if(type === '4'){
              to.meta.title="新增用户免费充电名单";
            } else if(type === '5'){
              to.meta.title="新增电卡免费充电名单";
            }
            next()
        },
    },
    {
        path: "/order/list",
        name: "orderList",
        component: OrderList,
        meta: {
            title: "订单管理",
        },
    },
    {
        path: "/order/detail",
        name: "orderDetail",
        component: OrderDetail,
        meta: {
            title: "订单详情",
        },
    },
    {
        path: "/withdrawal/financialDetails",
        name: "financialDetails",
        component: FinancialDetails,
        meta: {
            title: "财务明细",
        },
    }, {
        path: "/device/list",
        name: "deviceList",
        component: DeviceList,
        meta: {
            title: "设备管理",
        },
    }, {
        path: "/device/detail",
        name: "deviceDetail",
        component: DeviceDetail,
        meta: {
            title: "设备管理",
        },
    },
    {
        path: "/device/edit",
        name: "deviceEdit",
        component: DeviceEdit,
        meta: {
            title: "设备更改",
        },
    },

    {
        path: '/priceTemplate',
        name: 'priceTemplate',
        component: priceTemplate,
        meta: {
            title: '价格管理'
        }
    },
    {
        path: '/programmeAdd',
        name: 'programmeAdd',
        component: programmeAdd,
        meta: {
            title: '添加模板'
        }
    },{
        path: '/programmeUpdate',
        name: 'programmeUpdate',
        component: programmeUpdate,
        meta: {
            title: '修改模板'
        }
    }, {
        path: '/ladderAdd',
        name: 'ladderAdd',
        component: ladderAdd,
        meta: {
            title: '添加阶梯'
        }
    },{
        path: '/ladderUpdate',
        name: 'ladderUpdate',
        component: ladderUpdate,
        meta: {
            title: '修改阶梯组'
        }
    },
    {
        path: '/schemeAdd',
        name: 'schemeAdd',
        component: schemeAdd,
        meta: {
            title: '添加方案'
        }
    },
    {
        path: '/schemeUpdate',
        name: 'schemeUpdate',
        component: schemeUpdate,
        meta: {
            title: '修改方案'
        }
    },
    {
        path: '/schemeApplication',
        name: 'schemeApplication',
        component: schemeApplication,
        meta: {
            title: '方案应用'
        }
    },
    {
        path: "/user/withdrawal",
        name: "userWithdrawal",
        component: withDrawal,
        meta: {
            title: "提现",
        },
    },
    {
        path: "/user/withdrawal/add",
        name: "userWithdrawalAdd",
        component: withDrawalAdd,
        meta: {
            title: "提现账号",
        },
    },
    {
        path: "/withdrawal",
        name: "withdrawal",
        component: withDrawalList,
        meta: {
            title: "提现列表",
        },
    },
    {
        path: "/withdrawal/detail",
        name: "withdrawalDetail",
        component: withDrawalDetail,
        meta: {
            title: "提现详情",
        },
    },
    {
        path: '/deviceReplace',
        name: 'deviceReplace',
        component: DeviceReplace,
        meta: {
            title: '替换设备'
        }
    }, {
        path: '/deviceBind',
        name: 'deviceBind',
        component: DeviceBind,
        meta: {
            title: '绑定设备'
        }
    },
    {
      path: '/UpdateLocation',
      name: 'UpdateLocation',
      component: UpdateLocation,
      meta: {
          title: '更新定位'
      }
  }
    
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
