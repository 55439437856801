<template>
  <div>
    <div class="logo">
      <div v-if="showOther">
        <van-image
          width="140px"
          height="140px"
          :src="xiaolvSrc"
        />
      </div>
      <div v-else-if="showWangu">
        <van-image
          width="140px"
          height="140px"
          :src="wanguSrc"
        />
      </div>
      <div v-else>
        <van-image
          width="140px"
          height="140px"
          :src="logoSrc"
        />
      </div>
       
    </div>
   
<!--    <van-form @submit="onSubmit">-->
<!--      <van-cell-group inset>-->
<!--        <van-field-->
<!--          v-model="phone"-->
<!--          name="phone"-->
<!--          left-icon="manager"-->
<!--          placeholder="账号"-->
<!--          type="tel"-->
<!--          :rules="[{ required: true, message: '请填写账号' }]"-->
<!--        />-->
<!--        <van-field-->
<!--          v-model="password"-->
<!--          type="password"-->
<!--          name="password"-->
<!--          left-icon="lock"-->
<!--          placeholder="密码"-->
<!--          :rules="[{ required: true, message: '请填写密码' }]"-->
<!--        />-->
<!--        <van-field-->
<!--          v-model="role"-->
<!--          is-link-->
<!--          readonly-->
<!--          name="role"-->
<!--          left-icon="wap-nav"-->
<!--          placeholder="点击选择角色"-->
<!--          @click="showPicker = true"-->
<!--        />-->
<!--        <van-popup v-model:show="showPicker" position="bottom">-->
<!--          <van-picker :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />-->
<!--        </van-popup>-->
<!--      </van-cell-group>-->
<!--      <div style="margin: 16px;">-->
<!--        <van-button round block :disabled="disabled" color="#00d655" native-type="submit">登录</van-button>-->
<!--      </div>-->
<!--    </van-form>-->
  </div>
</template>

<script>

import {onMounted, ref} from 'vue';
import {useRoute, useRouter} from "vue-router";
import {login} from "@/api/account";

export default {
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const phone = ref('');
    const password = ref('');
    const columns = ['代理商', '物业', '合伙人'];
    const role = ref('代理商');
    const showPicker = ref(false);

    onMounted(() => {
      if (route.query.token){
        login(route.query.token).then(res => {
          if (res.code == 200) {
            //token
            window.localStorage.setItem('accessToken', res.token);
            router.replace({
              path: "/"
            });
          }
        }).catch(err => { });
       window.localStorage.setItem('channelUserId', route.query.channelUserId);

      }

     
    })

    const onSubmit = (values) => {
      let userType = 1;

      console.log('submit', values);
      switch (values.role) {
        case '代理商':
          userType = 1;
          break;
        case '物业':
          userType = 2;
          break;
        case '合伙人':
          userType = 4;
          break;
        default:
          userType = 1;
      }
      let data = {
        phone: values.phone,
        userType: userType,
        password: values.password
      }
      let time = new Date().getTime();
      let _this = this;


      };

    const onConfirm = (value) => {
      role.value = value;
      showPicker.value = false;
    };

    return {
      phone,
      password,
      onSubmit,
      columns,
      role,
      showPicker,
      onConfirm,
    };
  },
  data(){
    return{
      showOther: false,
      showWangu: false,
      logoSrc: require("@/assets/logo.png"),
      xiaolvSrc: require("@/assets/loginLogo.jpeg"),
      wanguSrc: require("@/assets/wangu.jpeg"),
    }
  },
  mounted(){
    if(this.$route.query.brand === "xiaolv"){
      this.showOther = true;
    }else if(this.$route.query.brand === "wangu"){
      this.showWangu = true;
    }
  },
  computed: {
    disabled: function () {
      let bool = true;
      if (this.phone && this.password) {
        bool = false;
      }
      return bool;
    }
  },
}
</script>

<style lang="scss" scoped>
.logo{
  .van-image{
    display: block;
    margin: 140px auto 60px auto;
  }
}
</style>