<template>
  <div class="container">
    <Tabs v-model:active="active" @change="changeTabs">
      <Tab title="基础详情">
        <div style="height: 6px"/>
        <div style="padding: 0 6px;">
          <div class="content-item">
            <div class="item-title">
              <div>设备信息</div>
              <van-button color="#00d655" size="small" style="border-radius: 5px" @click="modifyDevice(item)" v-if="userType">更改
              </van-button>
            </div>
            <Divider style="margin: 5px 0 5px 0"/>
            <div class="item-content">
              <div class="item-content-s">
                <div>
                  <div class="item-content-title">
                    {{ item.deviceName }}
                  </div>
                  <div class="item-content-address">
                    {{ item.apartmentName }}
                  </div>
                  <div class="item-content-address">
                    {{ item.provinceName }}{{ item.cityName }}{{ item.countyName }}{{ item.address }}
                  </div>
                </div>
                <div>
                  信号{{ item.signalPower }} 温度42
                </div>
              </div>
              <div class="item-content-address" style="color:#999999">
                设备编号：{{ item.deviceSn }} {{ item.createTime }}
              </div>
            </div>
          </div>
        </div>
        <div style="height: 6px"/>
        <div style="padding: 0 6px;">
          <div class="content-item">
            <div class="item-title">
              <div>设备状态</div>
              <Switch v-model="deviceSwitch" size="24px" active-color="#00d655" @change="changeStatus(item)" v-if="userType" :disabled="open"/>
            </div>
            <Divider style="margin: 5px 0 5px 0"/>
            <div class="device-content">
              <div v-for="item in deviceStatusList" :class=" item.isDel?'device-item device-item-gray':item.socketStatus == 0?
              'device-item device-item-green':
              item.socketStatus == 1 ? 'device-item device-item-orange':item.socketStatus == 2 ? 'device-item device-item-red':'device-item device-item-gray'
                " @click="showPick(item.id)">
                {{ item.socketNo }}
              </div>
            </div>
            <Popup v-model:show="showPicker" position="bottom">

              <Picker
                  title="标题"
                  :columns="columns"
                  @confirm="onConfirm"
                  @cancel="onCancel"
                  @change="onChange"
              />
            </Popup>
          </div>
        </div>
        <div style="height: 6px"/>
<!--        <div style="padding: 0 6px;">-->
<!--          <div class="content-item" v-if="userType">-->
<!--            <div class="item-title">-->
<!--              <div>价格方案</div>-->
<!--              <van-button color="#00d655" size="small" style="border-radius: 5px" @click="onClick(item)">更改</van-button>-->
<!--            </div>-->
<!--            <Divider style="margin: 5px 0 5px 0"/>-->
<!--            <div v-if="item.combId == 0" class="item-content">-->
<!--              <div class="item-content-s">-->
<!--                <div>-->
<!--                  <div class="item-content-title">-->
<!--                    方案名称：使用充电站设价-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-if="item.combId != 0" class="item-content">-->
<!--              <div class="item-content-s">-->
<!--                <div>-->
<!--                  <div class="item-content-title">-->
<!--                    方案名称：{{ combName }}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </Tab>

      <Tab title="分润详情">
        <div style="margin-top: 5px; padding: 0 12px;">
          <ListPage @register="register">
            <template #item="{dataList}">
              <div :key="index" v-for="(item,index) in dataList">
                <!-- <SwipeCell> -->
                  <div class="outer">

                 
                  <div class="item-content-fenrun">
                    <div class="item-content-title-fenrun">
                      {{ item.username }}
                    </div>
                    <div class="item-content-address-fenrun" v-if="item.userType===0">
                      角色：运营商 占比：{{
                        Math.ceil(item.sharePercent * 100) 
                      }}%
                    </div>
                    <div class="item-content-address-fenrun" v-else-if="item.userType===1">
                      角色：代理商 占比：{{
                        Math.ceil(item.sharePercent * 100)
                      }}%
                    </div>
                    <div class="item-content-address-fenrun" v-else-if="item.userType===2">
                      角色：物业 占比：{{
                         Math.ceil(item.sharePercent * 100)
                      }}%
                    </div>
                    <div class="item-content-address-fenrun" v-else-if="item.userType===3">
                      角色：服务商 占比：{{
                        Math.ceil(item.sharePercent * 100)
                      }}%
                    </div>
                    <div class="item-content-address-fenrun" v-else-if="item.userType===4">
                      角色：合伙人 占比：{{
                        Math.ceil(item.sharePercent * 100)
                      }}%
                    </div>
                  </div>
                  <!-- <template #right>
                    <div class="item-btn" v-if="userType">
                      <van-button square text="编辑" type="success" class="delete-button" @click="edit(index,item)"/>
                      <van-button square text="删除" type="danger" @click="deleteRow(index)" class="delete-button"/>
                    </div>
                  </template> -->
                <!-- </SwipeCell> -->
                 <div class="right"  v-if="userType">
                   <!-- <div class="icon">
                      <van-icon name="edit" size="32" color="#00d655" @click="edit(index,item)" />

                   </div> -->
                   <div class="icon">
                      <van-icon name="delete-o" size="32" color="#ff3552" @click="deleteRow(index)"/>
                   </div>
                   
                  </div>
                   </div>
                <div style="height: 8px"></div>
              </div>
            </template>
          </ListPage>
        </div>
        <div style="position: fixed;height: 60px;bottom: 30px;right: 30px;">
          <div style="display:flex;justify-content:space-between;width: 87vw; " v-if="userType">
            <van-button color="#00d655" size="small" style="flex: 1;height: 40px;margin: 0 12px;border-radius: 12px;"
                        @click="save">保存
            </van-button>
            <van-button type="primary" size="small" style="flex: 1;height: 40px;margin: 0 12px;border-radius: 12px;"
                        @click="add">新增
            </van-button>
          </div>
          <van-divider v-if="userType">修改数据后点击保存生效</van-divider>

        </div>

        <van-popup
            v-model:show="showAdd"
            closeable
            position="bottom"
            :style="{ height: '30%' }"
        >
          <div class="pop-content">
            <Field
                v-model="person"
                is-link
                readonly
                label="分润人"
                placeholder="选择分润人"
                @click="showPersonClick"
            />
            <van-popup v-model:show="showPerson" round position="bottom">
              <van-picker
                  :columns="ownerList"
                  @confirm="personConfirm"
                  @cancel="showPerson = false"
              />
            </van-popup>

            <Field
                v-model="customerNum"
                type="digit"
                label="分润比例(%)"
                placeholder="请输入分润比例"
                :rules="[{ required: true, message: '请输入分润比例且为正整数' }]"
            />
            <van-button v-show="showAddBtn" round type="primary" class="pop-btn" @click="addShareList">
              提交
            </van-button>
            <van-button v-show="showEditBtn" round type="primary" class="pop-btn" @click="editShareList">
              提交
            </van-button>
          </div>
        </van-popup>
      </Tab>
    </Tabs>

    <van-dialog v-model:show="show" title="修改方案" @confirm="updatePlan" show-cancel-button>

      <van-radio-group v-model="checked" @change="changeChecked">
        <van-radio name="1">使用充电站设价</van-radio>
        <van-radio name="2">独立定价</van-radio>
      </van-radio-group>

      <FieldPopup v-if="checked == '2'" label="价格方案" show-text="text" back-val="id" :data="combList"
                  @change="changePlan"/>
    </van-dialog>


  </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {ref, unref, onMounted, nextTick} from 'vue';
import {Divider, Switch, Tab, Tabs, Picker, Toast, Popup, SwipeCell, Dialog, Field, Notify} from 'vant';
import {ListPage, useListPage} from "@/components/ListPage";
import {
  socketPage,
  socketUpdate,
  pageComb,
  getAgentInfo,
  partnerList,
  pageEstate,
  listPartner,
  listEstate,
  listBroker,
  listAgent,
  modify,
  updateDevice,
  changeActiveStatus,
  deviceProfit,
  deviceStepTpl,
  getStationTpl
} from "@/api/device";
import FieldPopup from "@/components/FieldPopup";
import {chargeStationList} from "@/api/client";

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
    Divider,
    Switch,
    Tab,
    Tabs,
    Picker,
    Popup,
    SwipeCell,
    Dialog,
    ListPage,
    Field,
    FieldPopup
  },
  setup(props, {emit}) {
    const router = useRouter();
    const route = useRoute()
    const item = ref(JSON.parse(route.query.item));
    const id = ref(JSON.parse(route.query.item).id)
    const deviceSwitch = ref()
    const active = ref(0)
    const showPicker = ref(false)
    const apiUrl = 'profit/share/device/' + item.value.deviceSn;
    const showAdd = ref(false)
    const showPerson = ref(false)
    const socketID = ref(null);
    const combList = ref([]);
    const combName = ref('');
    const show = ref(false);
    const checked = ref('1');
    const shareList = ref([]);
    const customerNum = ref(0);
    const person = ref('');
    const showAddBtn = ref(false);
    const showEditBtn = ref(false);
    const choosePerson = ref({});
    const editItem = ref({});
    const newItemIndex = ref(-1);
    const userType = ref();
    const open = ref(false);

    const allOwnerList = ref([]);
    const ownerList = ref([]);
    const agentInfo = ref({
      merchantUser: {}
    });

    const [register, {
      setProps,
      queryData,
      resetData,
      deleteItem,
      getAllData,
      addItem,
      delItem,
      getItem,
      replaceItem
    }] = useListPage({
      apiUrl: apiUrl,
      pageSize: 10000,
      requestParam: {}
    });
    const deviceStatusList = ref([]);

    onMounted(() => {
      pageData();
      getcombList();
      getUserInfo();
      deviceSwitch.value = item.value.isPub;
      getAgentInfo().then(res => {
        if (res.data.businessUserType == 'ESTATE') {
          userType.value = false;
        } else if (res.data.businessUserType == 'PARTNER') {
          userType.value = false;
        } else {
          userType.value = true;
        }
      })



    });
    const pageData = () => {
      const query = {
        deviceSn: item.value.deviceSn
      }
      socketPage(query).then(res => {
        deviceStatusList.value = res.rows;
      })
    }

    const changeStatus = (item) => {
      if (item.apartmentId==null || item.apartmentId==0){
        open.value=true;
        deviceSwitch.value = item.isPub;
        Notify({type: 'danger', message: '该设备未绑定充电站,请绑定后再启用 !'});
        return
      }
      if (item.activeStatus === 0) {
        deviceSwitch.value = item.isPub;
        Notify({type: 'danger', message: '该设备未激活,请激活后再启用 !'});
        open.value=true;
        return
      }else {
        deviceProfit(item.deviceSn).then(res=>{
          if(res.rows.length<1) {
            open.value=true;
            deviceSwitch.value = item.isPub;
            Notify({type: 'danger', message: '该设备尚未设置分润，请设置分润后再启用'});
            return
          }else {
            getStationTpl(item.apartmentId).then(res=>{
              if (res.data.id==null){
                open.value=true;
                deviceSwitch.value = item.isPub;
                Notify({type: 'danger', message: '该充电站尚未设置消费价格，请设置消费价格后再启用'});
                return
              }else {
                changeActiveStatus({
                  id: id.value,
                  isPub: deviceSwitch.value
                }).then(res => {
                  if (res.code == 200) {
                    Notify({type: 'success', message: '修改成功'});
                  } else {
                    Notify({type: 'danger', message: '修改失败'});
                  }
                });
              }
            });
          }
        })
      }

     /* if (item.deviceStatus === 0) {
        console.log(item.isPub)
        deviceSwitch.value = item.isPub;
        Notify({type: 'danger', message: '该设备未激活,请激活后再启用 !'});
      } else {
        changeActiveStatus({
          id: id.value,
          isPub: deviceSwitch.value
        }).then(res => {
          if (res.code == 200) {
            Notify({type: 'success', message: '修改成功'});
          } else {
            Notify({type: 'danger', message: '修改失败'});
          }
        });
      }*/
    }

    const getCombName = () => {
      combList.value.forEach(comb => {
        if (comb.id == item.value.combId) {
          combName.value = comb.combName;
        }
      })
    }

    const changePlan = (value) => {
      item.value.combId = value;
    }

    const updatePlan = () => {
      if (checked.value == '1') {
        item.value.combId = 0;
      }
      updateDevice(item.value).then(res => {
        if (res.code == 200) {
          Notify({type: 'success', message: '修改成功'});
          router.push({path: '/device/list'});
        } else {
          Notify({type: 'danger', message: '修改失败'});
        }
      })
    }

    const changeTabs = (index) => {
      if (index === 1) {
        nextTick(() => {
          queryData();
          setTimeout(() => {
            shareList.value = getAllData();
          }, 500);
        });
      }
    }

    const deleteRow = (index) => {
      Dialog.confirm({
        title: '提示',
        message:
          '确认删除此条数据？',
      })
        .then(() => {
          delItem(index);
        })
        .catch(() => {
          // on cancel
        });
      
    }
    const columns = ['设为正常', '设为故障', '关闭端口', '打开端口'];

    const onConfirm = (value, index) => {
      // Toast(`当前值: ${value}, 当前索引: ${index}`);
      let query = {};
      if (index == 0) {
        query = {
          socketStatus: 0,
          socketIds: [socketID.value]
        }
      } else if (index == 1) {
        query = {
          socketStatus: 2,
          socketIds: [socketID.value]
        }
      } else if (index == 2) {
        query = {
          isDel: true,
          socketIds: [socketID.value]
        }
      } else if (index == 3) {
        query = {
          isDel: false,
          socketIds: [socketID.value]
        }
      }
      showPicker.value = false;
      socketUpdate(query).then(res => {
        if (res.code == 200) {
          Notify({type: 'success', message: '修改成功'});
        } else {
          Notify({type: 'danger', message: '修改失败'});
        }
      }).then(pageData);

    };
    const onChange = (value, index) => {
      // Toast(`当前值: ${value}, 当前索引: ${index}`);
    };

    const changeChecked = () => {
      if (checked.value == 1) {
        chargeStationList({apartmentName: item.apartmentName}).then(response => {
          for (let i = 0; i < response.rows.length; i++) {
            if (response.rows[i].combId == 0) {
              Notify({type: 'danger', message: '该充电站尚未设置价格方案,请设置后再试 !'});
            }
          }
        })
      }
    }

    const onClick = (item) => {
      if (item.combId == 0) {
        checked.value = '1';
      } else {
        checked.value = '2';
      }
      show.value = true;
    };
    const onCancel = () => {
      showPicker.value = false;
    };

    const showPick = (id) => {
      if (userType.value){
        socketID.value = id;
        showPicker.value = true;
      }else {
        socketID.value = id;
        showPicker.value = false;
      }
    }
    const getcombList = () => {
      let applyType;
      if (item.value.deviceTypeName=='汽车充电桩'){
        applyType=1
      }
      const query = {
        pageNum: 1,
        pageSize: 10000,
        combName: null,
        applyType: applyType
      };
      if (!userType.value){
        // pageComb(query).then(res => {
        //   combList.value = res.rows;
        //   combList.value.forEach(comb => {
        //     comb.text = comb.combName;
        //   })
        // }).then(getCombName)
      }
    }


    const showPersonClick = () => {
      showPerson.value = true;
    }

    const personConfirm = (value, index) => {
      person.value = ownerList.value[index].text;
      choosePerson.value = ownerList.value[index];
      showPerson.value = false;
    }

    const save = () => {
      let percent = 0;
      shareList.value = getAllData();
      shareList.value.forEach(share => {
        percent += share.sharePercent;
      })
      if (percent != 1) {
        Dialog.alert({
          message: '保存失败，设置分润总和不为100%',
        }).then(() => {
          // on close
        });
        return;
      }
      //
      let params = {
        deviceSn: item.value.deviceSn,
        sharePercentTuple: shareList.value
      }
      modify(params).then(res => {
        if (res.code == 200) {
          Notify({type: 'success', message: '修改成功'});
        } else {
          Notify({type: 'danger', message: '修改失败'});
        }
      })

    }

    const getUserInfo = () => {
      getAgentInfo().then(res => {
        agentInfo.value = res.data
        getOwnerList()
      })
    }
    //获取下拉框数据
    const getOwnerList = async () => {
      let username;
      let phone;
      let ownerId;
      let ownerType;
      let label;
      await getAgentInfo().then(res => {
        username = res.data.businessUserName;
        phone = res.data.merchantUser.phone;
        ownerId = res.data.businessUserId;
        if (res.data.businessUserType == 'ADMIN') {
          ownerType = 1;
          label = '运营'
        } else if (res.data.businessUserType == 'AGENT') {
          ownerType = 1;
          label = '代理'
        } else if (res.data.businessUserType == 'ESTATE') {
          ownerType = 2;
          label = '物业'
        } else if (res.data.businessUserType == 'BROKER') {
          ownerType = 3;
          label = '服务商'
        } else if (res.data.businessUserType == 'PARTNER') {
          ownerType = 4;
          label = '合伙人'
        }
      })
      let allOwnerList = [{
        username: '平台',
        phone: '',
        ownerId: 0,
        ownerType: 0,
        label: '自营'
      }]
      await listAgent({pageSize: 10000, pageNum: 0}).then(response => {
        response.rows.forEach(it => allOwnerList.push({
          username: it.username,
          phone: it.phone,
          ownerId: it.id,
          ownerType: 1,
          label: '代理'
        }))
      })
      await listBroker({pageSize: 10000, pageNum: 0}).then(response => {
        response.rows.forEach(it => allOwnerList.push({
          username: it.username,
          phone: it.phone,
          ownerId: it.id,
          ownerType: 3,
          label: '服务商'
        }))
      })
      await listEstate({pageSize: 10000, pageNum: 0}).then(response => {
        response.rows.forEach(it => allOwnerList.push({
          username: it.username,
          phone: it.phone,
          ownerId: it.id,
          ownerType: 2,
          label: '物业'
        }))
      })
      await listPartner({pageSize: 10000, pageNum: 0}).then(response => {
        response.rows.forEach(it => allOwnerList.push({
          username: it.username,
          phone: it.phone,
          ownerId: it.id,
          ownerType: 4,
          label: '合伙人'
        }))
      })
      allOwnerList.value = allOwnerList
      let owner = [{
        username: '平台',
        phone: '',
        ownerId: 0,
        ownerType: 0,
        label: '自营'
      }, {
        username: agentInfo.value.businessUserName,
        phone: agentInfo.value.merchantUser ? agentInfo.value.merchantUser.phone : '',
        ownerId: agentInfo.value.businessUserId,
        ownerType: 1,
        label: '代理'
      }]
      await pageEstate({pageSize: 10000, pageNum: 0}).then(response => {
        response.rows.forEach(it => owner.push({
          username: it.username,
          phone: it.phone,
          ownerId: it.id,
          ownerType: 2,
          label: '物业'
        }))
      })
      await partnerList({pageSize: 10000, pageNum: 0}).then(response => {
        response.rows.forEach(it => owner.push({
          username: it.username,
          phone: it.phone,
          ownerId: it.id,
          ownerType: 4,
          label: '合伙人'
        }))
      })
      //判断是代理商还是运营商
      if (agentInfo.value.isOperator) {
        ownerList.value = allOwnerList
      } else {
        ownerList.value = owner
      }
      ownerList.value.forEach(item => {
        item.text = item.label + ' - ' + item.username + (item.ownerType === 0 ? '' : ('(' + item.phone + ')'));
      })
    }


    const addShareList = () => {
      let sharePercent = customerNum.value;
      const share = {
        username: choosePerson.value.username,
        userId: choosePerson.value.ownerId,
        userType: choosePerson.value.ownerType,
        sharePercent: sharePercent / 100,
        disabled: false,
        deviceSn: item.value.deviceSn
      }
      // return;
      addItem(share);
      showAdd.value = false;
    }
    const modifyDevice = (val) => {
      router.push({path: '/device/edit', query: {item: JSON.stringify(val)}})
    };

    const add = () => {
      showAddBtn.value = true;
      showEditBtn.value = false;
      person.value = '';
      customerNum.value = 0;
      showAdd.value = true;
    }

    const editShareList = () => {
      showAdd.value = false;
      editItem.value.username = choosePerson.value.username;
      editItem.value.userId = choosePerson.value.ownerId;
      editItem.value.userType = choosePerson.value.ownerType;
      editItem.value.sharePercent = customerNum.value / 100;
      replaceItem(newItemIndex.value, editItem.value);
      showAdd.value = false;

    }

    const edit = (index, item) => {
      newItemIndex.value = index;
      editItem.value = {};
      showAddBtn.value = false;
      showEditBtn.value = true;
      let find = ownerList.value.find(owner => owner.ownerId == item.userId && owner.ownerType == item.userType);
      customerNum.value = item.sharePercent * 100;
      person.value = find.label + ' - ' + find.username + (find.ownerType === 0 ? '' : ('(' + find.phone + ')'));
      editItem.value = item;
      showAdd.value = true;
    }


    return {
      deviceSwitch, active, columns, showPicker, showPick,
      onChange,
      onCancel,
      onConfirm,
      register,
      deleteRow,
      changeTabs,
      showAdd,
      showPerson,
      showPersonClick,
      personConfirm,
      item,
      deviceStatusList,
      socketID,
      combList,
      combName,
      onClick,
      show,
      checked,
      changeChecked,
      changePlan,
      save,
      ownerList,
      customerNum,
      person,
      add,
      addShareList,
      edit,
      showAddBtn,
      showEditBtn,
      editShareList,
      modifyDevice,
      updatePlan,
      changeStatus,
      userType,
      open
    };
  },
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #f5f5f5;
  position: relative;
  height: 100%;
}

.content-item {
  display: flex;
  flex-direction: column;

  background: #FFFFFF;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid #cccccc;
}

.item-title {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.item-content {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
}

.item-content-s {
  display: flex;
  flex-direction: row;
  justify-content: space-between
}

.item-content-title {
  font-size: 16px;
  color: #333333;
  font-weight: 500;
}

.item-content-address {
  font-size: 13px;
  color: #666666;
  margin-top: 4px;
}

.device-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.device-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  font-size: 14px;
  color: #FFFFFF;
  margin-left: 6px;
}

.device-item-green {
  background-color: #00d655;
}

.device-item-orange {
  background-color: orange;
}


.device-item-gray {
  background-color: #999999;
}

.device-item-red {
  background-color: red;
}
.outer{
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
  .item-content-fenrun {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    padding: 12px 12px;
    .item-content-title-fenrun {
      font-size: 16px;
      color: #333333;
      font-weight: 500;
    }

    .item-content-address-fenrun {
      font-size: 13px;
      color: #666666;
      margin-top: 8px;
      margin-bottom: 4px;
    }
  }
  .right{
    display: flex;
    .icon{
      display: flex;
      align-items: center;
      .van-icon{
        width: 32px;
        height: 32px;
        padding-right: 12px;

    }
    }
    
  }

}




.item-btn {
  display: flex;
  flex-direction: column;
  height: 100%
}


.delete-button {
  height: 50%;
}

.pop-btn {
  margin-top: 20px;
  width: 200px
}

.pop-content {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

</style>