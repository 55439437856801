<template>
  <div class="form">
    <van-field
      label-width="60"
      v-model="timeValue"
      is-link
      readonly
      name="datetimePicker"
      label="日期选择"
      placeholder="选择时间"
      @click="showTimePicker = true"
    />
    <van-field
      label-width="60"
      v-model="typeValue"
      is-link
      readonly
      name="datetimePicker"
      label="业务类型"
      placeholder="选择业务"
      @click="showTypePicker = true"
    />
  </div>
  <div class="table_title">
    <div>时间</div>
    <div>业务类型</div>
    <div>金额</div>
    <div>账户余额</div>
  </div>
  <div class="content">
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell-group>
        <van-cell v-for="item in list" :key="item.id">
          <div class="item">
            <span>{{ item.time }}</span>
            <span>{{ item.type }}</span>
            <span>{{ item.money }}</span>
            <span>{{ item.balance }}</span>
          </div>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>

  <van-calendar
    v-model:show="showTimePicker"
    type="range"
    :show-confirm="false"
    @confirm="(e) => onConfirm(e, 'time')"
  />
  <van-popup v-model:show="showTypePicker" round position="bottom">
    <van-picker
      :columns="columns"
      @confirm="(e) => onConfirm(e, 'type')"
      @cancel="showTypePicker = false"
    />
  </van-popup>
</template>

<script>
import { onMounted, ref } from "vue";
export default {
  setup() {
    /**
     * 页面时间相关暂未做处理
     */
    const columns = ["全部", "自定义"];
    const showTimePicker = ref(false);
    const showTypePicker = ref(false);
    const typeValue = ref(columns[0]);
    const timeValue = ref(
      new Date().getFullYear() +
        `-${new Date().getMonth() + 1}` +
        `-${new Date().getDate()}`
    );
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);

    onMounted(() => {
      // 加载列表
      
    });

    const onLoad = () => {
        setTimeout(() => {
        list.value = [
          {
            id: 1,
            time: "22-04-23 17:34",
            type: "分润",
            money: "4",
            balance: "45",
          },
          {
            id: 2,
            time: "22-12-23 17:34",
            type: "分润",
            money: "5",
            balance: "15",
          },
          
         
        ];
        loading.value = false
        finished.value  = true
      },2000);
    }

    const onConfirm = (e, option) => {
      console.log(option, e);
      if (option === "time") {
        timeValue.value = e[1];
        showTimePicker.value = false;
      } else {
        typeValue.value = e;
        showTypePicker.value = false;
      }
    };

    return {
      typeValue,
      timeValue,
      columns,
      showTimePicker,
      showTypePicker,
      list,
      loading,
      finished,
      onConfirm,
      onLoad
    };
  },
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
}
.table_title {
  display: flex;
  height: 30px;
  div {
    flex: 1;
    &:first-child {
      flex: 1.3;
      padding-left: 16px;
    }
  }
}
.content {
    height: calc(100vh - 46px - 44px - 30px );
    overflow: auto;
  .van-cell {
    padding-left: 0;
    padding-right: 0;
  }
  .item {
    display: flex;
    span {
      flex: 1;
      &:first-child {
        flex: 1.3;
        padding-left: 16px;
      }
    }
  }
}
</style>
