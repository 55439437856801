import request from '../utils/request.js'


export function balance(data){
    return request({
        method:'GET',
        url:'/agent/asset/balance',
        data
    })
}

export function applyPage(params){
    return request({
        method:'GET',
        url:'/agent/asset/withdraw/source/apply/page',
        params
    })
}

export function checkPage(params){
    return request({
        method:'GET',
        url:'/agent/asset/withdraw/target/apply/page',
        params
    })
}

//取消提现
export function cancelWithdraw(id) {
    return request({
        url: '/agent/asset/withdraw/source/apply/cancel/'+ id,
        method: 'post'
    })
}
//同意
export function applyAgree(id) {
    return request({
        url: '/agent/asset/withdraw/target/apply/approve/'+ id,
        method: 'post'
    })
}

export function applyRefuse(data) {
    return request({
        url: '/agent/asset/withdraw/target/apply/refuse',
        method: 'post',
        data: data
    })
}

//提现
export function withdrawal(query) {
    return request({
        url: '/agent/asset/attach/list',
        method: 'get',
        params: query
    })
}

// 确认提现
export function confirmWithdraw(data) {
    return request({
        url: '/agent/asset/withdraw/apply/create',
        method: 'post',
        data: data
    })
}

//核账
export function validateApply(id) {
    return request({
        url: '/finance/withdrawApply/apply/validate/'+ id,
        method: 'post',
    })
}
//打款完成
export function paymentCompleted(data) {
    return request({
        url: '/finance/withdrawApply/entPay',
        method: 'post',
        data: data
    })
}
//拒绝打款
export function refuseToPay(data) {
    return request({
        url: '/finance/withdrawApply/refusePay',
        method: 'post',
        data: data
    })
}



