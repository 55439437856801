<template>
  <div class="list_page">
    <div class="card">
      <div class="c_top">
        <span>实时余额:</span>
        <span>{{ realTimeBalance / 100 }}</span>
        <div class="button-area">
          <span class="btn" @click="withdrawBtn" v-if="isOperatorShow">提现</span>
          <van-icon name="info" color="#fff" size="22"  class="info-icon" @click="showDialog" v-if="isOperatorShow"/>
        </div>
        
      </div>
      <div class="c_bottom">
        <div class="c_left">
          <div>可提现余额:</div>
          <div>{{ realTimeBalance / 100 }}</div>
        </div>
        <div class="c_right">
          <div>冻结余额:</div>
          <div>{{ freezeBalance / 100 }}</div>
        </div>
      </div>
    </div>
    <van-tabs v-model:active="active" color="#07c160" @change="handleChange">
      <van-tab title="我的申请"/>
      <van-tab title="我的审核"/>
    </van-tabs>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          v-model:error="error"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
      >
        <ApplyCard v-for="(item, index) in list" :key="index" :item="item" @click="goDetail(item)"/>
      </van-list>
    </van-pull-refresh>

    <!-- 提现下拉框 -->
    <van-dialog v-model:show="show" title="选择提现账户" show-cancel-button @confirm="onConfirmWithDraw">
      <van-field
          v-model="result"
          is-link
          readonly
          name="picker"
          label="选择账户"
          placeholder="点击选择账户"
          @click="showPicker = true"
      />
      <van-cell-group-inset>
        <van-field v-model="amount" type="number" label="提现金额" placeholder="请填写提现金额" @input="amountInput"/>
      </van-cell-group-inset>
      <div class="notice-info">提醒通知：因业务调整，提现至银行卡提现手续费自2023年4月1日起，由原来6‰调整为8‰，支付宝提现通道费率不变为6‰</div>
    </van-dialog>

    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
      />
    </van-popup>
    <van-dialog v-model:show="msgInfo"
     title="尊敬的用户"
     :cancelButtonColor="dialogCloseFlag ? '#c8c9cc':'#ee0a24'"
     :cancelButtonText="dialogCloseFlag ? `关闭（剩余${countDown}秒）`:'关闭'" 
     :closeOnClickOverlay="false"
     :showCancelButton="true"
     :cancelButtonDisabled="dialogCloseFlag"
     confirmButtonText="去续费"
     :confirmButtonColor="'#1989fa'"
     @confirm="msgDialogConfirm"
     @cancel="msgDialogClose"
     >
      <div class="notice-info-dialog">
        <div class="des">您有充电桩设备已到服务期，</div>
        <div class="des">为防止业务受影响，请及时续费！</div>
      </div>
    </van-dialog>


  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import ApplyCard from "@/components/ApplyCard";
import {useRoute, useRouter} from "vue-router";
import {applyPage, balance, checkPage, confirmWithdraw, withdrawal} from '@/api/withdraw'
import {Dialog, Notify} from "vant";
import {getAgentHasRenewalDevice} from "@/api/device";
import {getAgentInfo} from "@/api/stat";

export default {
  components: {
    ApplyCard,
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
   
    const msgInfo = ref(false);
    const countDown = ref(60);
    const countDownFlag = ref(false);
    const dialogCloseFlag = ref(true);
    const isOperatorShow = ref(true);
    const router = useRouter()
    const route = useRoute()
    const realTimeBalance = ref("0.00");
    const availableBalance = ref("0.00");
    const freezeBalance = ref("0.00");
    const active = ref(0);
    const list = ref([]);
    const checkList = ref([]);
    const applyList = ref([]);
    const error = ref(false);
    const loading = ref(false);
    const refreshing = ref(false);
    const finished = ref(false);
    const checkTotalNum = ref(0);
    const applyTotalNum = ref(0);
    const show = ref(false);
    const showPicker = ref(false);
    const columns = ref([]);
    const result = ref('');
    const amount = ref();
    const accID = ref();
    const msgDialogClose = () =>{
      clearInterval(countDownFlag.value)
    }
    const msgDialogConfirm = () => {
      clearInterval(countDownFlag.value)
      router.push({path: `/renewal`})

    }
    const params = {
      pageSize: 10,
      pageNum: 0,
      queryType: 1
    };
    const checkParams = {
      pageSize: 10,
      pageNum: 0,
      queryType: 2
    }
     onMounted(() => {
       // 查询设备续费
      getAgentHasRenewalDevice().then((response) => {
          if (response.data) {
            msgInfo.value = true;
            countDownFlag.value = setInterval(()=>{
              countDown.value --;
              if(countDown.value === 0){
                clearInterval(countDownFlag.value)
                dialogCloseFlag.value = false;
              }
            },1000)
          }
        })
      getAgentInfo().then(res=>{
        if (res.data.isOperator){
          isOperatorShow.value=false;
        }
      })
    });

    const onLoad = () => {
      loading.value = true;
      finished.value = false;
      balance().then(res => {
        realTimeBalance.value = res.data.realTimeBalance;
        availableBalance.value = res.data.withdrawBalance;
        freezeBalance.value = res.data.blockedBalance;
      });
      if (active.value == 1) {
        checkParams.pageNum += 1;
        checkPage(checkParams).then(res => {
          checkTotalNum.value = res.total;
          checkList.value = checkList.value.concat(res.rows);
          list.value = checkList.value;
          if (checkList?.value.length >= checkTotalNum?.value) {
            finished.value = true;
          }
        }).catch(err => {
        })

      } else {
        params.pageNum += 1;
        applyPage(params).then(res => {
          applyTotalNum.value = res.total;
          applyList.value = applyList.value.concat(res.rows);
          list.value = applyList.value;
          if (applyList?.value.length >= applyTotalNum?.value) {
            finished.value = true;
          }
        }).catch(err => {
        })
      }
      ;

      //提现下拉列表
      withdrawal().then(response => {
        columns.value = response.data;
        columns.value.forEach(item => {
          item.text = item.transferNo + item.bankName + item.subBankName;
        })

      });

      setTimeout(() => {
        loading.value = false;
        refreshing.value = false
      }, 300);
    };

    const amountInput=(val)=>{
      console.log(amount.value,'value')
      console.log(realTimeBalance.value,'value')
      if((amount.value.indexOf(".") != -1 && amount.value.split('.').length > 2)/*||isNaN(val)*/){
        Notify({type: 'danger', message: '请输入正确格式的金额'});//防止输入多个小数点
        amount.value=null;
        return;
      }else if(amount.value.indexOf(".") != -1 && amount.value.split('.')[1].length > 2){
        Notify({type: 'danger', message: '请输入正确的金额数字，最多两位小数'});//小数点后两位
        amount.value=null;
        return;
      }else if(amount.value*100>realTimeBalance.value){
        Notify({type: 'danger', message: '提现金额不能大于实际金额'});
        amount.value=null;
        return;
      }
    }

    const handleChange = () => {
      params.pageNum = 0;
      checkParams.pageNum = 0;
      if (active.value == 1) {
        checkList.value = [];
      } else {
        applyList.value = [];
      }
      onLoad()
    };

    const onConfirmWithDraw = () => {
      if (amount.value<100){
        Notify({type: 'danger', message: '提现金额不能小于100元'});
        amount.value=null;
        return;
      }else if((amount.value.indexOf(".") != -1 && amount.value.split('.').length > 2)/*||isNaN(val)*/){
        Notify({type: 'danger', message: '请输入正确格式的金额'});//防止输入多个小数点
        amount.value=null;
        return;
      }else if(amount.value.indexOf(".") != -1 && amount.value.split('.')[1].length > 2){
        Notify({type: 'danger', message: '请输入正确的金额数字，最多两位小数'});//小数点后两位
        amount.value=null;
        return;
      }else if(amount.value*100>realTimeBalance.value){
        Notify({type: 'danger', message: '提现金额不能大于实际金额'});
        amount.value=null;
        return;
      }
      if (accID.value!=null){
        const form = {
          assetAccountAttachId: accID.value,
          assetAccountAmount:amount.value * 100,
          note: ''
        }
        confirmWithdraw(form).then(res => {
          if (res.code == 200) {
            finished.value = false;
            Notify({type: 'success', message: '申请成功'});
            let applyParams = {
              pageSize:10,
              pageNum:1,
              queryType:1
            }
            balance().then(res => {
              realTimeBalance.value = res.data.realTimeBalance;
              availableBalance.value = res.data.withdrawBalance;
              freezeBalance.value = res.data.blockedBalance;
              applyPage(applyParams).then(res => {
                applyTotalNum.value = res.total;
                list.value = res.rows;
                if (applyList?.value.length >= applyTotalNum?.value) {
                  finished.value = true;
                }
              })
            });
          } else {
            Notify({type: 'danger', message: '申请失败'});
          }
        })
      }else {
        Notify({type: 'danger', message: '请选择提现账户'});
      }

    }
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      params.pageNum = 0;
      checkParams.pageNum = 0;
      onLoad();
    };

    const onConfirm = (value) => {
      result.value = value.text;
      showPicker.value = false;
      accID.value = value.id;
    };

    const goDetail = (item) => {
      // 0 申请详情 1 审核详情
      getAgentInfo().then(res => {
        if (res.data.businessUserType == 'ESTATE'||res.data.businessUserType == 'PARTNER'){
          if (item.applyStatus){
            item.applyStatus=item.applyEPStatus;
            console.log(item.applyStatus)
            router.push({path: `/withdrawal/detail`, query: {type: active.value, item: JSON.stringify(item)}})
          }
        }else{
          router.push({path: `/withdrawal/detail`, query: {type: active.value, item: JSON.stringify(item)}})
        }
      })

    };
    const withdrawBtn = () => {
      if (availableBalance.value < 10000) {
        Dialog.alert({
          message: '提现金额必须大于100元',
        }).then(() => {
          // on close
        });
        return;
      }
      show.value = true;
    }
    const showDialog = () => {
      Dialog.alert({
        title: '提现说明',
        message:`<div>提现金额：<span style="font-weight: bold">实提金额满100元可申请提现。</span> <br />提现周期：<span style="font-weight: bold">当天15:00前提交的提现申请，当天18:00前处理完毕。当天15:00后的提现申请，次日处理完毕，节假日顺延。</span><br />提现费率：<span style="font-weight: bold">6‰（自2023年4月1日起提现至银行卡提现手续费将变更为8‰）</span><br />提现方式：<span style="font-weight: bold">现支持银行卡、支付宝打款。若打款信息填写有误无法打款，提现申请会驳回，请正确填写后重新提交。</span></div>`,
        theme: 'round-button',
        messageAlign:'left',
        allowHtml: true,
      }).then(() => {
        // on close
      });
    }

    return {
      isOperatorShow,
      realTimeBalance,
      availableBalance,
      freezeBalance,
      active,
      list,
      error,
      finished,
      loading,
      refreshing,
      onLoad,
      handleChange,
      onRefresh,
      goDetail,
      withdrawBtn,
      show,
      showPicker,
      columns,
      result,
      amount,
      amountInput,
      msgInfo,
      onConfirm,
      onConfirmWithDraw,
      showDialog,
      msgDialogClose,
      msgInfo,
      countDown,
      countDownFlag,
      msgDialogClose,
      dialogCloseFlag,
      msgDialogConfirm
    };
  },
};
</script>

<style lang="scss" scoped>
.notice-info-dialog{
  margin: 16px 12px;
  .des{
    color: #ee0a24;
    font-size: 16px;
  }
  .bottom{
    font-size: 14px;
    color: #c8c9cc;
    line-height: 28px;
  }
}
.list_page {
  padding: 12px;
  height: calc(100vh - 46px);
  overflow: auto;
  box-sizing: border-box;

  .card {
    padding: 12px;
    color: #fff;
    background-color: #07c160;
    border-radius: 8px;

    .c_top {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 12px;
      .button-area{
        margin: 0 auto;
        display: flex;
        .btn {
          padding: 4px 24px;
          background-color: #fff;
          color: #333;
          font-size: 14px;
          border-radius: 16px;
          margin-top: 4px;
        }
        .info-icon{
          margin: 8px 0 0 8px;
        }
      }
      
    }

    .c_bottom {
      display: flex;
      justify-content: space-between;
      text-align: center;
      padding-top: 12px;

      .c_left {
        border-right: 1px solid #e0e0e0;
        width: 50%;
      }

      .c_right {
        width: 50%;
      }
    }
  }

  .van-list {
    // height: calc(100vh - 64px - 160px - 44px);
    // overflow: auto;
  }
  .notice-info{
    padding: 0 16px;
    color: var(--van-field-error-message-color);
    font-size: var(--van-field-error-message-font-size);
  }

}
</style>
