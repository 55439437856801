<template>
  <div class="container">
    <Search v-model="apartmentName" @search="searchClick" @update:model-value="onInputChange" :clearable="true" showAction placeholder="请输入电站名称">
      <template #action>
        <div style="line-height: 0" v-if="userType">
          <van-button color="#00d655" icon="plus" size="small" @click="toAdd" round></van-button>
        </div>
      </template>
    </Search>
    <div class="tabBox">
      <van-tabs v-model:active="active" @click="onClick(active)">
        <van-tab :title="item.name" :key="index" v-for="(item,index) in typeList"></van-tab>
      </van-tabs>
    </div>
        <div class="statistic" v-if="userType">
          <div class="statistic-text">今日总订单：{{totalCurrDayOrderCount}}</div>
          <div class="statistic-text"> 今日总收入：{{thisDayIncomeAmount/100 || 0}}元</div>
        </div>
    <div style="margin-top: 5px; padding: 0 12px;">
      <ListPage @register="register">
        <template #item="{dataList}">
          <div :key="index" v-for="(item,index) in dataList" @click="handleClick(item,index)">
            <!-- <SwipeCell> -->
              <div class="item-content" :class="currentIndex === index ? 'item-content-checked':''">
                <div class="item-content-title">
                  {{ item.apartmentName }}
                </div>
                <div class="item-content-address">
                  {{ item.provinceName }}{{ item.cityName }}{{ item.countyName }}{{ item.address }}
                </div>
                <div class="item-content-statistic">
                  <div class="item-content-item-num">
                    <div class="item-title-statistic">编号</div>
                    <div class="item-content-c">{{ item.id }}</div>
                  </div>
                  <div class="item-content-item-device">
                    <div class="item-title-statistic">设备</div>
                    <div class="item-content-c">{{ item.deviceCount }}</div>
                  </div>
                  <div class="item-content-item-soket">
                    <div class="item-title-statistic">端口</div>
                    <div class="item-content-c">{{ item.deviceSocketCount }}</div>
                  </div>
                  <div class="item-content-item-free">
                    <div class="item-title-statistic">充电/空闲</div>
                    <div class="item-content-c">{{ item.chargingSockets }}/{{ item.freeSockets }}</div>
                  </div>
                </div>
              </div>
              <!-- <template #right>
                <div class="item-btn" v-if="userType">
                  <van-button square text="黑白名单" type="success" @click="toBlackList(item.id)"/>
                  <van-button square text="编辑" type="primary " @click="updateRow(item.id)"/>
                  <van-button square text="删除" type="danger" @click="deleteRow(item.id)"/>
                </div>
              </template> -->
            <!-- </SwipeCell> -->
            <div style="height: 8px"></div>
          </div>
        </template>
      </ListPage>
    </div>
    <div v-if="show && userType" class='bottom-button' >
          <Button type="primary" block style="flex:1;" size=large @click='toBlackList'>黑白名单</Button>
          <Button type="success" block style="flex:1;" size=large @click='updateRow'>编辑</Button>
          <Button type="danger" block style="flex:1" size=large @click="deleteRow">删除</Button>

      </div>
  </div>
</template>

<script>
import {ListPage, useListPage} from "@/components/ListPage";
import {delStation, getStation} from '@/api/station'

import {useRouter} from "vue-router";
import {onMounted, ref} from 'vue';
import {Dialog, Divider, NoticeBar, SwipeCell, Tag, Search,Button} from 'vant';
import {aggregateStation, getAgentInfo, indexAggregate, indexNumeric} from "@/api/stat";

export default {
  components: {
    Search,
    SwipeCell,
    ListPage,
    Tag,
    Divider,
    NoticeBar,
    Button
  },
  setup() {
    const typeList = ref([
      {id: 1, name: '两轮充电站', title: [{name: '两轮充电站'}]},
      {id: 2, name: '汽车充电站', title: [{name: '汽车充电站'}]},
    ]);
    const active = ref(0)
    const userType =ref();
    const router = useRouter();
    const thisDayIncomeAmount = ref();
    const totalCurrDayOrderCount = ref();
    const currentIndex = ref(-1);
    const sampleId = ref(0)
    const show = ref(false);
    const apiUrl = '/device/station/list';
    const [register, {getAllData, setProps, queryData, resetData, deleteItem}] = useListPage({
      apiUrl: apiUrl,
      requestParam: {
        needSocketsInfo: true,
        stationKindType:0
      }
    });
    const apartmentName = ref('');
    onMounted(() => {
      getAgentInfo().then(res => {
        if (res.data.businessUserType == 'ESTATE') {
          userType.value = false;
        } else if (res.data.businessUserType == 'PARTNER') {
          userType.value = false;
        } else {
          userType.value = true;
        }
        console.log(userType.value)
      })
      queryData();
      // indexAggregate().then(res => {
      //   thisDayIncomeAmount.value = res.data.thisDayIncomeAmount;
      //   totalCurrDayOrderCount.value = res.data.thisDayOrderCount;
      // })
      aggregateStation(0).then(res=>{
        thisDayIncomeAmount.value = res.data.thisDayIncomeAmount;
        totalCurrDayOrderCount.value = res.data.thisDayOrderCount;
      })
    });
    const onClick = (active) => {
      let pageSize = 10;
      if (active === 0) {
        setProps({apiUrl,pageSize,requestParam: {needSocketsInfo: true,stationKindType:active}})
        resetData()
        aggregateStation(active).then(res=>{
          thisDayIncomeAmount.value = res.data.thisDayIncomeAmount;
          totalCurrDayOrderCount.value = res.data.thisDayOrderCount;
        })
        currentIndex.value=false
        show.value=false
      } else if (active === 1) {
        aggregateStation(active).then(res=>{
          thisDayIncomeAmount.value = res.data.thisDayIncomeAmount;
          totalCurrDayOrderCount.value = res.data.thisDayOrderCount;
        })
        setProps({apiUrl,pageSize,requestParam: {needSocketsInfo: true,stationKindType:active}})
        resetData()
        currentIndex.value=false
        show.value=false
      }
    }
    const handleClick = (item,index) => {
      show.value = true;
      sampleId.value = item.id;
      currentIndex.value = index;
    }
    const searchClick = () => {
      let pageSize = 10;
      setProps({apiUrl, pageSize, requestParam: {apartmentName: apartmentName, needSocketsInfo: true,stationKindType:active.value}});
      resetData();
    }
    const debounce=(fun, delay = 200)=> {
        let t='';
        return  (args) => {
            let that = this
            let _args = args
            clearTimeout(t)
            t= setTimeout(function () {
                fun.call(that, _args)
            }, delay)
        }
     }
    const onInputChange =debounce((val) => {
      let pageSize = 10;
      setProps({apiUrl, pageSize, requestParam: {apartmentName: val,needSocketsInfo:true}});
      resetData();
    })
    const cleanStatus = () => {
      show.value = false;
      currentIndex.value = -1;
    }
    const deleteRow = () => {
      let id = sampleId.value;
      Dialog.confirm({
        title: '确定删除吗？',
      }).then(() => {
        //删除本地数据
        delStation(id).then(res => {
          resetData();
          cleanStatus();
        })
      });
    }
    const updateRow = () => {
      let id = sampleId.value;

      getStation(id).then(res => {
        router.push({path: '/rechargeStation/update', query: {item: JSON.stringify(res.data)}})
      })

    }

    const toBlackList = () => {
      let id = sampleId.value;
      router.push({path: '/rechargeStation/blackList', query: {id: id}})
    }
    const toAdd = () => {
      router.push({path: '/rechargeStation/add',query:{stationKindType:active.value}})
    }
    return {
      onClick,
      active,
      typeList,
      cleanStatus,
      show,
      sampleId,
      handleClick,
      currentIndex,
      userType,
      register,
      onInputChange,
      searchClick,
      updateRow,
      deleteRow,
      toBlackList,
      toAdd,
      apartmentName,
      totalCurrDayOrderCount,
      thisDayIncomeAmount
    };
  },
}
</script>

<style lang="scss"  scoped>
.container {
  background-color: #F5f5f5;
}

.delete-button {
  height: 50%;
}


.item-content {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 12px 12px;
  border-radius: 5px;
}
.item-content-checked {
  background-color: #00d655;
  .item-content-title{
    // background-color: #00d655;
    color: #fff;
  }
  .item-content-address{
    color: #FFFFFF;
  }
  .item-content-statistic{
    background-color: #fff;
  }
}

.item-content-title {
  font-size: 16px;
  color: #333333;
  font-weight: 500;
}

.item-content-address {
  font-size: 13px;
  color: #666666;
  margin-top: 8px;
  margin-bottom: 12px;
}

.item-content-statistic {
  height: 64px;
  padding: 12px 0;
  display: flex;
  // flex-direction: row;
  // align-items: flex-start;
  background-color: #F5f5f5;
  border-radius: 4px;
  flex: 0;
  order: 1;
  flex-grow: 0;
  .item-content-item-num {
    flex: 2;
    height: 40px;
    text-align: center;
  }
  .item-content-item-device {
    flex: 1;
    height: 40px;
    text-align: center;
  }
  .item-content-item-soket {
    flex: 2;
    height: 40px;
    text-align: center;
  }
  .item-content-item-free {
    flex: 4;
    height: 40px;
    text-align: center;
  }

}



.item-title-statistic {
  font-size: 14px;
  color: #666666;
}

.item-content-c {
  font-size: 16px;
  color: #333333;
  margin: 2px 16px 0 16px;
}

.item-btn {
  display: flex;
  flex-direction: column;
  height: 100%
}

.statistic {
  display: flex;
  flex-direction: row;
  background-color: #ecf9ff;
  padding: 8px
}

.statistic-text {
  font-size: 14px;
  color: #333333;
  margin-right: 5px;
}
 .bottom-button{
      display: flex;
      width:100%;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
  }
</style>