<template>
  <div class="tixian">
    <div class="card" v-for="(item, index) in cardList" :key="index">
      <van-image
        width="60"
        height="60"
        src="https://img01.yzcdn.cn/vant/cat.jpeg"
      />
      <div class="card_center">
        <div>{{ "网三" }}</div>
        <div>{{ "中国银行" }}</div>
        <div>{{ "6210 **** **** 7956" }}</div>
      </div>
      <span class="fr">{{ "银行卡" }}</span>
    </div>
    <div class="add_icon" @click="goAdd">
      <van-icon name="plus" color="#fff" size="28" />
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter()
    const cardList = [{}, {}];

    const goAdd = () => {
      router.push('/withdrawal/add')
    };
    return { cardList, goAdd };
  },
};
</script>

<style lang="scss" scoped>
.tixian {
  padding: 12px;
  .card {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 1px 8px #e0e0e0;
    margin-bottom: 12px;
    .card_center {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 8px;
      font-size: 14px;
      flex: auto;
    }
  }
  .add_icon {
    position: fixed;
    right: 12px;
    bottom: 60px;
    padding: 4px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
