<template>
  <div class="container">
    <div class="item" v-for="item in listData" :key="item.id">
      <!-- 银行卡 -->
      <div class="outer"  v-if="item.transferChannel === 1">
        <div class="left">
        <van-image 
          class="icon"
          src="https://anchong-dev-1304380937.cos.ap-shanghai.myqcloud.com/ee4dc2fbd75648fa9663a635b115e45fvlPoSUZBnSLT1406976e307bcbd33d669dfc18767bfd.png"
        />
        <div class="des">
          <div class="user-name">{{item.username}}</div>
          <div class="bank-name">{{item.subBankName}}</div>
          <div class="account-num">{{item.transferNo}}</div>

        </div>
      </div>
      <div class="right">
<!--        <van-icon name="edit" size="32" color="#00d655" @click="modifyItem(item)" />-->
        <van-icon name="delete-o" size="32" color="#ff3552" @click="delBank(item.id)"/>
      </div>
      </div>
      
      <!-- 支付宝 -->
      <div class="outer" v-else>
         <div class="left" >
        <van-image 
          class="icon"
          src="https://anchong-dev-1304380937.cos.ap-shanghai.myqcloud.com/4fe8cbaaa57442cb9922beceb204f26aK71odTF6w4yV6d9ba10a3506008b0f564fbdd0974213.png"
        />
         <div class="des">
          <div class="user-name">{{item.username}}</div>
          <div class="bank-name"></div>
          <div class="account-num">{{item.transferNo}}</div>

        </div>
      </div>
      <div class="right">
<!--        <van-icon name="edit" size="32" color="#00d655" @click="modifyItem(item)" />-->
        <van-icon name="delete-o" size="32" color="#ff3552" @click="delAli(item.id)"/>
      </div>
      </div>
     
    </div>
    <van-divider>已展示全部账户数据</van-divider>
    <van-dialog v-model:show="delShow" title="提示" @confirm="confirmDel" show-cancel-button>
      <div class="delDes">{{delDes}}</div>
    </van-dialog>
<!--    <div class="add" @click="toAdd">-->
<!--      <van-icon name="add" color="#00d655" size="56"/>-->
<!--    </div>-->
  </div>
</template>

<script>
import {getAttachList,delAttachList} from "@/api/user";
import { Notify} from "vant";

export default {
  data(){
    return{
      delShow:false,
      delDes:"",
      delId:"",
      listData:[]
    }
  },
  methods:{
    modifyItem(item){
      this.$router.push({path:"./withdrawAccountAdd",
        query:{
          type:'modify',
          data: JSON.stringify(item)
        }
      })
    },
    toAdd(){
      this.$router.push({path:"./withdrawAccountAdd",query:{type:'add'}})
    },
    delAli(id){
      this.delId = id;
      this.delDes="确定删除此条支付宝账号？"
      this.delShow = true;
      
    },
    delBank(id){
      this.delId = id;
      this.delDes="确定删除此条银行账号？"
      this.delShow = true;
    },
    confirmDel(){
      delAttachList(this.delId).then(response => {
        console.log(response)
        if(response.code === 200){
          Notify({ type: 'primary', message: '删除成功' });
          this.init();
        }
      });

    },
    init(){
      getAttachList().then(response => {
        console.log(response)
        if(response.code === 200){
          let data = response.data.reverse();
          this.listData = data;
        }
      });
    }
  },
  mounted(){

  },
  created(){
    //请求数据
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.container{
  margin: 0 12px;
  padding-bottom: 80px;
  .item{
    padding: 12px;
    border-radius: 8px;
    font-size: 12px;
    color: #333;
    border: 1px solid #e0e0e0;
    margin-top: 12px;
    .outer{
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      .left{
       display: flex;
        .icon{
          display: block;
          width: 40px;
          height: 40px;
          margin-top: 10px;
        }
        .des{
          margin-left: 12px;
          .user-name{
            font-size: 14px;
            font-weight: bold;
            height: 24px;
            line-height: 24px;
          }
          .bank-name{
            height: 24px;
          }
        }
      }
      .right{
        display: flex;
        .van-icon{
           width: 32px;
           height: 32px;
           padding-top: 14px;
           padding-right: 12px;
        }
      }
    }
    
  }
  .delDes{
    text-align: center;
    line-height: 44px;
    height: 44px;
  }
  .add{
    position: fixed;
    right: 24px;
    bottom: 68px;
  }
}
</style>