import request from '../utils/request.js'

export function testPost(data){
  return request({
    method:'POST',
    url:'/tob/bizUser/auth/signIn',
    data
 })

}

// 用户信息
export function getUser(id) {
  return request({
    method:'GET',
    url:`/test/user/${id}`,
 })
}

// 登录方法
export function login(token) {
    const data = {
        token
    }
    return request({
        url: '/login',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}