import {createApp,Vue} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/dateUtil.js'
import {
    Button, Form, Field, CellGroup, Toast, Picker, Popup, Search, Checkbox, CheckboxGroup,
    ConfigProvider, Icon, Tag, Image as VanImage, Tabbar, TabbarItem,
    Area, Divider, SwipeCell, RadioGroup, Radio, DatetimePicker,DropdownMenu, DropdownItem,
    Cell, Tab, Tabs, List, PullRefresh, Calendar, Switch,TreeSelect,NoticeBar,Dialog,Notify,
} from 'vant';
import 'vant/lib/index.css';

// 控制台调试
// import VConsole from 'vconsole'
// const vConsole = new VConsole()
// app.use(vConsole)

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
const app = createApp(App)
let jweixin = require('jweixin-module')
app.config.globalProperties.$wx = jweixin


app.use(Checkbox)
app.use(CheckboxGroup)
app.use(TreeSelect)
app.use(Notify)
app.use(Dialog)
app.use(DatetimePicker)
app.use(NoticeBar)
app.use(Switch)
app.use(store)
app.use(router)
app.use(Button)
app.use(Form)
app.use(Field)
app.use(CellGroup)
app.use(Toast)
app.use(Picker)
app.use(Popup)
app.use(Search)
app.use(ConfigProvider)
app.use(Icon)
app.use(Tag)
app.use(VanImage)
app.use(Tabbar)
app.use(TabbarItem)
app.use(Area)
app.use(Tab)
app.use(Tabs)
app.use(Divider)
app.use(PullRefresh)
app.use(List)
app.use(SwipeCell)
app.use(Radio)
app.use(RadioGroup)
app.use(Cell)
app.use(Tab)
app.use(Tabs)
app.use(List)
app.use(PullRefresh)
app.use(Calendar)
app.use(DropdownMenu)
app.use(DropdownItem)
app.mount('#app')
