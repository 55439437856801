<template>
  <div class="container">

<!--    <Search @search="searchClick" showAction>-->
<!--      <template #action>-->
<!--        <div  style="line-height: 0">-->
<!--          <van-button color="#00d655" icon="plus" size="small" @click="add" round></van-button>-->
<!--        </div>-->
<!--      </template>-->
<!--    </Search>-->
    <div>
      <Tabs v-model:active="active" @click-tab="onClickTab">
        <Tab title="用户白名单">
            <div v-for="(item,index) in userWhiteList" :key="index" class="item">
                <div class="outer">
                  <div class="left">
                    <div class="account">{{item.phone}}</div>
                    <div class="nickname">昵称：{{item.nickname}}</div>
                  </div>
                  <div class="right">
                    <van-icon name="delete-o" size="28" color="#ff3552" @click="deleteRow(item)"/>
                  </div>
                </div>
            </div>
        </Tab>
         <Tab title="用户黑名单" >
           <!-- <SwipeCell v-for="item in userBlackList">
             <Cell :title="item.phone" :value="item.createTime"></Cell>
             <template #right>
               <div class="item-btn">
                 <van-button square text="删除" type="danger" @click="deleteRow(item)" />
               </div>
             </template>
           </SwipeCell> -->
           <div v-for="(item,index) in userBlackList" :key="index" class="item">
                <div class="outer">
                  <div class="left">
                    <div class="account">{{item.phone}}</div>
                    <div class="nickname">昵称：{{item.nickname}}</div>
                  </div>
                  <div class="right">
                    <van-icon name="delete-o" size="28" color="#ff3552" @click="deleteRow(item)"/>
                  </div>
                </div>
            </div>
        </Tab>
         <Tab title="电卡白名单" >
           <!-- <SwipeCell v-for="item in cardWhiteList">
             <Cell :title="item.cardNo" :value="item.createTime"></Cell>
             <template #right>
               <div class="item-btn">
                 <van-button square text="删除" type="danger" @click="deleteRow(item)" />
               </div>
             </template>
           </SwipeCell> -->
           <div v-for="(item,index) in cardWhiteList" :key="index" class="item">
                <div class="outer">
                  <div class="left">
                    <div class="account">{{item.cardNo}}</div>
                    <div class="nickname">账户余额：{{item.account.userAmount/100}} 元</div>
                  </div>
                  <div class="right">
                    <van-icon name="delete-o" size="28" color="#ff3552" @click="deleteRow(item)"/>
                  </div>
                </div>
            </div>
        </Tab>
         <Tab title="电卡黑名单" >
           <!-- <SwipeCell v-for="item in cardBlackList">
             <Cell :title="item.cardNo" :value="item.createTime"></Cell>
             <template #right>
               <div class="item-btn">
                 <van-button square text="删除" type="danger" @click="deleteRow(item)" />
               </div>
             </template>
           </SwipeCell> -->
           <div v-for="(item,index) in cardBlackList" :key="index" class="item">
                <div class="outer">
                  <div class="left">
                    <div class="account">{{item.cardNo}}</div>
                    <div class="nickname">账户余额：{{item.account.userAmount/100}} 元</div>
                  </div>
                  <div class="right">
                    <van-icon name="delete-o" size="28" color="#ff3552" @click="deleteRow(item)"/>
                  </div>
                </div>
            </div>
        </Tab>
        <Tab title="用户免费充电名单" >
          <!-- <SwipeCell v-for="item in userFreeList">
            <Cell :title="item.phone" :value="item.createTime"></Cell>
            <template #right>
              <div class="item-btn">
                <van-button square text="删除" type="danger" @click="deleteRow(item)" />
              </div>
            </template>
          </SwipeCell> -->
          <div v-for="(item,index) in userFreeList" :key="index" class="item">
              <div class="outer">
                <div class="left">
                  <div class="account">{{item.phone}}</div>
                  <div class="nickname">昵称：{{item.nickname}}</div>
                </div>
                <div class="right">
                  <van-icon name="delete-o" size="28" color="#ff3552" @click="deleteRow(item)"/>
                </div>
              </div>
          </div>
        </Tab>
        <Tab title="电卡免费充电名单" >
          <!-- <SwipeCell v-for="item in cardFreeList">
            <Cell :title="item.cardNo" :value="item.createTime"></Cell>
            <template #right>
              <div class="item-btn">
                <van-button square text="删除" type="danger" @click="deleteRow(item)" />
              </div>
            </template>
          </SwipeCell> -->
          <div v-for="(item,index) in cardFreeList" :key="index" class="item">
                <div class="outer">
                  <div class="left">
                    <div class="account">{{item.cardNo}}</div>
                    <div class="nickname">账户余额：{{item.account.userAmount/100}} 元</div>
                  </div>
                  <div class="right">
                    <van-icon name="delete-o" size="28" color="#ff3552" @click="deleteRow(item)"/>
                  </div>
                </div>
            </div>
        </Tab>

      </Tabs>
    </div>
    <div class="add" @click="toAdd">
      <van-icon name="add" color="#00d655" size="56"/>
    </div>
    <van-popup
        v-model:show="showAdd"
        closeable
        position="bottom"
        :style="{ height: '30%' }"
    >
      <div class="pop-content">
        <Field
            v-model="customerNum"
            name="username"
            label="手机号码"
            placeholder="请输入充电站名称"
            :rules="[{ required: true, message: '请填写手机号码' }]"
        />
        <van-button round type="primary" class="pop-btn">
          提交
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref,onMounted } from 'vue';
import { SwipeCell,Tag,Divider,Dialog,Cell,Tab, Tabs,Field,Search,Notify } from 'vant';
import {useRoute} from "vue-router";
import {apartmentConfig,updateApartmentConfig} from "@/api/station";
import {useRouter} from "vue-router";


export default {
  components:{
    Search,
    SwipeCell,
    Tag,
    Divider,
    Cell,
    Tab,
    Tabs,
    Field,
    Notify
  },
  setup() {
    const router = useRouter();
    const route = useRoute()
    const rowId = ref(route.query.id);
    const active = ref(0);
    const showAdd = ref(false);
    const customerNum = ref('');
    const userWhiteList = ref([]);
    const userBlackList = ref([]);
    const cardWhiteList = ref([]);
    const cardBlackList = ref([]);
    const userFreeList = ref([]);
    const cardFreeList = ref([]);
    const userData = ref({});
    
    onMounted(()=>{
      //查询名单数据
      getData()
      
     });
     const getData = ()=>{
       apartmentConfig(rowId.value).then(res =>{
        const userBWData = res.data;
        userData.value = res.data;
        // 用户白名单
        userWhiteList.value
            = userBWData.userMemberList && userBWData.userMemberList.filter(member => userBWData.userWhiteList.indexOf(member.userId) > -1)
        userWhiteList.value.forEach(item => {
          item.userAmount = (item.account.userAmount / 100).toFixed(2)
          item.presentAmount = (item.account.presentAmount / 100).toFixed(2)
          item.address = item.provinceName + item.cityName
          item.sex = item.gender == 1 ? '男' : '女'
        })

        // 用户黑名单
        userBlackList.value
            = userBWData.userMemberList && userBWData.userMemberList.filter(member => userBWData.userBlackList.indexOf(member.userId) > -1)
        userBlackList.value.forEach(item => {
          item.userAmount = (item.account.userAmount / 100).toFixed(2)
          item.presentAmount = (item.account.presentAmount / 100).toFixed(2)
          item.address = item.provinceName + item.cityName
          item.sex = item.gender == 1 ? '男' : '女'
        })

        // 电卡白名单
        cardWhiteList.value
            = userBWData.cardList && userBWData.cardList.filter(card => userBWData.cardWhiteList.indexOf(card.cardNo) > -1)
        cardWhiteList.value.forEach(item => {
          item.userAmount = (item.account.userAmount / 100).toFixed(2)
          item.presentAmount = (item.account.presentAmount / 100).toFixed(2)
          // this.bizCardStatus.forEach(it => {
          //   if (it.value === item.cardStatus) {
          //     item.cardStatus = it.label;
          //   }
          // })
        })
        console.log('first',cardWhiteList.value)

        // 电卡黑名单
        cardBlackList.value
            = userBWData.cardList && userBWData.cardList.filter(card => userBWData.cardBlackList.indexOf(card.cardNo) > -1)
        cardBlackList.value.forEach(item => {
          item.userAmount = (item.account.userAmount / 100).toFixed(2)
          item.presentAmount = (item.account.presentAmount / 100).toFixed(2)
          // this.bizCardStatus.forEach(it => {
          //   if (it.value === item.cardStatus) {
          //     item.cardStatus = it.label;
          //   }
          // })
        })

        // 用户免费名单
        userFreeList.value
            = userBWData.userMemberList && userBWData.userMemberList.filter(member => userBWData.userFreeGroup.indexOf(member.userId) > -1)
        userFreeList.value.forEach(item => {
          item.userAmount = (item.account.userAmount / 100).toFixed(2)
          item.presentAmount = (item.account.presentAmount / 100).toFixed(2)
          item.address = item.provinceName + item.cityName
          item.sex = item.gender == 1 ? '男' : '女'
        })

        // 电卡免费名单
        cardFreeList.value
            = userBWData.cardList && userBWData.cardList.filter(card => userBWData.cardFreeGroup.indexOf(card.cardNo) > -1)
        cardFreeList.value.forEach(item => {
          item.userAmount = (item.account.userAmount / 100).toFixed(2)
          item.presentAmount = (item.account.presentAmount / 100).toFixed(2)
          // this.bizCardStatus.forEach(it => {
          //   if (it.value === item.cardStatus) {
          //     item.cardStatus = it.label;
          //   }
          // })
        })

      });
     }
    const searchClick = (val)=>{

    }
    const toAdd = () =>{
      router.push({path:"./addBlackList",query:{type:active.value,rowId:rowId.value}})
    }
    
    const onClickTab = () => {
      console.log(active.value);
    }

    const add=()=>{
      showAdd.value = true;
    }

    const deleteRow = (item)=>{
      let delItem = item;
      Dialog.confirm({
        title: '确定删除吗？',
      }).then(() => {
        handleDel(delItem);
      });
    }
    const handleDel=(item)=>{
      let data = userData.value;
      let delItem = item;
      console.log('dddd',data,delItem);
      //删除用户
      if(active.value === 0){
        let index = data.userWhiteList.findIndex(userId => userId == delItem.userId);
        data.userWhiteList.splice(index,1);
      }else if(active.value === 1){
        let index = data.userBlackList.findIndex(userId => userId == delItem.userId);
        data.userBlackList.splice(index,1);
      }else if(active.value === 4){
        let index = data.userFreeGroup.findIndex(userId => userId == delItem.userId);
        data.userFreeGroup.splice(index,1);
      }
      //删除电卡
      else if(active.value === 2){
        let index = data.cardWhiteList.findIndex(cardNo => cardNo == delItem.cardNo);
        data.cardWhiteList.splice(index,1);
      }else if(active.value === 3){
        let index = data.cardBlackList.findIndex(cardNo => cardNo == delItem.cardNo);
        data.cardBlackList.splice(index,1);
      }else if(active.value === 5){
        let index = data.cardFreeGroup.findIndex(cardNo => cardNo == delItem.cardNo);
        data.cardFreeGroup.splice(index,1);
      }
      updateApartmentConfig(data).then(res =>{
        if(res.code === 200){
          Notify({ type: 'success', message: '删除成功' });
          getData()
        }
      })
    }

    return {userData,handleDel,getData,toAdd,active,searchClick,add,deleteRow,showAdd,customerNum,onClickTab,userWhiteList,userBlackList,cardWhiteList,cardBlackList,userFreeList,cardFreeList};
  },
}
</script>

<style lang="scss" scoped>
.container{
  height: 100vh;
  background-color: #FFFFFF;
  .item{
    padding: 12px;
    border-radius: 8px;
    font-size: 12px;
    color: #333;
    border: 1px solid #e0e0e0;
    margin: 12px 12px 0 12px;
    .outer{
      display: flex;
      justify-content: space-between;
      width: 100%;
      .left{
        .account{
          font-size: 16px;
          color: #333333;
          font-weight: 500;
        }
        .nickname{
          font-size: 13px;
          color: #666666;
          margin-top: 8px;
        }
      }
      .right{
        display: flex;
        align-items: center;
        .van-icon{
           width: 32px;
           height: 32px;
        }
      }
    }
  }
}
.add{
    position: fixed;
    right: 24px;
    bottom: 68px;
  }

.tag-cl{
  margin: 5px;
}
.item-content{
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.item-btn{
  display: flex;flex-direction: column;height: 100%
}

.pop-content{
  padding-top: 60px;display: flex;flex-direction:column;align-items: center;justify-content: center
}

.pop-btn{
  margin-top: 20px;width: 200px
}

</style>