import request from '../utils/request.js'

export function getTemplatePage(data) {
    return request({
        method: 'GET',
        url: '/chargeManage/template/page?pageNum=1&pageSize=10',
        data
    })

}

export function getStepGroupPage(data) {
    return request({
        method: 'GET',
        url: '/chargeManage/stepGroup/page?pageNum=1&pageSize=10',
        data
    })

}

export function getCombinationPage(data) {
    return request({
        method: 'GET',
        url: '/chargeManage/combination/page?pageNum=1&pageSize=10',
        data
    })

}

export function deleteTemplate(id) {
    return request({
        method: 'DELETE',
        url: `/chargeManage/template/del/${id}`,
    })

}

export function deleteStepGroup(id) {
    return request({
        method: 'DELETE',
        url: `/chargeManage/stepGroup/del/${id}`,
    })

}

export function deleteCombination(id) {
    return request({
        method: 'DELETE',
        url: `/chargeManage/combination/del/${id}`,
    })

}

export function applyComb(data){
    return request({
        url: '/chargeManage/combination/apply',
        method: 'put',
        data: data
    })
}
