<template> 
    <div class='root'>  
            <van-form >
            <van-cell-group inset class="searchWrapper" >
                <van-field
                    maxlength="11"
                    v-model="value1"
                    :placeholder="placeholderValue"
                    input-align="left" 
                    type="digit" 
                     :rules="rules"      
                >
                </van-field>       
                <div class='searchIcon' @click=handleSearch(value1)><van-icon name="search" size=30 /></div>
            </van-cell-group>
            </van-form>
                   
            <van-tabs v-model:active="active"  color="#07c160" line-height="1px" @click-tab="onClickTab">                      
                <van-tab :title="typeArr[0].text">
                    <PhoneUserList ref="reloadPhone" />              
                </van-tab>
                <van-tab :title="typeArr[1].text">
                    <CardUserList ref="reloadCard"/>
                </van-tab>
            </van-tabs>      
    </div>
</template>

<script>
import { ref,reactive, } from 'vue';
import PhoneUserList from './component/phoneUserList.vue'
import CardUserList from './component/cardUserList.vue'
    export default {
        components:{
            PhoneUserList,  
            CardUserList
        },    
        setup(){
            const value1 = ref('');
            const active = ref(0); 
            const reloadPhone = ref();
            const reloadCard = ref();
            const rules = ref([{ validator:(val) => /1\d{10}/.test(val) , message:'请输入正确手机号' }])
            const placeholderValue = ref('请输入手机号');
            const typeArr = ref([
                {
                    state: 0,
                    text: "手机用户"
                },
                {
                    state: 1,
                    text: "电卡用户"
                },
                ])

         const  handleSearch = (value) => {
             if(placeholderValue.value === "请输入手机号"){ 
                 if(value.length === 11){  
                    // 搜索手机用户列表
                     reloadPhone.value.onRefresh(value)
                 } }else{
                        //  搜索电卡用户列表
                        if(value.length <= 10 && value.length >= 6){
                            reloadCard.value.onRefresh(value)
                        }     
                 }
         }

        const onClickTab = ({title}) => {
            placeholderValue.value = title === typeArr.value[1].text ? "请输入卡号" : "请输入手机号"
            rules.value = title === typeArr.value[1].text ? [{ validator:(val) => /\d{6,10}/.test(val) , message:'请输入正确卡号' }] :[{ validator:(val) => /1\d{10}/.test(val) , message:'请输入正确手机号' }]
            value1.value = ''
            if(title === typeArr.value[0].text){
                reloadPhone.value.onRefresh() 
            }  
            if (title === typeArr.value[1].text){  
                reloadCard.value && reloadCard.value.onRefresh()
            }    
        }   
            return { 
                value1, 
                active,
                typeArr,
                placeholderValue,
                handleSearch,
                rules,
                reloadPhone,
                reloadCard,
                onClickTab,
            };
        }
     }
</script>

<style lang="scss" scoped>

     .searchWrapper{
         display:flex;
         border:1px rgb(100, 99, 99) solid;
         width:90%
     }
     .searchIcon {
         width: 15%;
         height:40px;
         text-align: center;
        //  border: 1px gray solid;
         line-height:40px;
         margin: 10px 10px 0 0;
     }


</style>
