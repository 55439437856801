<template>
  <div :style="{margin:'10px'}">
      <div class='header'>
        <div style="font-size:14px; color:orange;font-weight:600">分配充电站</div>
        <div  style="font-size:14px; color:orange;font-weight:600">合伙人：{{username}}</div>
      </div>

      <div class='search-wrapper'>
          <div  class='search'>
              <Field
                v-model="value"
                placeholder="请输入充电站名称"
            />
          </div>
          <div class='icon' @click="searchStation(value)"> <Icon class='icon-search' name="search" size="25px"/></div>
      </div>

    <List
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
    >
    <template #default>
        <table  width="100%" class="thead-td">
            <thead >
                <tr >
                    <td width='20%'  >序号</td>
                    <td width='40%'>充电站名称</td>
                    <td width='20%'>绑定</td>
                    <td width='20%'>查看上级分润</td>
                </tr>
            </thead>
            <tbody width="100%">
                <tr v-for="(item,index) in dataList" :key="index" class='table-row'>
                    <td class="td">{{item.id}}</td>
                    <td >{{item.apartmentName}}</td>
                    <td><Switch v-model="binded[index]" size="20px" @change="handleBind(item,index,binded[index])"/></td>
                    <td colspan="4"><Switch v-if="binded[index]" size="20px" v-model="checked[index]" @change="checkedUpProfit(item,index,checked[index])"/></td>
                </tr>
            </tbody>
        </table>

    </template>
    </List>

  </div>
</template>

<script>
import {ref,onMounted,deactive, } from 'vue'
import {useRoute} from 'vue-router';
import {Form,Icon,Switch,Field,List} from 'vant';
import { queryStation,chargeStationList } from '@/api/client';
// import List from './components/list.vue'
import {bindPartnerStation,unbindPartnerStation,checkedProfit} from '@/api/client'
export default {
    components:{
        Form,
        Icon,
        Switch,
        List,
        Field
    },
    setup() {
        const route = useRoute()
        const dataList = ref([]);
        const total = ref(0);
        const value = ref('')
        const checked = ref([]);
        const binded = ref([]);
        const listref = ref(null);
        const matchId = ref(0);
        const loading = ref(false);
       const finished = ref(false);
        let userId = route.params.id;
        let username = route.query.username;

        onMounted(()=>{
            queryStationList()
        })

        const queryStationList = () => {
            const params ={pageNum:1,pageSize:10000
            }
            loading.value = true
            chargeStationList(params).then(res => {
                dataList.value = res.rows
                total.value = res.total

                for(let i=0; i < res.rows.length; i++){
                    binded.value.push(false)
                    checked.value.push(false)
                }
            queryStation(userId,params).then( res => {
                if(res.code === 200){
                    res.data.map((item2,index)=>{
                        dataList.value && dataList.value.filter((item1,index) =>{
                            if(item1.id === item2.apartmentId) {
                                binded.value[index] = true
                              if (item2.isCheckOtherRatio){
                                checked.value[index] = true
                              }
                            }
                        })
                    })
                }

             loading.value = false;
                // 数据全部加载完成
                if (dataList.value.length >= total.value) {
                finished.value = true;
                }
            })
            })
        }

        const searchStation = (value) => {
           /* if(value){
            dataList.value =  dataList.value.filter((item)=>{
                    return item.apartmentName === value
                })
            }else{
                queryStationList()
            }*/
          chargeStationList({apartmentName:value}).then(res=>{
            dataList.value=res.rows
          })
        }

        const  handleBind = (item,index,state) => {
            const data = {
                apartmentId: item.id,
                partnerId: Number(userId),
            }

            if(state === true){
                // 绑定充电站
                bindPartnerStation(data).then(res => {
                    if(res.code !== 200){
                        binded.value[index] = false
                    }
                })
            }else{
                //解绑充电站
                unbindPartnerStation(data).then(res =>{
                    if(res.code !== 200){
                        binded.value[index] = true
                    }
                })
            }
        }

            const checkedUpProfit = (item,index,state) => {

                const data ={
                    apartmentId: item.id,
                    checkStatus: state,
                    partnerId: Number(userId),
                }

                checkedProfit(data).then(res =>{
                    if(res.code !== 200){
                        checked.value[index] = !state;
                      console.log(checked.value[index],'111')
                    }
                })

            }

        return {
            username,
            dataList,
            binded,
            value,
            matchId,
            checked,
            listref,
            loading,
            finished,
            handleBind,
            searchStation,
            queryStationList,
            checkedUpProfit,
        }
    }

}
</script>

<style scoped>
.body{
    margin: 8px;
}
.header{
    display: flex;
    justify-content: space-between;
    padding:8px 15px;
}
.search-wrapper{
    margin: 10px;
    display: flex;
    justify-content:center;
    align-items: center;
    border:0.5px solid gray;
    border-radius: 30px;
    height: 40px;
    overflow: hidden;
}
.search{
    flex:88%;
    overflow: hidden;
    margin-right: 10px;
}
.form {
    display: flex;
}

.icon {
    width:12%;
    text-align: center;
}
.table-row{
    width:100%;
    font-size: 16px;
}
.van-list {
    margin-left:10px ;
}

.td{
    padding:15px 0;

}

</style>