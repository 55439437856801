import request from '../utils/request.js'

export function getTemplateType(data) {
    return request({
        method: 'GET',
        url: '/system/dict/data/type/ac_template_type',
        data
    })

}

export function addTemplate(data) {
    return request({
        method: 'POST',
        url: '/chargeManage/template/add',
        data
    })

}

export function addStepGroup(data) {
    return request({
        method: 'POST',
        url: '/chargeManage/stepGroup/add',
        data
    })
}

export function editStepGroup(data) {
    return request({
        url: '/chargeManage/stepGroup/edit',
        method: 'put',
        data: data
    })
}

export function editTemplate(data) {
    return request({
        url: '/chargeManage/template/edit',
        method: 'put',
        data: data
    })
}

export function editComb(data) {
    return request({
        url: '/chargeManage/combination/edit',
        method: 'put',
        data: data
    })
}

export function queryStepGroup(gId) {
    return request({
        url: '/chargeManage/stepGroup/query/' + gId,
        method: 'get'
    })
}

export function queryTemplate(tplId) {
    return request({
        url: '/chargeManage/template/query/' + tplId,
        method: 'get'
    })
}

export function queryComb(gId) {
    return request({
        url: '/chargeManage/combination/query/' + gId,
        method: 'get'
    })
}



