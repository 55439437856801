<template>
  <van-form @submit="onSubmit" error-message-align="right">
    <van-cell-group inset>

      <van-field
          name="combName"
          label="方案名称"
          placeholder="请输入方案名称"
          input-align="right"
          v-model="combName"
          :rules="[{ required: true, message: '请填写方案名称' }]"
      />
      <van-cell center title="电卡充电">
        <template #right-icon>
          <van-switch v-model="checked" size="24" active-color="#32CD32" inactive-color="#ee0a24"/>
        </template>
      </van-cell>


      <van-field
          v-if="checked==true"
          v-model="Program"
          is-link
          readonly
          label="组合方案"
          placeholder="选择组合方案"
          input-align="right"
          @click="showPicker = true"
          :rules="[{ required: true, message: '请选择组合方案' }]"
      />
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker title="选择组合方案"
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="onCancel"
        />

      </van-popup>
      <van-field
          v-if="checked==true"
          v-model="LadderGroup"
          is-link
          readonly
          label="阶梯组"
          placeholder="选择阶梯组"
          input-align="right"
          @click="showLadderGroup = true"
          :rules="[{ required: true, message: '请选择阶梯组' }]"
      />
      <van-popup v-model:show="showLadderGroup" round position="bottom">
        <van-picker title="选择阶梯组"
                    :columns="ladderGroupList"
                    @confirm="onConfirmLadderGroup"
                    @cancel="onCancelLadderGroup"
        />
      </van-popup>

      <van-cell center title="扫码充电">
        <template #right-icon>
          <van-switch v-model="tested" disabled="false" size="24" active-color="#32CD32" inactive-color="#ee0a24"/>
        </template>
      </van-cell>
      <van-field
          v-if="checked1==true"
          v-model="Program1"
          is-link
          readonly
          label="组合方案"
          placeholder="选择组合方案"
          input-align="right"
          @click="showPicker1 = true"
          :rules="[{ required: true, message: '请选择组合方案' }]"
      />
      <van-popup v-model:show="showPicker1" round position="bottom">
        <van-picker title="选择组合方案"
                    :columns="columns"
                    @confirm="onConfirm1"
                    @cancel="onCancel1"
        />

      </van-popup>
      <van-field
          v-if="checked1==true"
          v-model="LadderGroup1"
          is-link
          readonly
          label="阶梯组"
          placeholder="选择阶梯组"
          input-align="right"
          @click="showLadderGroup1 = true"
          :rules="[{ required: true, message: '请选择阶梯组' }]"
      />
      <van-popup v-model:show="showLadderGroup1" round position="bottom">
        <van-picker title="选择阶梯组"
                    :columns="ladderGroupList1"
                    @confirm="onConfirmLadderGroup1"
                    @cancel="onCancelLadderGroup1"
        />
      </van-popup>


      <van-field
          v-model="remark"
          name="remark"
          label="备注"
          placeholder="请填写备注"
          input-align="right"
      />
    </van-cell-group>
    <div style="margin: 16px">
      <van-button round block type="primary" native-type="submit" @submit="onSubmit">
        提交
      </van-button>
    </div>
  </van-form>
</template>
<script>
import {onMounted, ref} from 'vue';
import {addStepGroup, getTemplateType} from "@/api/programmeAdd";
import {Notify} from "vant";
import {useRoute, useRouter} from "vue-router";
import {addCombination, getStepGroupTypeList, getTemplateTypeList} from "@/api/schemeAdd";

export default {
  setup() {
    const route = useRoute()
    const applyType = ref(JSON.parse(route.query.applyType));
    const router = useRouter()
    const remark = ref('')
    const combName = ref('')
    const checked = ref(true);
    const checked1 = ref(true);
    const tested = ref(true);
    const Program = ref('');
    const Program1 = ref('');
    const LadderGroup = ref('');
    const LadderGroup1 = ref('');
    const showPicker = ref(false);
    const showPicker1 = ref(false);
    const showLadderGroup = ref(false);
    const showLadderGroup1 = ref(false);
    const columns = []
    const ladderGroupList = ref([])
    const ladderGroupList1 = ref([])
    const items1 = []
    const items2 = []
    const items3 = []
    const columns1 = {}
    const columns2 = {}
    const columns3 = {}
    const modeType = ref('')
    const modeType1 = ref('')
    const stepId = ref('')
    const stepId1 = ref('')
    const tplId = ref('')
    const tplId1 = ref('')
    const resultValues = {stepTpls: []}

    onMounted(() => {
      onStepGroupAdd();
    });


    const zzz = ref([])
    getTemplateType().then(res => {
      res.data.forEach(item => {
            if (applyType.value==0){
              if (item.dictLabel == '功率模式') {
                columns1.text = '功率模式'
                getTemplateTypeList({modeType: 1,applyType:applyType.value}).then(res => {
                  res.rows.forEach(item => {
                    zzz.value.push({tplName: item.tplName, tplId: item.id})
                    items1.push(item.tplName)
                  })
                  columns1.children = items1
                  columns.push(columns1)
                })
              } else if (item.dictLabel == '时间模式') {
                columns2.text = '时间模式'
                getTemplateTypeList({modeType: 2,applyType:applyType.value}).then(res => {
                  res.rows.forEach(item => {
                    zzz.value.push({tplName: item.tplName, tplId: item.id})
                    items2.push(item.tplName)
                  })
                  columns2.children = items2
                  columns.push(columns2)
                })
              } else if (item.dictLabel == '电量模式') {
                columns3.text = '电量模式'
                getTemplateTypeList({modeType: 3,applyType:applyType.value}).then(res => {
                  res.rows.forEach(item => {
                    zzz.value.push({tplName: item.tplName, tplId: item.id})
                    items3.push(item.tplName)
                  })
                  columns3.children = items3
                  columns.push(columns3)
                })
              }
            }else {
              if (item.dictLabel == '电量模式') {
                columns3.text = '电量模式'
                getTemplateTypeList({modeType: 3,applyType:applyType.value}).then(res => {
                  res.rows.forEach(item => {
                    zzz.value.push({tplName: item.tplName, tplId: item.id})
                    items3.push(item.tplName)
                  })
                  columns3.children = items3
                  columns.push(columns3)
                })
              }
            }
          }
      )
    });
    const www = ref([])
    const onStepGroupAdd=()=>{
      if (applyType.value==1){
        getStepGroupTypeList({modeType:modeType.value,applyType:applyType.value}).then(res => {
          res.rows.forEach(item => {
            www.value.push({groupName: item.groupName, stepId: item.id,modeType:item.modeType})
            ladderGroupList.value.push(item.groupName)
            ladderGroupList1.value.push(item.groupName)
          })
          console.log(JSON.stringify(www.value))
        })
      }else {
        getStepGroupTypeList({modeType:modeType.value,applyType:applyType.value}).then(res => {
          res.rows.forEach(item => {
            www.value.push({groupName: item.groupName, stepId: item.id,modeType:item.modeType})
            ladderGroupList.value.push(item.groupName)
            ladderGroupList1.value.push(item.groupName)
          })
          console.log(JSON.stringify(www.value))
        })
      }
    }

    const onSubmit = (values) => {
      console.log(values)
      resultValues.combName = values.combName
      resultValues.remark = values.remark
      resultValues.stepTpls.push({
        clientType: 1,
        modeType: modeType.value,
        stepId: stepId.value,
        tplId: tplId.value
      }, {
        clientType: 2,
        modeType: modeType1.value,
        stepId: stepId1.value,
        tplId: tplId1.value
      })
      console.log(resultValues)
      resultValues.applyType=applyType.value
      addCombination(resultValues).then(result => {
        console.log(result)
        if (result.code == 200) {
          router.push({path: `/priceTemplate`});
          // 成功通知
          Notify({type: 'success', message: '新增方案成功'});
        } else {
          Notify({type: 'danger', message: '新增方案失败'});
        }
      })
    }
    const onConfirm = (values) => {
      LadderGroup.value = '';
      Program.value = values[0].text + " / " + values[1]
      zzz.value.forEach(item => {
        if (values[1] == item.tplName) {
          tplId.value = item.tplId
        }
      })
      if (values[0].text == '功率模式') {
        modeType.value = 1
      } else if (values[0].text == '时间模式') {
        modeType.value = 2
      } else if (values[0].text == '电量模式') {
        modeType.value = 3
      }
      //判断 阶梯组选择区间
      
      let arr = [];
      let ladderGroupArr = JSON.parse(JSON.stringify(www.value))
      ladderGroupArr.map((item) => {
        if(item.modeType === modeType.value){
           arr.push(item.groupName)
        }
      })
      ladderGroupList.value = arr;
      console.log('arr',arr)
      showPicker.value = false
    }
    const onCancel = (values) => {
      showPicker.value = false
    }

    const onConfirmLadderGroup = (values) => {
      www.value.forEach(
          item => {
            if (item.groupName == values) {
              stepId.value = item.stepId
            }
          }
      )
      LadderGroup.value = values
      showLadderGroup.value = false
    }
    const onCancelLadderGroup = (values) => {
      showLadderGroup.value = false
    }

    const onConfirm1 = (values) => {
      LadderGroup1.value = '';
      Program1.value = values[0].text + " / " + values[1]
      zzz.value.forEach(item => {
        if (values[1] == item.tplName) {
          tplId1.value = item.tplId
        }
      })
      if (values[0].text == '功率模式') {
        modeType1.value = 1
      } else if (values[0].text == '时间模式') {
        modeType1.value = 2
      } else if (values[0].text == '电量模式') {
        modeType1.value = 3
      }
      let arr = [];
      let ladderGroupArr = JSON.parse(JSON.stringify(www.value))
      ladderGroupArr.map((item) => {
        if(item.modeType === modeType1.value){
           arr.push(item.groupName)
        }
      })
      console.log('arr',ladderGroupArr)

      ladderGroupList1.value = arr;
      showPicker1.value = false
    }
    const onCancel1 = (values) => {
      showPicker1.value = false
    }

    const onConfirmLadderGroup1 = (values) => {
      www.value.forEach(
          item => {
            if (item.groupName == values) {
              stepId1.value = item.stepId
            }
          }
      )
      LadderGroup1.value = values
      showLadderGroup1.value = false
    }
    const onCancelLadderGroup1 = (values) => {
      showLadderGroup1.value = false
    }


    return {
      resultValues,
      remark,
      combName,
      checked,
      checked1,
      tested,
      onSubmit,
      onConfirm,
      onConfirmLadderGroup,
      onCancel,
      onCancelLadderGroup,
      onConfirm1,
      onConfirmLadderGroup1,
      onCancel1,
      onCancelLadderGroup1,
      columns,
      ladderGroupList,
      ladderGroupList1,
      Program,
      Program1,
      LadderGroup,
      LadderGroup1,
      showPicker,
      showPicker1,
      showLadderGroup,
      showLadderGroup1,
    }
  }
}
</script>


<style lang="scss" scoped>
.list {
  width: 100%;

  .item {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    position: relative;

    .lf {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .van-field {
        padding: 0 !important;
        width: 70px;
      }

      & > span {
        font-size: 12px;
      }
    }

    .lf_two {
      .van-field {
        padding: 0 !important;
        width: 50px;
      }
    }

    .add {
      position: absolute;
      right: -16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .label {
    margin-top: -10px;
    margin-right: 10px;
    font-size: var(--van-cell-font-size);
    color: var(--van-field-label-color);
  }
}

:deep(.td) {
  width: 30px !important;
}
</style>
