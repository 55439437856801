import request from '../utils/request.js'

//设备统计
export function deviceAggregate(data){
    return request({
        method:'GET',
        url:'/dev-api/agent/stat/device/numerical/aggregate',
        data
    })
}

//首页统计
export function indexAggregate(data){
    return request({
        method:'GET',
        url:'/agent/stat/index/numerical/aggregate',
        data
    })
}

export function aggregateStation(stationKindType){
    return request({
        method:'GET',
        url:'/agent/stat/index/numerical/aggregate/station/'+stationKindType,
    })
}

export function indexNumeric(data){
    return request({
        method:'GET',
        url:'/stat/index/numeric',
        data
    })
}
export function deviceCount(){
    return request({
        method:'GET',
        url:'/device/page',
        params:{
            pageNum :1,
            pageSize :10000
        }
    })
}

export function getAgentInfo() {
    return request({
        url: '/getAgentInfo',
        method: 'get'
    })
}


// 首页收入统计折线图
export function getIncome(query) {
  return request({
      url: '/agent/stat/graph/trend/income',
      method: 'get',
      params: query
  })
}
// 首页订单统计折线图
export function getOrder(query) {
  return request({
      url: '/agent/stat/graph/trend/order',
      method: 'get',
      params: query
  })
}
// 首页电量统计折线图
export function getElect(query) {
  return request({
      url: '/agent/stat/graph/trend/electricity',
      method: 'get',
      params: query
  })
}