<template safe-area-inset-top safe-area-inset-bottom>
 <div style="height: 100vh">
   <NavBar left-arrow left-text="返回" :title="title" @click-left="onClickLeft" v-if="!showTabbar"/>
   <router-view/>
   <van-tabbar safe-area-inset-bottom  v-model="active" active-color="#00d655" inactive-color="#000" @change="onChangeTabbar" v-if="showTabbar">
     <van-tabbar-item icon="home-o">首页</van-tabbar-item>
     <van-tabbar-item icon="more-o">更多</van-tabbar-item>
     <van-tabbar-item icon="user-o">我的</van-tabbar-item>
   </van-tabbar>
 </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref,watch } from 'vue';
import { NavBar } from 'vant';

const routeList = ['/','/more','/user'];
export default {
  components:{
    NavBar
  },
  setup() {
    const router = useRouter()
    const active = ref(0);
    const showTabbar = ref(true);
    const title = ref('')
    watch(() =>router.currentRoute.value.path,(newValue,oldValue)=> {
      console.log('currentRoute',newValue)
      if(routeList.includes(newValue)){
        showTabbar.value = true;
      }else{
        showTabbar.value = false;
        const {meta} = router.currentRoute.value;
        title.value = meta.title;
      }
      if(newValue === '/'){
        active.value = 0
      }else if(newValue === '/more') {
        active.value = 1
      }else if(newValue === '/user'){
        active.value = 2
      }


    },{ immediate: true });
    const onChangeTabbar = (index) =>{
      console.log(router)
      if(index === 0){
        router.push({ path: "/" })
      }
      if(index === 1){
        router.push({ path: "/more" })
      }

      if(index === 2){
        router.push({ path: "/user" })
      }
    }
    const onClickLeft = ()=>{
        router.back();
    }
    window.localStorage.setItem('scanUrl',location.href.split('#')[0])

    return { active,onChangeTabbar,showTabbar,onClickLeft,title };
  },
}
</script>
<style lang="scss">

</style>
