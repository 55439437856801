<template>
  <div class="box">
    <div class="top">
      <div class="top-search">
        <van-search v-model="name" placeholder="请输入搜索关键词" @search="searchClick"/>
        <van-icon class="add" name="add-o" @click="add"/>
      </div>
      <van-divider/>
      <div class="tabBox">
        <van-tabs v-model:active="activeType"  @click="onClickTab(activeType)">
          <van-tab :title="item.name" :key="index" v-for="(item,index) in deviceTypeList"></van-tab>
        </van-tabs>
      </div>
      <div class="tabBox">
        <van-tabs v-model:active="active" @change="tabChange" @click="onClick(active)">
          <van-tab :title="item.name" :key="index" v-for="(item,index) in typeList"></van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="list">
      <!-- <div class="list-header">
        <div v-for="(item,index) in typeList[active].title">{{ item.name }}</div>
      </div>
      <van-divider :style="{ margin: 0 }"/> -->
      <div>
        <ListPage @register="register">
          <template #item="{dataList}">
            <!-- <div :key="index" v-for="(item,index) in dataList">
              <van-swipe-cell>
                <template #right>
                  <van-button style="height: 100%;font-size: 12px;" square type="primary" text="编辑"
                              class="update-button"
                              @click="updateRow(item.id,active)"/>
                  <van-button v-if="active==2" style="height: 100%;font-size: 12px;" square type="success" text="应用"
                              @click="apply(item.id)"/>
                  <van-button style="height: 100%;font-size: 12px;" square text="删除" type="danger"
                              class="delete-button"
                              @click="deleteRow(item.id)"/>

                </template>
                <template #default>
                  <div class="list-item">
                    <div v-if="active==0">{{ item.tplName }}</div>
                    <div v-else-if="active==1">{{ item.groupName }}</div>
                    <div v-if="active==2">{{ item.combName }}</div>
                    <div v-if="item.modeType===1">{{ "功率模式" }}</div>
                    <div v-else-if="item.modeType===2">{{ "时间模式" }}</div>
                    <div v-else-if="item.modeType===3">{{ "电量模式" }}</div>
                  </div>
                </template>
              </van-swipe-cell>
              <van-divider :style="{ margin: 0 }"/>
            </div> -->
            <div style="margin-top: 5px; padding: 0 12px;">
              <div v-for="(item,index) in dataList" :key="index"  @click="handleClick(item,index)">
                <div class="item" :class="currentIndex === index ? 'item-checked':''"> 
                  <div class="title">{{filtersName(item)}}</div>
                  <div class="type" v-if="active===0 || active===1">类型：{{filtersType(item)}}</div>
                </div>
              </div>
            </div>  
          </template>
        </ListPage>
      </div>
    </div>
    <div v-if="show" class='bottom-button'>
          <Button type="primary" block style="flex:1;" size=large @click='updateRow(active)'>编辑</Button>
          <Button type="success" v-if="active==2"  block style="flex:1;" size=large @click='apply'>应用</Button>
          <Button type="danger" block style="flex:1" size=large @click="deleteRow">删除</Button>
           
      </div>
  </div>
</template>
<script>
import {
  deleteCombination,
  deleteStepGroup,
  deleteTemplate,
  getCombinationPage,
  getStepGroupPage,
  getTemplatePage
} from '@/api/priceTemplate';
import {onMounted, ref} from 'vue';
import {useRouter} from "vue-router";
import {useListPage} from "@/components/ListPage";
import ListPage from "@/components/ListPage/ListPage";
import {Dialog,Button} from "vant";
import {aggregation, listStation} from "@/api/device";

export default {
  components: {ListPage,Button},
  setup() {
    const typeList = ref([
      {id: 1, name: '模版', title: [{name: '模板名称'}, {name: '模式类型'}]},
      {id: 2, name: '阶梯', title: [{name: '阶梯名称'}, {name: '模式类型'}]},
      {id: 3, name: '方案', title: [{name: '方案名称'}]}
    ]);
    const deviceTypeList = ref([
      {id: 1, name: '两轮充电站', title: [{name: '两轮充电站'}]},
      {id: 2, name: '汽车充电站', title: [{name: '汽车充电站'}]},
    ]);
    const activeType = ref(0)
    const router = useRouter();
    const refreshing = ref(false);
    const list = ref([1, 2, 3, 4, 5]);
    const active = ref(0)
    const loading = ref(true)
    const finished = ref(false)
    const currentIndex = ref(-1);
    const sampleId = ref(0)
    const show = ref(false);

    const name = ref('')
    const apiUrl = ref('/chargeManage/template/page');
    const type = ref(0)
    const [register, {setProps, queryData, resetData, deleteItem}] = useListPage({
      apiUrl: apiUrl,
      requestParam: {}
    });

    onMounted(() => {
      console.log(active.value);
      // queryData();
      let pageSize=10;
      setProps({apiUrl,pageSize,requestParam:{applyType:activeType.value}});
      resetData()
    });
    const handleClick = (item,index) => {
      sampleId.value = item.id;
      show.value = true;
      currentIndex.value = index;
    }
    const searchClick = (name) => {
      let pageSize = 10;
      if (active.value === 0) {
        apiUrl.value = '/chargeManage/template/page';
        setProps({apiUrl, pageSize, requestParam: {tplName: name,applyType:activeType.value}});
      } else if (active.value === 1) {
        apiUrl.value = '/chargeManage/stepGroup/page';
        setProps({apiUrl, pageSize, requestParam: {groupName: name,applyType:activeType.value}});
      } else if (active.value === 2) {
        apiUrl.value = '/chargeManage/combination/page';
        setProps({apiUrl, pageSize, requestParam: {combName: name,applyType:activeType.value}});
      }
      resetData()
    }
    const filtersName = (item) => {
      if (active.value === 0) {
        return item.tplName;
      } else if (active.value === 1) {
        return item.groupName;
      } else if (active.value === 2) {
        return item.combName;
      }
    }
    
    const filtersType = (item) => {
      if (item.modeType === 1) {
        return "功率模式" 
      } else if (item.modeType === 2) {
        return "时间模式";
      } else if (item.modeType === 3) {
        return "电量模式";
      }
    }

    const onClickTab = (activeType) => {
      let pageSize = 10;
      show.value = false;
      currentIndex.value = -1;
      console.log(activeType,'activeTypeactiveTypeactiveTypeactiveType')
      console.log(active.value)
      if (activeType==0){
        if (active.value === 0) {
          apiUrl.value = '/chargeManage/template/page';
          setProps({apiUrl,pageSize,requestParam:{applyType:activeType}});
          resetData()
        } else if (active.value === 1) {
          apiUrl.value = '/chargeManage/stepGroup/page';
          setProps({apiUrl,pageSize,requestParam:{applyType:activeType}});
          resetData()
        } else if (active.value === 2) {
          apiUrl.value = '/chargeManage/combination/page';
          setProps({apiUrl,pageSize,requestParam:{applyType:activeType}});
          resetData()
        }
      }else if (activeType==1){
        if (active.value === 0) {
          apiUrl.value = '/chargeManage/template/page';
          setProps({apiUrl,pageSize,requestParam:{applyType:activeType}});
          resetData()
        } else if (active.value === 1) {
          apiUrl.value = '/chargeManage/stepGroup/page';
          setProps({apiUrl,pageSize,requestParam:{applyType:activeType}});
          resetData()
        } else if (active.value === 2) {
          console.log('走了吗  吗吗吗密码吗吗')
          apiUrl.value = '/chargeManage/combination/page';
          setProps({apiUrl,pageSize,requestParam:{applyType:activeType}});
          console.log('走了吗  222')
          resetData()
        }
      }
    }

    const onClick = (active) => {
      let pageSize=10;
      show.value = false;
      currentIndex.value = -1;
      if (active === 0) {
        apiUrl.value = '/chargeManage/template/page';
        setProps({apiUrl,pageSize,requestParam:{applyType:activeType.value}});
        resetData()
      } else if (active === 1) {
        apiUrl.value = '/chargeManage/stepGroup/page';
        setProps({apiUrl,pageSize,requestParam:{applyType:activeType.value}});
        resetData()
      } else if (active === 2) {
        apiUrl.value = '/chargeManage/combination/page';
        setProps({apiUrl,pageSize,requestParam:{applyType:activeType.value}});
        resetData()
      }
    }
    const updateRow = (active) => {
      let id = sampleId.value; 
      if (active === 0) {
        router.push({path: '/programmeUpdate', query: {id: id}})
      } else if(active === 1){
        router.push({path: '/ladderUpdate', query: {id: id}})
      }else if (active===2){
        router.push({path: '/schemeUpdate', query: {id: id}})
      }
    }

    const apply = () => {
      let id = sampleId.value; 
      router.push({path: 'schemeApplication', query: {id: id,stationKindType:activeType.value}})
    }
    const cleanStatus = () => {
      show.value = false;
      currentIndex.value = -1;
    }


    const deleteRow = () => {
      let id = sampleId.value; 
      console.log(active.value)
      Dialog.confirm({
        title: '确定删除吗？',
      }).then(() => {
        //删除本地数据
        if (active.value === 0) {
          deleteTemplate(id).then(res => {
            resetData();
            queryData();
            cleanStatus();

          })
        } else if (active.value === 1) {
          deleteStepGroup(id).then(res => {
            resetData();
            queryData();
            cleanStatus();
          })
        } else if (active.value === 2) {
          deleteCombination(id).then(res => {
            resetData();
            queryData();
            cleanStatus();
          })
        }
      });
    }

    const loadList = () => {
      getTemplatePage();
    };
    const onRefresh = () => {
      loadList();
      refreshing.value = false;
    }
    const tabChange = () => {
      /*if (active.value === 0) {
        apiUrl.value = '/chargeManage/template/page';
      } else if (active.value === 1) {
        apiUrl.value = '/chargeManage/stepGroup/page';
      } else if (active.value === 2) {
        apiUrl.value = '/chargeManage/combination/page';
      }
      queryData();*/
    }
    const add = () => {
      if (active.value === 0) {
        router.push({path: "/programmeAdd", query: {add: "programmeAdd",applyType:activeType.value}})
      } else if (active.value === 1) {
        router.push({path: "/ladderAdd", query: {add: "ladderAdd",applyType:activeType.value}})
      } else if (active.value === 2) {
        router.push({path: "/schemeAdd", query: {add: "schemeAdd",applyType:activeType.value}})
      }
    }


    return {
      onClickTab,
      deviceTypeList,
      activeType,
      add,
      onClick,
      sampleId,
      name,
      type,
      register,
      searchClick,
      deleteRow,
      updateRow,
      apply,
      typeList,
      refreshing,
      list,
      active,
      loading,
      finished,
      loadList,
      onRefresh,
      tabChange,
      filtersName,
      filtersType,
      currentIndex,
      handleClick,
      show,
      cleanStatus
    };
  },
}
</script>
<style lang="scss" scoped>
.box {
  height: calc(100vh - 46px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
  background: #f5f5f5;

  .top {
    //margin-bottom: 12px;
    width: 100%;

    .top-search {
      padding: 12px;
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      box-sizing: border-box;

      .van-search {
        padding: 0 !important;
        flex: 1 !important;
      }

      .add {
        font-size: 20px;
        margin-left: 12px;
        color: #4fc08d;
      }
    }

    .van-divider {
      margin: 0 !important;
    }

    .tabBox {
      width: 100%;
    }
  }

  .list {
    overflow-y: auto;
    margin-top: 5px;
    flex: 1;
    width: 100%;
    box-sizing: border-box;

    .list-header, .list-item {
      padding: 0 12px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
    }
    .item{
      padding: 12px;
      background-color: #FFFFFF;
      border-radius: 5px;
      margin-bottom: 12px;
      .title {
        font-size: 16px;
        color: #333333;;
        font-weight: 500;
      }

      .type {
        font-size: 13px;
        color: #666666;
        margin-top: 8px;
      }
    }
    .item-checked{
      background-color: #00d655;
      .title{
       color: #FFFFFF;
      }
      .type{
       color: #FFFFFF;
      }
    }
  }
}
.bottom-button{
      display: flex;
      width:100%;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
  }

</style>