<template>
  <div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <div v-if="showSearchPhone">
          <van-field
            v-model="phone"
            name="phone"
            label="手机号码"
            maxlength="11"
            type="tel"
            @update:model-value="onInputChange"
            placeholder="请输入用户登录小程序时的手机号"
            :rules="[{ required: true, message: '请填写手机号' }]"
          />
        </div>
        <div v-else>
          <van-field
            v-model="eCard"
            name="eCard"
            label="电卡卡号"
            type="number"
            @update:model-value="onInputEcard"
            placeholder="请填写电卡卡号"
            :rules="[{ required: true, message: '请填写电卡卡号' }]"
          />
        </div> 
        <!-- 控制查询手机号的结果  -->
        <div v-if="showUserInfo">
          <van-field name="user" label="用户">
            <template #input>
              <div class="user-info">
                <van-image 
                  round
                  fit="cover"
                  class="icon"
                  :src="data.avatar"
                />
                <div class="name">{{data.nickname}}</div>
              </div>
            </template>
          </van-field>
          <van-field name="accont" label="用户余额">
            <template #input>
              <div class="user-info">
                <div class="balance">{{data.account.userAmount + data.account.presentAmount/100 || 0}}元</div>
              </div>
            </template>
          </van-field>
        </div>
         <!-- 控制查询电卡的结果  -->
        <div v-if="showEcardInfo">
          <van-field name="user" label="用户">
            <template #input>
              <div class="user-info">
                <van-image 
                  round
                  fit="cover"
                  class="icon"
                  :src="eCardData.member ? eCardData.member.avatar:  ''"
                />
                <div class="name">{{eCardData.member ? eCardData.member.nickname : '未绑用户'}}</div>
              </div>
            </template>
          </van-field>
          <van-field name="accont" label="用户余额">
            <template #input>
              <div class="user-info">
                <div class="balance">{{eCardData.balance/100 || 0}}元</div>
              </div>
            </template>
          </van-field>
        </div>
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { phoneSearch,searchCardNum } from "@/api/consumer";
import {apartmentConfig,updateApartmentConfig} from "@/api/station";
import { Notify} from "vant";



export default {
  data(){
    return{
      rowId: "",
      type:"", // 0新增用户白名单 1新增用户黑名单 2新增电卡白名单 3新增电卡黑名单 4新增用户免费充电名单 5 新增电卡免费充电名单
      phone:"",
      eCard:"",
      showUserInfo: false,
      showEcardInfo: false,
      data:{}, //手机号匹配的 用户数据
      eCardData:{},//电卡匹配的 用户数据
      userData: {}, // 充电站 匹配的 黑白名单数据
      showSearchPhone: true
    }
  },
  methods:{
    onInputChange(){
      if(this.phone.length === 11){
         //查询用户
        phoneSearch(this.phone).then(response => {
          if(response.code === 200){
          this.data = response.data;
          this.showUserInfo = true;
          }
        });
      }else{
          this.showUserInfo = false;
      }
    },
    onInputEcard(){
      if(this.eCard.length >= 6){
        //查询电卡
        searchCardNum(this.eCard).then(response => {
          if(response.code === 200){
            if(response.data){
              this.eCardData = response.data;
              this.showEcardInfo = true;
            }
          }
        });
      }else{
        this.showEcardInfo = false;
      }
    },
    getUserList(id){
      apartmentConfig(id).then(response => {
          if(response.code === 200){
            this.userData = response.data;
          }
      });
    },
    addUser(){
      if(!this.showUserInfo){
        Notify({ type: 'warning', message: '暂无此账户' });
        return;
      }
      let data = this.userData;
      if(this.type==='0'){
        data.userWhiteList.push(this.data.userId);
        data.userWhiteList = data.userWhiteList.filter(u => u > 0);
      }else if(this.type==='1'){
        data.userBlackList.push(this.data.userId);
        data.userBlackList = data.userBlackList.filter(u => u > 0);
      }else if(this.type==='4'){
        data.userFreeGroup.push(this.data.userId);
        data.userFreeGroup = data.userFreeGroup.filter(u => u > 0);
      }
      updateApartmentConfig(data).then(response => {
        if(response.code === 200){
          Notify({ type: 'success', message: '新增成功' });
          this.$router.back({query:{type: this.type}})
        }
      });
    },
    addEcard(){
      if(!this.showEcardInfo){
        Notify({ type: 'warning', message: '暂无此电卡' });
        return;
      }
      let data = this.userData;
      if(this.type==='2'){
        data.cardWhiteList.push(this.eCardData.cardNo);
        data.cardWhiteList = data.cardWhiteList.filter(u => u > 0);
      }else if(this.type==='3'){
        data.cardBlackList.push(this.eCardData.cardNo);
        data.cardBlackList = data.cardBlackList.filter(u => u > 0);
      }else if(this.type==='5'){
        data.cardFreeGroup.push(this.eCardData.cardNo);
        data.cardFreeGroup = data.cardFreeGroup.filter(u => u > 0);
      }
      updateApartmentConfig(data).then(response => {
        if(response.code === 200){
          Notify({ type: 'success', message: '新增成功' });
          this.$router.back({query:{type: this.type}})
        }
      });
    },
    onSubmit(){
      //用户逻辑
      if(this.type==='0' || this.type==='1' || this.type==='4'){
        this.addUser();
      }
      //电卡逻辑
      else{
        this.addEcard();
      }
      

    },
    queryRoute(){
      let qType = this.$route.query.type;
      console.log('first',this.$route.query.type)
      this.type = this.$route.query.type;
      //判断输入框展示 搜索电卡 还是 搜索手机号码
      if(qType === '0' || qType === '1' || qType === '4'){
        this.showSearchPhone = true;
      }else {
        this.showSearchPhone = false;
      }
      //查询名单信息
      this.getUserList(this.$route.query.rowId);
    }
  },
  created(){
    
  },
  mounted(){
    this.queryRoute()
  }

}
</script>

<style lang="scss" scoped>
.user-info{
  display: flex;
  .icon{
    width: 40px;
    height: 40px;
  }
  .name{
    font-size: 16px;
    margin-left: 12px;
    height: 40px;
    line-height: 40px;
    width: 172px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .balance{
    font-size: 16px;
  }
}
</style>