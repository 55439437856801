

Date.prototype.format = function(fmt = 'yyyy-MM-dd hh:mm:ss') {
	var o = {
		"M+": this.getMonth() + 1, //月份
		"d+": this.getDate(), //日
		"h+": this.getHours(), //小时
		"m+": this.getMinutes(), //分
		"s+": this.getSeconds(), //秒
		"q+": Math.floor((this.getMonth() + 3) / 3), //季度
		"S": this.getMilliseconds() //毫秒
	};
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
	}
	for (var k in o) {
		if (new RegExp("(" + k + ")").test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
		}
	}
	return fmt;
}

Date.prototype.getFirstDayOfYear = function(dateString) {
	var year = new Date(dateString).getFullYear();
	return year + "-01-01 00:00:00";
}

Date.prototype.getFirstDayOfMonth = function(dateString) {
	var month = new Date(dateString).getMonth() + 1;
	month = month > 10 ? month : `0${month}`
	var year = new Date(dateString).getFullYear();
	return `${year}-${month}-01 00:00:00`;
}

Date.prototype.getTimeListFromTwoDate = function(startTime, endTime, dataType) {
	let rtnArr = []
	let firstDate = new Date(startTime)
	let lastDate = new Date(endTime)

	if (dataType == 'DAY') {
		while (true) {
			rtnArr.push(firstDate.format('yyyy-MM-dd'))
			if (firstDate.format('yyyy-MM-dd') == lastDate.format('yyyy-MM-dd')) {
				break
			} else {
				firstDate.setDate(firstDate.getDate() + 1)
			}
		}
	} else if (dataType == 'MONTH') {
		while (true) {
			rtnArr.push(firstDate.format('yyyy-MM'))
			if (firstDate.format('yyyy-MM') == lastDate.format('yyyy-MM')) {
				break
			} else {
				firstDate.setMonth(firstDate.getMonth() + 1)
			}
		}
	} else if (dataType == 'YEAR') {
		while (true) {
			rtnArr.push(firstDate.format('yyyy'))
			if (firstDate.format('yyyy') == lastDate.format('yyyy')) {
				break
			} else {
				firstDate.setFullYear(firstDate.getFullYear() + 1)
			}
		}
	}

	return rtnArr
}
