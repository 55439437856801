<template>
  <Field
      v-model="text"
      is-link
      readonly
      name="carType"
      :label="label"
      :placeholder="placeholder"
      @click="showPicker = true"
  />
  <van-popup v-model:show="showPicker" position="bottom">
    <van-picker
        :columns="data"
        @confirm="onConfirm"
        value-key="text"
        @cancel="cleanText"
    />
  </van-popup>
</template>

<script>
import {defineComponent, ref, watch, unref} from 'vue';
import {  Field } from 'vant';

export default defineComponent({
  name: "FieldPopup",
  components:{
    Field
  },
  model:{
    prop:'value',
    event:'change'
  },
  props: {
    data: {
      type: Array,
      default: []
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    showText:{
      type: String,
      default: ''
    },
    backVal:{
      type: String,
      default: ''
    }
  },
  setup(props,{emit}) {
    const showPicker = ref(false);
    const text = ref()
    const onConfirm = (value) => {
      console.log('value', value)
      showPicker.value = false;
      text.value = value[props.showText];
      emit('change',value[props.backVal])
    };
    const cleanText=()=>{
      showPicker.value = false;
      text.value=null;
    }

    return {showPicker, onConfirm,text,cleanText}
  }
})
</script>

<style scoped>

</style>