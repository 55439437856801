import {ref, onUnmounted, unref, watch, toRaw} from 'vue';

const defaultProps = {
    pageSize: 10
};

export function useListPage(listPageProps) {
    const listRef = ref(null);
    function register(listInstance) {
        listRef.value = listInstance;
        listInstance.setProps({...defaultProps, ...listPageProps});
    }

    function getListInstance() {
        return unref(listRef);
    }

    const methods = {
        resetData: function () {
            getListInstance().resetData();
        },
        queryData: function (params) {
            getListInstance().queryData(params);
        },
        setProps: function (props) {
            getListInstance().setProps(props);
        },
        deleteItem: function (key, value) {
            getListInstance().deleteItem(key, value);
        },
        getAllData:function (){
           return getListInstance().getAllData();
        },
        getItem:function (){
            return getListInstance().getItem();
        },
        replaceItem:function (index,newItem){
            getListInstance().replaceItem(index,newItem)
        },
        addItem:function (newItem){
            getListInstance().addItem(newItem)
        },
        delItem:function (index) {
            getListInstance().delItem(index)
        }
    };

    return [register, methods];
}