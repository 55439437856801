<template>
 <div>
  <van-form @submit="onSubmit">
    <van-cell-group inset>

      <van-field
        label="账号类型"
        is-link
        readonly
        v-model="value1"
        name="transferChannel"
        @click="showPicker = true"
      />
      <div v-if="value1 === '银行卡'">
     
        <van-field
          label="银行名称"
           placeholder="点击选择银行"
          is-link
          readonly
          name="bankName"
          v-model="bankVal"
          @click="showBankPicker"
        />
        <van-field
          v-model="bankData.bankStationName"
          label="支行名称"
          name="subBankName"
          placeholder="请输入支行名称"
          :rules="[{ required: true, message: '请输入支行名称' }]"
        />
        <van-field
          v-model="bankData.bankNum"
          label="银行卡号"
          name="transferNo"
          placeholder="请输入银行卡号"
          :rules="[{ required: true, message: '请输入银行卡号' }]"
        />
        <van-field
          v-model="bankData.bankUserName"
          label="银行卡姓名"
          name="username"
          placeholder="请输入银行卡姓名"
          :rules="[{ required: true, message: '请输入银行姓名' }]"
        />
       </div>
       <div v-else-if="value1 === '支付宝'">
        <van-field
          v-model="aliData.zfbAccount"
          name="zfbAccount"
          label="支付宝账号"
          placeholder="请输入支付宝账号"
          :rules="[{ required: true, message: '请输入支付宝账号' }]"
        />
        <van-field
          v-model="aliData.accountName"
          name="accountName"
          label="账号姓名"
          placeholder="请输入账号姓名"
          :rules="[{ required: true, message: '请输入账号姓名' }]"
        />
       </div>
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="success" native-type="submit" v-if="disableChannel">
        修改
      </van-button>
      <van-button round block type="primary" native-type="submit" v-else>
        提交
      </van-button>
    </div>
  </van-form>
  <!-- 选择账号类型 -->
  <van-popup v-model:show="showPicker" position="bottom">
    <van-picker
      :columns="option1"
      @confirm="onConfirm"
      @cancel="showPicker = false"
    />
  </van-popup>
  <!-- 选择银行 -->
  <van-popup v-model:show="showPickerBank" position="bottom">
    <van-picker
      :columns="bankList"
      @confirm="onConfirmBank"
      @cancel="showPickerBank = false"
    />
  </van-popup>
  </div>
</template>

<script>
import { getBankList,addAttachList,updateAttachList } from "@/api/user";
import { Notify} from "vant";


export default {
  data(){
    return{
      disableChannel: false,
      showPicker: false,
      showPickerBank: false,
      value1: "银行卡",
      option1:['银行卡','支付宝'],
      bankVal:"",
      bankId:0,
      bankList:[],
      bankListData:[],
      bankData:{
        bankStationName:"",
        bankNum:"",
        bankUserName:"",
      },
      aliData:{
        zfbAccount: "",
        accountName: ""
      },
      modifyData:{
        createId:0,
        id:0,
        isDel:false,
        ownerId:0,
        ownerType:0,
        updateId:0
      }
    }
  },
  methods:{
    showBankPicker(){
      getBankList().then(response => {
        if(response.code === 200){
          this.bankListData = response.data;
          let data = response.data;
          let list =  data.map((item) => {
             return item.bankName
          })
          this.bankList = list;
          this.showPickerBank = true;
        }
      });
    },
    onConfirmBank(value){
      this.bankVal = value;
      //过滤银行id
      this.bankListData.map((item) => {
        if(value === item.bankName){
          this.bankId = item.bankId;
        }
      })
      console.log('first',this.bankId)
      this.showPickerBank = false;
    },
    onConfirm(value){
      console.log('confirm', value);
      this.value1 = value;
      this.showPicker = false;
    },
    add(value){
      let data;
      //银行卡
      if(value.transferChannel === '银行卡'){
        data = {
          bankId: this.bankId,
          bankName: this.bankVal,
          subBankName: value.subBankName,
          transferChannel: 1,
          transferNo: value.transferNo,
          username: value.username
        }
      }
      //支付宝
      else{
         data = {
          bankId: 42,
          bankName: "支付宝",
          subBankName: "",
          transferChannel: 3,
          transferNo: value.zfbAccount,
          username: value.accountName
        }
      }

      addAttachList(data).then(response => {
        if(response.code === 200){
          Notify({ type: 'success', message: '新增账户成功' });
          this.$router.back()
         
        }else{
          Notify({ type: 'warning', message: response.msg });
        }
      });
    },
    midify(value){
      console.log('编辑',value)
      let data;
      //银行卡
      if(value.transferChannel === '银行卡'){
        data = {
          bankId: this.bankId,
          bankName: this.bankVal,
          subBankName: value.subBankName,
          transferChannel: 1,
          transferNo: value.transferNo,
          username: value.username
        }
      }
      //支付宝
      else{
         data = {
          bankId: 42,
          bankName: "支付宝",
          subBankName: "",
          transferChannel: 3,
          transferNo: value.zfbAccount,
          username: value.accountName
        }
      }
      let otherData = {
        createId: this.modifyData.createId,
        id: this.modifyData.id,
        isDel: this.modifyData.isDel,
        ownerId: this.modifyData.ownerId,
        ownerType: this.modifyData.ownerType,
        updateId: this.modifyData.updateId
      };
      let postData = Object.assign(data,otherData);

      updateAttachList(postData).then(response => {
        if(response.code === 200){
          Notify({ type: 'success', message: '修改账户成功' });
          this.$router.back()
         
        }else{
          Notify({ type: 'warning', message: response.msg });
        }
      });

    },
    onSubmit(value){
      if(this.disableChannel){
        //编辑
        this.midify(value)
      }else{
        //新增
        this.add(value)
      }
      
    },
    queryRoute(){
      //编辑
      if(this.$route.query.type === 'modify'){
        console.log('firstd',JSON.parse(this.$route.query.data))
        let data = JSON.parse(this.$route.query.data);
        this.disableChannel =true;
        //银行卡
        if(data.transferChannel === 1){
          this.value1 = '银行卡';
          this.bankVal = data.bankName;
          this.bankId = data.bankId;
          this.bankData.bankStationName = data.subBankName;
          this.bankData.bankNum = data.transferNo;
          this.bankData.bankUserName = data.username;
        }
        //支付宝
        else{
          this.value1 = '支付宝';
          this.aliData.zfbAccount = data.transferNo;
          this.aliData.accountName = data.username;
        }
        
        //其他数据
        this.modifyData.createId = data.createId;
        this.modifyData.id = data.id;
        this.modifyData.isDel = data.isDel;
        this.modifyData.ownerId = data.ownerId;
        this.modifyData.ownerType = data.ownerType;
        this.modifyData.updateId = data.updateId;
      }
    }
  },
  mounted(){
    this.queryRoute()
  }

}
</script>

<style lang="scss" scoped>

</style>