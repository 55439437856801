<template>



  <div class='search-wrapper'>
      <div class='search'>
          <Form  class='form'>
              <Field
              v-model="value" 
              placeholder="请输入名称、手机号"
            />
            <Icon class='icon-search' name="search" size="25px" @click="search(value)"/>
          </Form>
      </div>
      <div class='icon-add' @click="add"><van-icon name="plus" /></div>    
  </div>
  <Tabs v-model:active="active" color="#07c160" line-height="1px" @click-tab="onClickTab">
    <Tab :title="carTypeArr[0].text">
        <Property ref="estateRef"/>
    </Tab>
    <Tab :title="carTypeArr[1].text">
        <Partner ref="partnerRef" /> 
    </Tab>
</Tabs>

</template>

<script>
import {ref} from 'vue';
import { Search,Icon,Tab, Tabs,Form, Field } from 'vant';
import {useRouter} from "vue-router";
import Property from './components/property.vue';
import Partner from './components/partner.vue';
export default {
    components:{
       Search,
       Tab,
       Tabs,
       Property, 
       Partner,
       Form,
       Field,
       Icon,
    },
    setup() {
        const value = ref('');
        const router = useRouter();
        const type = ref(1);
        const estateRef = ref();
        const partnerRef = ref();
        const carTypeArr = ref([
        {
            state: 1,
            text: "物业"
        },
        {
            state: 2,
            text: "合伙人"
        },
        ])

        const add = () =>{
            router.push({ path: "/client/add" ,query:{add:"add"}})
        }

        const onClickTab = ({title}) => {    
             value.value=""   
            if(title === carTypeArr.value[1].text){
                type.value = carTypeArr.value[1].state
                partnerRef.value.queryList({  
                      pageNum : 1,
                    pageSize: 10         
                })   
                partnerRef.value.cleanStatus()     
            } else{            
                type.value = carTypeArr.value[0].state
                    
                    estateRef.value.queryList({
                        pageNum : 1,
                        pageSize: 10
                    })
                 estateRef.value.cleanStatus()

            }  
            
        } 

        const search = (value) => {

            const params = {}
            const regs=/^1[3|4|5|6|7|8|9][0-9]{9}$/
            if(regs.test(value)){
                // 手机号搜索
                // console.log("匹配手机号成功",estateRef.value)
                    params.phone = value                
            }else{
                // 名称搜索
                params.username = value
            }
            if(type.value === 1){
                estateRef.value.queryList(params)
            }else{
                partnerRef.value.queryList(params)

            }

        }

        return { 
            value,
            carTypeArr,
            estateRef,
            partnerRef,
            search,
            add,
            onClickTab,
         };
  },
    

}
</script>

<style scoped>
.container {
  background-color: #F5f5f5;
}
.search-wrapper{
    display: flex;
    flex-direction: row;
    height:40px;
    align-items: center;
    margin:10px;
  background-color: #fff;
}

.search{
 flex:88%;
 border:0.5px solid gray;
 border-radius: 30px;
 overflow: hidden;
 padding: 0;
 margin-right: 10px;
}
.form {
    display: flex;
}
.icon-search {
    padding:10px 10px;
    size: large;
}
.icon-add {
    width:12%;
    border:0.5px solid rgb(216, 212, 212);
    border-radius:10px;
    text-align: center;
    height: 100%;
    line-height: 40px;
    font-size: 16px;

}

</style>