<template>
  <div class="tabBox">
  <van-tabs v-model:active="activeType"  @click="onClickTab(activeType)">
    <van-tab :title="item.name" :key="index" v-for="(item,index) in typeList"></van-tab>
  </van-tabs>
  </div>
  <div class="container">
    <div class="statistic">
      <div class="statistic-text">总设备数：{{ allCount.totalDeviceCount }}</div>
      <div class="statistic-text">运营中：{{ allCount.operatingDeviceCount }}</div>
      <div class="statistic-text"> 在线：{{ allCount.onlineDeviceCount }}</div>
      <div class="statistic-text"> 离线：{{ allCount.offlineDeviceCount }}</div>
      <div class="statistic-text"> 待投入运营：{{ allCount.pendingOperatingDeviceCount }}</div>
    </div>
    <div style="margin-top: 5px;" class="catelist">
      <div class="left-nav">
         <Sidebar v-model="active" @change="onChange">
          <SidebarItem  title="所有设备" @click="onClick(null)"/>
          <SidebarItem  title="未分配" @click="onClick(1)"/>
          <SidebarItem v-for="item in stationList" :title="item.apartmentName" @click="onClick(item)"/>
        </Sidebar>
      </div>
      <div class="cate-body">
      <ListPage @register="register">
        <template #item="{dataList}">
          <div :key="index" v-for="(item,index) in dataList">
              <div class="item-content" @click="toDetail(item)">
                <div class="item-content-s">
                  <div>
                    <div class="item-content-title">
                      {{ item.deviceName }}
                    </div>
                    <div class="item-content-address">
                      {{ item.deviceSn }}
                    </div>
                    <div class="item-content-address">
                      {{item.deviceTypeName}}
                    </div>
                    <div class="item-content-address">
                      {{ item.createTime }}
                    </div>
                  </div>
                  <div>
                    <Tag v-if="item.deviceStatus==0" plain type="primary" size="large">待激活</Tag>
                    <Tag v-if="item.deviceStatus == 1" plain type="default" size="large">离线</Tag>
                    <Tag v-if="item.deviceStatus == 2" plain type="success" size="large">在线</Tag>
                    <Tag></Tag>
                    <Tag v-if="item.isPub == true" plain type="success" size="large">运营</Tag>
                    <Tag v-if="item.isPub == false" plain type="warning" size="large">维护</Tag>
                  </div>
                </div>
                <div class="item-content-statistic">
                  <div class="item-content-item">
                    <div class="item-title-statistic">充电中</div>
                    <div class="item-content-c">{{ item.busySocketCount }}</div>
                  </div>
                  <div class="item-content-item">
                    <div class="item-title-statistic">空闲</div>
                    <div class="item-content-c">{{ item.socketCount - item.busySocketCount - item.expSocketCount - item.closeSocketCount }}</div>
                  </div>
                  <div class="item-content-item">
                    <div class="item-title-statistic">故障</div>
                    <div class="item-content-c">{{ item.expSocketCount }}</div>
                  </div>
                  <div class="item-content-item">
                    <div class="item-title-statistic">端口关闭</div>
                    <div class="item-content-c">{{ item.closeSocketCount }}</div>
                  </div>
                </div>
              </div>
            <div style="height: 8px"></div>
          </div>
        </template>
      </ListPage>
      </div>

    </div>
    <!-- 筛选状态 -->
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="deviceStatusType"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <div class="add" @click="showPicker = true" v-if="userType">
      <van-icon name="search" color="#fff" size="40" style="margin: 8px 0 0 8px;"/>
    </div>
  </div>
</template>

<script>
import {ListPage, useListPage} from "@/components/ListPage";

import {useRoute, useRouter} from "vue-router";
import {onMounted, ref} from 'vue';
import {Search, Divider, SwipeCell, Tag, Sidebar, SidebarItem, Popup, CellGroup} from 'vant';
import {aggregation, getAgentInfo, listStation} from "@/api/device";
import FieldPopup from "@/components/FieldPopup";
import {aggregateStation} from "@/api/stat";

export default {
  components: {
    FieldPopup,
    CellGroup,
    Popup,
    Search,
    SwipeCell,
    ListPage,
    Tag,
    Divider,
    Sidebar,
    SidebarItem
  },
  emits: ['search'],
  setup({emit}) {
    const typeList = ref([
      {id: 1, name: '两轮充电桩', title: [{name: '两轮充电桩'}]},
      {id: 2, name: '汽车充电桩', title: [{name: '汽车充电桩'}]},
    ]);
    const activeType = ref(0)
    const show = ref(false)
    const showPicker = ref(false);
    const router = useRouter();
    const stationList = ref([]);
    const active = ref(0);
    const allCount = ref({});
    const apiUrl = '/device/page';
    const apartmentId = ref(null);
    const deviceStatusTypeArr = [
      {
        state: 0,
        text: "待激活"
      },
      {
        state: 1,
        text: "离线"
      },
      {
        state: 2,
        text: "在线"
      }
    ];
    const deviceStatusType = ["待激活","离线","在线"];
    const deviceStatus = ref();
    const userType = ref();
    const route = useRoute();
    const [register, {setProps, queryData, resetData, deleteItem}] = useListPage({
      apiUrl: apiUrl,
      requestParam: {}
    });
    onMounted(() => {
      let deviceStatus =route.query.deviceStatus
      console.log(deviceStatus)
      let pageSize = 10;
      if (deviceStatus==1){
        setProps({apiUrl, pageSize,requestParam: {deviceStatus: deviceStatus,deviceTypeSeparate:0}});
        resetData();
        aggregation({deviceStatus: deviceStatus,deviceTypeSeparate:0}).then(res => {
          allCount.value = res.data;
        })
      }else {
        setProps({apiUrl, pageSize,requestParam: {deviceStatus: deviceStatus,deviceTypeSeparate:0}});
        resetData();
        aggregation({deviceTypeSeparate:0}).then(res => {
          allCount.value = res.data;
        })
      }
      listStation({pageNum:1,pageSize:10000,stationKindType:0}).then(result =>{
        stationList.value = result.rows;
      })
      getAgentInfo().then(res => {
        if (res.data.businessUserType == 'ESTATE') {
          userType.value = false;
        } else if (res.data.businessUserType == 'PARTNER') {
          userType.value = false;
        } else {
          userType.value = true;
        }
      })
    });
    const onClickTab = (activeType) => {
      let pageSize = 10;
      if (activeType === 0) {
        setProps({apiUrl,pageSize,requestParam: {deviceTypeSeparate:activeType,deviceStatus:route.query.deviceStatus}})
        resetData()
        listStation({pageNum:1,pageSize:10000,stationKindType:activeType}).then(result =>{
          stationList.value = result.rows;
        })
        aggregation({deviceTypeSeparate:activeType}).then(res => {
          allCount.value = res.data;
        })
      } else if (activeType === 1) {
        setProps({apiUrl,pageSize,requestParam: {deviceTypeSeparate:activeType,deviceStatus:route.query.deviceStatus}})
        resetData()
        listStation({pageNum:1,pageSize:10000,stationKindType:activeType}).then(result =>{
          stationList.value = result.rows;
        })
        aggregation({deviceTypeSeparate:activeType}).then(res => {
          allCount.value = res.data;
        })
      }
    }
    const onSubmit = () => {
    }
    const onConfirm = (value) =>{
      //过滤state
      deviceStatusTypeArr.map((item) => {
        if(value === item.text){
          deviceStatus.value = item.state;
        }
      })
      console.log('first',deviceStatus.value)
      // TODO 查询
      let pageSize = 10;
      setProps({apiUrl, pageSize,requestParam: {deviceStatus: deviceStatus.value,deviceTypeSeparate:activeType}});
      resetData();
      aggregation({deviceStatus: deviceStatus.value,deviceTypeSeparate:activeType.value}).then(res => {
        allCount.value = res.data;
      })
      showPicker.value = false;
    }
    const toSearch = () => {

    }
    const onSearch = (val) => {
      emit('search', val)
    };

    const onInputChange = (val) => {
      console.log('val', val)
      let pageSize = 10;
      setProps({apiUrl, pageSize, requestParam: {deviceSn: val}});
      resetData();
    }

    const onChange = () =>{
    }
    const onClick = (item) =>{
      const pageSize = 10;
      console.log(activeType.value,'ac')
      if (route.query.deviceStatus==1){
        if (!item) {
          setProps({apiUrl, pageSize,requestParam: {deviceStatus: 1,deviceTypeSeparate:activeType.value}});
          resetData();
          aggregation({deviceTypeSeparate:activeType.value}).then(res => {
            allCount.value = res.data;
          })
        }else if (item == 1) {
          setProps({apiUrl, pageSize,requestParam: {apartmentId:0,deviceStatus: 1,deviceTypeSeparate:activeType.value}});
          resetData();
          aggregation({apartmentId: 0,deviceStatus: 1,deviceTypeSeparate:activeType.value}).then(res => {
            allCount.value = res.data;
          })
        } else {
          const apartmentId = item.id;
          setProps({apiUrl, pageSize,requestParam: {apartmentId: apartmentId,deviceStatus: 1,deviceTypeSeparate:activeType.value}});
          resetData();
          aggregation({apartmentId: apartmentId,deviceStatus: 1,deviceTypeSeparate:activeType.value}).then(res => {
            allCount.value = res.data;
          })
        }
      }else {
        if (!item) {
          setProps({apiUrl, pageSize,requestParam: {deviceTypeSeparate:activeType.value}});
          resetData();
          aggregation({deviceTypeSeparate:activeType.value}).then(res => {
            allCount.value = res.data;
          })
        }else if (item == 1) {
          setProps({apiUrl, pageSize,requestParam: {apartmentId:0,deviceStatus: deviceStatus.value,deviceTypeSeparate:activeType.value}});
          resetData();
          aggregation({apartmentId: 0,deviceStatus: deviceStatus.value,deviceTypeSeparate:activeType.value}).then(res => {
            allCount.value = res.data;
          })
        } else {
            const apartmentId = item.id;
            setProps({apiUrl, pageSize,requestParam: {apartmentId: apartmentId,deviceStatus: deviceStatus.value,deviceTypeSeparate:activeType.value}});
            resetData();
            aggregation({apartmentId: apartmentId,deviceStatus: deviceStatus.value,deviceTypeSeparate:activeType.value}).then(res => {
              allCount.value = res.data;
            })
        }
      }
    }

    const searchClick = () => {
    }

    const toDetail = (item) => {
      router.push({path: '/device/detail',query:{item:JSON.stringify(item)}})
    }
    return {onClickTab,activeType,typeList,register, searchClick, toDetail, active,stationList,onChange,onClick,allCount,onSearch,userType,
      onInputChange,show,onSubmit,deviceStatusTypeArr,deviceStatus,toSearch,deviceStatusType,showPicker,onConfirm};
  },
}
</script>

<style lang="scss"  scoped>
.container {
  background-color: #F5f5f5;
  .catelist{
    display: flex;
    justify-content: space-between;
    .left-nav{
      width: 15%;
      height: calc(100vh - 100px);
      overflow: scroll;
      .van-sidebar{
        width: 100%;
        .van-sidebar-item{
          writing-mode: vertical-rl;
        }
      }
      
    }
    .cate-body{
      width: 100%;
      height: calc(100vh - 100px);
      overflow: scroll;
    }
  }
}

.item-content {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 12px 12px;
}

.item-content-s {
  display: flex;
  flex-direction: row;
  justify-content: space-between
}

.item-content-title {
  font-size: 16px;
  color: #333333;
  font-weight: 500;
      width: 170px;
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.item-content-address {
  font-size: 13px;
  color: #666666;
  margin-top: 4px;
}

.item-content-statistic {
  height: 64px;
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #F5f5f5;
  border-radius: 4px;
  flex: 0;
  order: 1;
  flex-grow: 0;
  margin-top: 8px;
}

.item-content-item {
  height: 40px;
  padding:  0 10px;
  display: flex;
  flex-direction: column;
  align-items: center
}

.item-title-statistic {
  font-size: 14px;
  color: #666666;
}

.item-content-c {
  font-size: 16px;
  color: #333333;
  margin: 2px 16px 0 16px;
}

.item-btn {
  display: flex;
  flex-direction: column;
  height: 100%
}

.statistic {
  display: flex;
  flex-direction: row;
  background-color: #ecf9ff;
  padding: 8px
}

.statistic-text {
  font-size: 14px;
  color: #333333;
  margin-right: 5px;
}
.add{
    position: fixed;
    right: 24px;
    bottom: 68px;
    width: 56px;
    height: 56px;
    background-color: #00d655;
    border-radius: 28px;
  }
</style>