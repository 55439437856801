<template>
  <van-form @submit="onSubmit" error-message-align="right">
    <van-cell-group inset>
      <van-field
          name="tplName"
          label="模式名称"
          placeholder="请输入模式名称"
          input-align="right"
          v-model="tplName"
          :rules="[{ required: true, message: '请填写模式名称' }]"
      />
      <van-field
          v-model="modeType"
          is-link
          readonly
          input-align="right"
          name="modeType"
          label="模式类型"
          placeholder="点击选择模式类型"
          @click="showPicker = true"
          :rules="[{ required: true, message: '请选择模式类型' }]"
      />
      <van-field v-if="modeType=='功率模式'||modeType=='电量模式'"
                 show-text="text" back-val="state"
                 v-model="powerTime"
                 :data="PowerTimeArr"
                 is-link
                 readonly
                 input-align="right"
                 name="PowerTime"
                 label="限制功率时长"
                 placeholder="点击选择限制功率时长"
                 @click="showPowerTime = true"
                 :rules="[{ required: true, message: '请选择限制功率时长' }]"
      />

      <van-field v-if="modeType=='功率模式'||modeType=='电量模式'"
                 show-text="text" back-val="state"
                 v-model="powerAmount"
                 :data="PowerAmountArr"
                 is-link
                 readonly
                 input-align="right"
                 name="PowerAmount"
                 label="功率限制金额"
                 placeholder="点击选择限制功率金额"
                 @click="showPowerAmount = true"
                 :rules="[{ required: true, message: '请选择限制功率金额' }]"
      />
      <van-field
          name="thresholdAmount"
          v-model="thresholdAmount"
          label="门槛金额"
          placeholder="请输入门槛金额"
          input-align="right"
          :rules="[{ required: true, message: '请填写门槛金额' },{pattern:/^(0\.?\d{0,2}|[1-9]\d*\.?\d{0,2})$/,message:'请输入正数'}]"
      />

      <van-field
          name="isFullStop"
          label="是否充满自停"
          input-align="right"
      >

        <template #input>
          <van-radio-group v-model="isFullStop" direction="horizontal">
            <van-radio :name="true">是</van-radio>
            <van-radio :name="false">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
          name="isOpenLow"
          label="是否最低消费"
          input-align="right"
          :rules="[{ required: true, message: '请填写最低消费金额' },{pattern:/^(0\.?\d{0,2}|[1-9]\d*\.?\d{0,2})$/,message:'请输入正数'}]"
      >
        <template #input>
          <van-radio-group v-model="isOpenLow" direction="horizontal">
            <van-radio :name="0">关闭</van-radio>
            <van-radio :name="1">开启</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field v-if="isOpenLow==1"
                 v-model="minSpendTime"
                 input-align="right"
                 name="minSpendTime"
                 label="最低进入时间"
                 placeholder="请输入进入时间"
                 :rules="[{ required: true, message: '请填写最低进入时间' },{pattern:/^(0\.?\d{0,2}|[1-9]\d*\.?\d{0,2})$/,message:'请输入正数'}]"
      />

      <van-field v-if="isOpenLow==1"
                 v-model="minSpendAmount"
                 input-align="right"
                 name="minSpendAmount"
                 label="最低消费金额"
                 placeholder="请输入消费金额"
                 :rules="[{ required: true, message: '请填写最低消费金额' },{pattern:/^(0\.?\d{0,2}|[1-9]\d*\.?\d{0,2})$/,message:'请输入正数'}]"
      />


      <van-field
          v-model="note"
          name="note"
          label="备注"
          placeholder="请填写备注"
          input-align="right"
      />


    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit" @submit="onSubmit" v-model:show="showSubmit">
        确定
      </van-button>
      <van-button round block type="default" style="margin-top: 12px" @click="cancel">
        取消
      </van-button>
    </div>

    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
          :columns="columns"
          @confirm="onConfirmPicker"
          @cancel="showPicker = false"
      />
    </van-popup>
    <Overlay :show="showPowerTime || showPowerAmount" :lock-scroll="false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="top">
            <div class="tit">功率限制{{showPowerTime?'时长':'金额'}}</div>
            <div class="icon">
              <Icon name="cross" size="24" @click="closeOverlay" />
            </div>
          </div>
          <van-checkbox-group v-model="powerTimechecked" v-if="showPowerTime">
            <van-cell-group inset>
              <van-cell
                v-for="(item, index) in powerTimeArr"
                clickable
                :key="item.state"
                :title="item.text"
                @click="toggle(index)"
              >
                <template #right-icon>
                  <van-checkbox
                    :name="item.state"
                    :ref="(el) => (checkboxRefs[index] = el)"
                    @click.stop
                  />
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>
          <van-checkbox-group v-model="powerAmountchecked" v-else>
            <van-cell-group inset>
              <van-cell
                v-for="(item, index) in powerAmountArr"
                clickable
                :key="item.state"
                :title="item.text"
                @click="toggle(index)"
              >
                <template #right-icon>
                  <van-checkbox
                    :name="item.state"
                    :ref="(el) => (checkboxRefs[index] = el)"
                    @click.stop
                  />
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>
          <div class="btn-box">
            <Button style="width: 200px;" type="success" @click="onConfirmTime">确认</Button>
          </div>
        </div>
      </div>
    </Overlay>


    <!-- <van-popup v-model:show="showPowerTime" position="bottom">
      <van-picker
          :columns="powerTimeArr"
          @confirm="onConfirmTime"
          @cancel="showPowerTime = false"
      />
    </van-popup> -->

    <!-- <van-popup v-model:show="showPowerAmount" position="bottom">
      <van-picker
          :columns="powerAmountArr"
          @confirm="onConfirmAmount"
          @cancel="showPowerAmount = false"
      />
    </van-popup> -->

  </van-form>
</template>

<script>
import {onMounted, ref,onBeforeUpdate} from 'vue';
import {addTemplate, editTemplate, getTemplateType, queryTemplate} from "@/api/programmeAdd";
import {Notify, Switch,Overlay,
  Checkbox,
  CheckboxGroup,
  CellGroup,
  Cell,
  Icon,
  Button,} from 'vant';
import {useRoute, useRouter} from "vue-router";

export default {
  components: {
    Switch,
    Overlay,
    Checkbox,
    CheckboxGroup,
    CellGroup,
    Cell,
    Icon,
  Button,
  },
  setup() {

    onMounted(() => {
      queryTemplate(id.value).then(res => {
            let item = res.data;
            tplName.value = item.tplName;
            if (item.modeType == 1) {
              modeType.value = '功率模式';
            } else if (item.modeType == 2) {
              modeType.value = '时间模式';
            } else if (item.modeType == 3) {
              modeType.value = '电量模式';
            }
            timeList.value = item.timeList;
            amountList.value = item.amountList;
            powerTimechecked.value = item.timeList;
            powerAmountchecked.value = item.amountList;
            console.log(timeList.value)
            console.log(amountList.value)
            let a = ''
            for (let i = 0; i < item.timeList.length; i++) {
               powerTimeArr.map((it) => {
                  if (item.timeList[i] === it.state) {
                    a = a + it.text + ",";
                  }
              });
            }
            powerTime.value = a.substring(a, a.length - 1);
            let b = '';
            for (let i = 0; i < item.amountList.length; i++) {
              powerAmountArr.map((it) => {
                if(item.amountList[i] === it.state){
                  b = b + it.text + ",";
                }
              })
            }
            powerAmount.value = b.substring(b,b.length - 1);
            thresholdAmount.value = item.thresholdAmount / 100;
            isFullStop.value = item.isFullStop;
            isOpenLow.value = item.isOpenLow;
            minSpendTime.value = item.minSpendTime;
            minSpendAmount.value = item.minSpendAmount / 100;
            note.value = item.note;
            isDel.value = item.isDel;
            ownerId.value = item.ownerId;
            ownerType.value = item.ownerType;
            updateId.value = item.updateId;
            createId.value = item.createId;
            refundType.value = item.refundType;
            updateTime.value = item.updateTime;
          }
      )
    })

    const isDel = ref();
    const ownerId = ref();
    const ownerType = ref();
    const updateId = ref();
    const createId = ref();
    const refundType = ref();
    const updateTime = ref();

    const router = useRouter()
    const route = useRoute()
    const id = ref(JSON.parse(route.query.id))
    const tplName = ref('')
    const note = ref('')
    const modeType = ref('')
    const powerTime = ref('')
    const powerAmount = ref('')
    const thresholdAmount = ref('')
    const minSpendAmount = ref('')
    const minSpendTime = ref('');
    const showPicker = ref(false)
    const showPowerTime = ref(false)
    const showPowerAmount = ref(false)
    const showSubmit = ref(false)
    const isFullStop = ref(true);
    const isOpenLow = ref(0);
    const columns = [];
    const checkboxRefs = ref([]);
    const powerTimechecked = ref([]);
    const powerAmountchecked = ref([]);
    const toggle = (index) => {
      checkboxRefs.value[index].toggle();
    };
    onBeforeUpdate(() => {
      checkboxRefs.value = [];
    });
    const powerTimeArr = [
      {
        state: 60,
        text: "1小时"
      },
      {
        state: 2 * 60,
        text: "2小时"
      },
      {
        state: 3 * 60,
        text: "3小时"
      }, {
        state: 4 * 60,
        text: "4小时"
      }, {
        state: 5 * 60,
        text: "5小时"
      }, {
        state: 6 * 60,
        text: "6小时"
      }, {
        state: 7 * 60,
        text: "7小时"
      }, {
        state: 8 * 60,
        text: "8小时"
      }, {
        state: 9 * 60,
        text: "9小时"
      }, {
        state: 10 * 60,
        text: "10小时"
      }, {
        state: 11 * 60,
        text: "11小时"
      }, {
        state: 12 * 60,
        text: "12小时"
      }
    ];
    const powerAmountArr = [
      {
        state: 100,
        text: "1元",
      },
      {
        state: 2 * 100,
        text: "2元",
      },
      {
        state: 3 * 100,
        text: "3元",
      },
      {
        state: 4 * 100,
        text: "4元",
      },
      {
        state: 5 * 100,
        text: "5元",
      },
      {
        state: 6 * 100,
        text: "6元",
      },
      {
        state: 7 * 100,
        text: "7元",
      },
      {
        state: 8 * 100,
        text: "8元",
      },
      {
        state: 9 * 100,
        text: "9元",
      },
      {
        state: 10 * 100,
        text: "10元",
      },
      {
        state: 15 * 100,
        text: "15元",
      },
      {
        state: 20 * 100,
        text: "20元",
      },
      {
        state: 25 * 100,
        text: "25元",
      },
      {
        state: 30 * 100,
        text: "30元",
      },
      {
        state: 35 * 100,
        text: "35元",
      },
      {
        state: 40 * 100,
        text: "40元",
      },
      {
        state: 45 * 100,
        text: "45元",
      },
      {
        state: 50 * 100,
        text: "50元",
      },
    ];
    const onConfirmPicker = (value) => {
      console.log(value)
      modeType.value = value;
      showPicker.value = false;
    }
    const onConfirmTime = (value) => {
      if(showPowerTime.value){
        let isNull = JSON.parse(JSON.stringify(powerTimechecked.value));
        if(isNull.length === 0){
          powerTime.value = "";
          timeList.value = [];
          powerTimechecked.value = [];
          Notify({ type: "warning", message: "请选择功率限制时长" });
          return;
        }
        timeList.value = JSON.parse(JSON.stringify(powerTimechecked.value))
        let timeListArr = timeList.value;
        if(timeListArr.length === 0){
          Notify({ type: "warning", message: "请选择功率限制时长" });
          return;
        }
        let a = "";
        timeListArr.map((item) => {
          powerTimeArr.map((it) => {
            if (item === it.state) {
              a = a + it.text + ",";
            }
          });
        });
        powerTime.value = a.substring(a, a.length - 1);
        console.log("it", powerTime.value);
        showPowerTime.value = false;
      }else{
        let isNull = JSON.parse(JSON.stringify(powerAmountchecked.value));
        if(isNull.length === 0){
          powerAmount.value = "";
          amountList.value = [];
          powerAmountchecked.value = [];
          Notify({ type: "warning", message: "请选择功率限制金额" });
          return;
        }
        amountList.value = JSON.parse(JSON.stringify(powerAmountchecked.value))
        let amountListArr = amountList.value;
        if(amountListArr.length === 0){
          Notify({ type: "warning", message: "请选择功率限制金额" });
          return;
        }
        let a = "";
        amountListArr.map((item) => {
          powerAmountArr.map((it) => {
            if (item === it.state) {
              a = a + it.text + ",";
            }
          });
        });
        powerAmount.value = a.substring(a, a.length - 1);
        console.log("itbottom", powerAmount.value);
        showPowerAmount.value = false;
      }
    }
    const onConfirmAmount = (value) => {
      console.log(value)
      powerAmount.value = value.text
      amountList.push(value.state)
      showPowerAmount.value = false;
    }
    getTemplateType().then(res => {
      console.log(res.data)
      for (let i = 0; i < res.data.length; i++) {
        console.log(res.data[i].dictLabel)
        columns.push(res.data[i].dictLabel)
      }
    })
    const closeOverlay = () => {
      showPowerTime.value ? showPowerTime.value = false : showPowerAmount.value = false;
    }
    const amountList = [];
    const timeList = [];
    const onSubmit = (values) => {
      values.minSpendAmount = values.minSpendAmount * 100;
      values.thresholdAmount = values.thresholdAmount * 100
      values.amountList = amountList.value
      values.timeList = timeList.value
      if (values.modeType == '功率模式') {
        values.modeType = 1
      } else if (values.modeType == '时间模式') {
        values.modeType = 2
      } else if (values.modeType == '电量模式') {
        values.modeType = 3
      }
      values.id = id.value;
      values.updateId = updateId.value;
      values.createId = createId.value;
      values.ownerId = ownerId.value;
      values.ownerType = ownerType.value;
      values.isDel = isDel.value;
      values.updateTime = updateTime.value;
      values.refundType = refundType.value;
      editTemplate(values).then(result => {
        console.log(result)
        amountList.length = 0;
        timeList.length = 0;
        if (result.code == 200) {
          router.push({path: `/priceTemplate`});
          // 成功通知
          Notify({type: 'success', message: '修改模板成功'});
        } else {
          Notify({type: 'danger', message: '修改模板失败'});
        }
      })
    }
    const cancel = () => {
      router.push({path: '/priceTemplate'})
    };
    return {
      amountList,
      timeList,
      modeType,
      note,
      tplName,
      powerAmount,
      powerTime,
      minSpendTime,
      thresholdAmount,
      minSpendAmount,
      powerTimeArr,
      powerAmountArr,
      showPicker,
      showPowerTime,
      showPowerAmount,
      showSubmit,
      isFullStop,
      isOpenLow,
      columns,
      cancel,
      onSubmit,
      onConfirmPicker,
      onConfirmTime,
      onConfirmAmount,
      checkboxRefs,
      toggle,
      closeOverlay,
      powerTimechecked,
      powerAmountchecked
    };
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .block {
    width: 360px;
    height: 500px;
    background-color: #fff;
    border-radius: 5px;
    overflow-y: auto;
    position: relative;
    .top {
      position: sticky;
      top: 0;
      width: 100%;
      height: 32px;
      display: flex;
      justify-content: space-between;
      background: #eee;
      z-index: 100;

      .tit {
        padding-left: 12px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-size: 16px;
      }
      .icon {
        padding: 4px 12px 0 0;
      }
    }

    .btn-box {
      z-index: 100;
      background-color: #fff;
      position: sticky;
      bottom: 0;
      display: flex;
      justify-content: center;
      padding: 12px 0;
      
    }
  }
}
.tag-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .tag-item {
    margin-right: 1px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 20px;
    font-size: 12px;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .label {
    margin-top: -10px;
    margin-right: 10px;
    font-size: var(--van-cell-font-size);
    color: var(--van-field-label-color);
  }

  .tag-item:nth-child(4n) {
    //margin-right: 0;
  }
}
</style>