import wx from 'weixin-js-sdk'
import {loadData} from "@/api/common";
import {onMounted} from 'vue'

export function useWeiXinHook(props) {

    onMounted(() => {
        weiXinInit()
    })

    const weiXinInit = async () => {
        if (!props.apiUrl) {
            console.error("微信sdk接口api未配置");
            return;
        }
        const config = await loadData(props.apiUrl, {url: window.location.href});
        wx.config({
            // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            debug: false,
            // 必填，公众号的唯一标识
            appId: config.appId,
            // 必填，生成签名的时间戳
            timestamp: config.timestamp,
            // 必填，生成签名的随机串
            nonceStr: config.nonceStr,
            // 必填，签名，见附录1
            signature: config.signature,
            // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            jsApiList: ["scanQRCode", "getLocation", "openLocation"]
        });
    }


    const scanQRCode = async () => {
        return new Promise((resolve, reject) => {
            wx.ready(() => {
                wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success: res => {
                        console.log("扫描成功", res.resultStr);
                        resolve(res.resultStr)// 当needResult 为 1 时，扫码返回的结果
                    }
                });
            })
        })
    }

    const getLocation = async () => {
        return new Promise((resolve, reject) => {
            wx.ready(() => {
                wx.getLocation({
                    type: 'wgs84', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
                    success: function (res) {
                        // var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                        // var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                        // var speed = res.speed; // 速度，以米/每秒计
                        // var accuracy = res.accuracy; // 位置精度
                        resolve(res)
                    }
                });
            })
        })
    }

    const openLocation = (lat, lng) => {
        wx.ready(() => {
            wx.openLocation({
                latitude: lat || 0, // 纬度，浮点数，范围为90 ~ -90
                longitude: lng || 0, // 经度，浮点数，范围为180 ~ -180。
                name: '', // 位置名
                address: '', // 地址详情说明
                scale: 1, // 地图缩放级别,整型值,范围从1~28。默认为最大
                infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
            });
        })
    }

    return {getLocation, openLocation, scanQRCode}
}