<template>
  <div class="container">
     <div class="top-item">
      <div class="item-list">
        <div class="top">功能管理</div>
        <Grid :column-num="4" :border="false">
          <GridItem icon="cluster" icon-color="#fff" text="充电站" to="/rechargeStation"/>
          <GridItem icon="graphic" icon-color="#fff" text="设备" to="/device/list"/>
<!--          <GridItem icon="after-sale" icon-color="#fff" text="价格" to="/priceTemplate" v-if="userType"/>-->
          <GridItem icon="balance-list" icon-color="#fff" text="财务" to="/withdrawal"/>
          <GridItem icon="bars" icon-color="#fff" text="订单" to="/order/list"/>
          <GridItem icon="friends-o" icon-color="#fff" text="客户" to="/client" v-if="userType"/>
          <GridItem icon="manager" icon-color="#fff" text="用户" to="/consumer" v-if="userType"/>
        </Grid>
      </div>
    </div>
    <div class="top-item" v-if="userType">
      <div class="item-list">
        <div class="top">快捷操作</div>
        <Grid :column-num="4" :border="false">
          <GridItem icon="shield-o" icon-color="#fff" text="设备绑定" to="/deviceBind" />
          <GridItem icon="exchange" icon-color="#fff" text="设备替换" to="/deviceReplace"/>
          <GridItem icon="map-marked"  icon-color="#fff" text="更新定位" to="/UpdateLocation" v-if="userType"/>
        </Grid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAgentInfo
} from "@/api/stat";
import {
  Grid,
  GridItem
} from "vant";
import { ref } from "vue";
import { onMounted } from "@vue/runtime-core";

export default {
  components: {
    Grid,
    GridItem
  },
  setup() {
    const userType = ref();
    onMounted(() => {
       getAgentInfo().then((res) => {
        if (res.data.businessUserType == "ESTATE") {
          userType.value = false;
        } else if (res.data.businessUserType == "PARTNER") {
          userType.value = false;
        } else {
          userType.value = true;
        }
      });
    })
    return {
      userType
    };
  }

}
</script>

<style lang="scss" scoped>
::v-deep .van-grid-item__icon {
  background-color: rgb(0, 214, 85)!important;
  padding: 8px!important;
  border-radius: 50%!important;
}
.container {
  height: 100vh;
  background-color: #ffffff;
  .top-item {
    background-color: #ffffff;
    padding: 10px 10px;
    .item-list {
      // border: 1px solid rgb(0, 214, 85);
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding: 1px;
      .top {
        color: #ffffff;
        width: 60px;
        font-size: 12px;
        line-height: 24px;
        padding: 0 12px;
        text-align: center;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 5px;

        background-color: rgb(0, 214, 85);
      }
    }
  }
}

</style>