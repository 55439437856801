<template>
  <div class="container">
    <Form>
      <div style="text-align: center">录入旧设备序列号</div>
      <Field
          v-model="oldDeviceSn"
          name="oldDeviceSn"
          class="input-s"
          placeholder="请输入或扫描旧设备序列号"
      >
        <template #button>
          <van-button size="small" icon="scan" style="border-width: 0" @click="onScanQrCode(1)"></van-button>
        </template>
      </Field>
      <div style="text-align: center;margin-top: 50px">录入新设备序列号</div>
      <Field
          v-model="newDeviceSn"
          name="newDeviceSn"
          class="input-s"
          placeholder="请输入或扫描新设备序列号"
      >
        <template #button>
          <van-button size="small" icon="scan" style="border-width: 0" @click="onScanQrCode(2)"></van-button>
        </template>
      </Field>
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
        该操作仅支持替换设备编号,如需更换设备类型与设备端口请用电脑端操作 !
      </van-notice-bar>
    </Form>
    <div style="position: fixed;bottom: 0;width: 100%;height: 48px">
      <van-button color="#00d655" block @click="onDeviceReplace">确认替换</van-button>
    </div>
  </div>
</template>

<script>
import {Form, Field, Notify,} from 'vant';
import {useWeiXinHook} from "@/hooks/useWeiXinHooks";
import {devicePage, deviceReplace, deviceType} from "@/api/device";
import {onMounted, ref,getCurrentInstance} from "vue";
import { wxConfig } from "@/utils/wx.js";

export default {
  components: {
    Form, Field,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const {scanQRCode} = useWeiXinHook({apiUrl: ''});
    const newDeviceSn = ref('');
    const oldDeviceSn = ref('');
    const data = {
      deviceKindType: '',//设备类型
      deviceSn: '',//新设备编号
      id: '',//设备id
      kindType: '',//端口类型
      socketCount: '',//端口数
      socketPower: '',//每个端口功率
    };
    const onScanQrCode =  (type) => {
      if (type === 1) {
        console.log('1111')
        getCodeVal(1)
      }
      if (type === 2) {
        console.log('2222')
        getCodeVal(2)
      }
    }
    const getCodeVal = (type) => {
      wxConfig(proxy.$wx, () => {
        proxy.$wx.scanQRCode({
          needResult: 1,
          scanType: ["qrCode"],
          success: function (res) {
            const url = res.resultStr;
            //'http://charging.test.anchong001.com/web/wx?'
            let b = url.split("?");
            //['http://charging.test.anchong001.com/web/wx','sn=521200829145848$$outletNum=1']
            let c = b[1].split("="); //['sn','521200829145848$$outletNum','1']
            let d = c[1].split("$$"); //['521200829145848','outletNum']
            if(type === 1){
              oldDeviceSn.value = d[0];
            }else if(type === 2){
              newDeviceSn.value = d[0];
            }
          },
        });
      });

    }
    const id = ref();
    const deviceTypeListValue = ref();
    const socketPower = ref();
    const kindType = ref();
    const deviceKindType = ref();
    const socketCount = ref();
    const typeId = ref();

    const onDeviceReplace = async () => {
      await devicePage({deviceSn: oldDeviceSn.value}).then(response => {
        if (response.rows.length == 0) {
          Notify({type: 'danger', message: '旧设备编号不存在'});
        } else {
          id.value = response.rows[0].id;
          typeId.value = response.rows[0].deviceType;
        }
      })
      await deviceType({pageNum: 1, pageSize: 10000}).then(response => {
        deviceTypeListValue.value = response.rows.filter(item => item.id === typeId.value)
        socketPower.value = deviceTypeListValue.value[0].socketPower;
        kindType.value = deviceTypeListValue.value[0].id;
        deviceKindType.value = deviceTypeListValue.value[0].deviceType;
        socketCount.value = deviceTypeListValue.value[0].socketCount;
        data.id = id.value;
        data.kindType = kindType.value;
        data.deviceSn = newDeviceSn.value;
        data.deviceKindType = deviceKindType.value;
        data.socketCount = socketCount.value;
        data.socketPower = socketPower.value;

      })
      deviceReplace(data).then(res => {
        if (res.code == 200) {
          Notify({type: 'success', message: '修改成功'});
          oldDeviceSn.value = null;
          newDeviceSn.value = null;
        } else {
          Notify({type: 'danger', message: '修改失败'});
        }
      })
    }
    return {onScanQrCode, onDeviceReplace, newDeviceSn, oldDeviceSn,getCodeVal};
  },
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
}

.input-s {
  border: 1px solid #999999;
  margin-top: 10px;
  min-width: 260px;
}

</style>