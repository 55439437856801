<template>
  <div class="container">
    <div>
      <div class="count">
        <div class="money">
          <span>¥</span>
          {{ leftAmount }}
          <span>.{{ rightAmount }}</span>
        </div>
        <div class="pay-countdown">
          <span class="name">1年服务</span> <span class="name">合计{{idList.length}}台</span>
        </div>
      </div>
      <div class="payType">
        
          <div class="item">
            <div class="left">
            
              <van-image
                src="https://anchong-dev-1304380937.cos.ap-shanghai.myqcloud.com/e435bf733c124242a0a9cb442d38ea09FQIvSSNErwRM776ca5496b151bc33da2fe693e0f0455.png"
                class="icon"
            />
              <div class="name">微信支付</div>
            </div>
            <div class="right">
              <van-image
                class="icon"
                src="https://anchong-dev-1304380937.cos.ap-shanghai.myqcloud.com/fb244dd5d16b4ac594abe272a0a86bb9X9R8VbEIoNUE77e5de44267e416ce8a8501435de23e3.png"
              />
              
            </div>
          </div>
        
      </div>
      <div class="bottom-btn">
        <van-button round type="success" block width="100%" class="submit" @click="submitOrder"
          >确认支付</van-button
        >
      </div>
    </div>
    <!-- <div v-else class="pay-success">
        <van-image
          class="top-icon"
          src="https://anchong-dev-1304380937.cos.ap-shanghai.myqcloud.com/fb244dd5d16b4ac594abe272a0a86bb9X9R8VbEIoNUE77e5de44267e416ce8a8501435de23e3.png"
        />
        <div class="title">支付成功</div>
        <van-button class="btn" round type="success" block   @click="backIndex"
          >返回首页</van-button
        >
    </div> -->
    
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { renewalAll } from "@/api/order";
import {devicePage} from "@/api/device";
import { ref ,getCurrentInstance} from "vue";
import { onMounted } from "@vue/runtime-core";

import { wxConfig } from "@/utils/wx.js";
import { Toast} from 'vant';


export default {


  setup(){
    const router = useRouter()
    const { proxy } = getCurrentInstance();
    const idList = ref([])
    const leftAmount = ref('')
    const rightAmount = ref('')
    const paySuccess = ref(true)
    const init = ()=>{
        let params = {
        pageNum: 1,
        pageSize: 10000,
        deviceTypeSeparate: 0,
        renewal: 1,
        activeStatus: 1,
        renewalFlag: true
      }
      let ids = []
      let total = 0
      devicePage(params).then(res => {
        if(res.code === 200){
          const list = res.rows;
          if(list.length === 0){
            paySuccess.value = false
          }
          list.forEach(item => {
            total += item.servicePrice
            ids.push(item.id)
          }); 
          let stringMoney = parseFloat(total / 100).toFixed(2);
          let splitVal = stringMoney.toString().split(".");
          leftAmount.value = splitVal[0];
          rightAmount.value = splitVal[1].substring(0,2);
          idList.value = ids;
        }
      })
    }
    const submitOrder = () => {
      let channelUserId = window.localStorage.getItem('channelUserId');
        let data = {
          channelUserId:channelUserId,
          ids: idList.value,
          clientType: 2 // 1 客户端 2 C端
        }
        renewalAll(data).then(res => {
          if(res.code === 200){
            let result = res.data.goPayResult;
            let wxPackage = result.wxPackage;
            let val = wxPackage.split("=");
            proxy.$wx.miniProgram.navigateTo({url:`/pages/discover/pay?timeStamp=${result.timeStamp}&noceStr=${result.nonceStr}&signType=${result.signType}&paySign=${result.paySign}&package1=${val[0]}&package2=${val[1]}`});
          }
        })
    }
    const backIndex = () => {
      router.push({ path: "/" })
    }
    onMounted(() => {
      init()
    })
   
    return {
      idList,
      leftAmount,
      rightAmount,
      submitOrder,
      backIndex,
      paySuccess
    };
  }
};
</script>

<style lang="scss" scoped>
.container {
  // height: 100vh;
  overflow: hidden;
  .pay-success{
    display: flex;
    flex-direction: column;
    align-items: center;
    .top-icon{
      width: 100px;
      height: 100px;
      display: block;
      margin: 106px 0 16px 0;
    }
    .title{
      font-size: 24px;
      height: 48px;
      line-height: 48px;
    }
    .btn{
     width: 60vw;
     margin-top: 24px;
    }
  }
  .bottom-btn {
    position: fixed;
    bottom: 12px;
    left: 50%;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    margin-left: -150px;
    width: 300px;
  }
  .h32 {
    height: 16px;
  }
  .payType{
    border-top: 1px solid #E0E0E0;
    margin: 64px 16px 0 16px;
    padding-top: 12px;
    .item{
      display: flex;
      justify-content: space-between;
      .left{
        display: flex;
        .icon{
          width: 32px;
          height: 32px;
          display: block;
        }
        .name{
          margin-left: 12px;
          line-height: 32px;
          font-size: 16px;
        }
      }
      .right{
        .icon{
          margin-top: 7px;
          width: 18px;
          height: 18px;
          display: block;
        }
      }
    }
  }
  .count {
    padding-top: 27px;
    text-align: center;

    .money {
      color: #ff3552;
      font-weight: bold;
      font-size: 36px;
      line-height: 50px;

      span {
        
        font-size: 18px;
        line-height: 33px;
      }
    }

    .pay-countdown {
      padding-top: 8px;

      .name {
        font-size: 14px;
        font-weight: 400;
        color: #828282;
        line-height: 14px;
      }

      .time {
        padding-top: 3px;
        padding-bottom: 25px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        line-height: 16px;
      }
    }
  }
}
</style>