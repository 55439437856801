<template>
  <div class="add_page">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="addForm.type"
          is-link
          readonly
          name="type"
          label="账号类型"
          placeholder="点击选择账号类型"
          @click="showPicker = true"
        />
        <van-field
          v-model="addForm.yhName"
          name="yhName"
          label="银行名称"
          placeholder="填写银行名称"
          :rules="[{ required: true, message: '请填写银行名称' }]"
        />
        <van-field
          v-model="addForm.zhName"
          name="zhName"
          label="支行名称"
          placeholder="填写支行名称"
          :rules="[{ required: true, message: '请填写支行名称' }]"
        />
        <van-field
          v-model="addForm.cardNum"
          name="cardNum"
          label="银行卡号"
          type="number"
          placeholder="填写银行卡号"
          :rules="[{ required: true, message: '请填写银行卡号' }]"
        />
        <van-field
          v-model="addForm.chikaName"
          name="chikaName"
          label="银行卡姓名"
          placeholder="填写银行卡姓名"
          :rules="[{ required: true, message: '请填写银行卡姓名' }]"
        />
      </van-cell-group>
      <div class="bottom_btn">
          <van-button block type="default" @click="cancel" >
          取消
        </van-button>
        <van-button block type="primary" native-type="submit" color="#07c160">
          保存
        </van-button>
      </div>
    </van-form>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter()
    const columns = ["银行卡", "支付宝"];

    const showPicker = ref(false);
    const addForm = reactive({
      type: "",
      yhName: "",
      zhName: "",
      cardNum: "",
      chikaName: "",
    });

    const onConfirm = (value) => {
      console.log(value);
      addForm.type = value;
      showPicker.value = false;
    };

    const cancel = ()=> {
        router.go(-1)
    }

    return {
      addForm,
      showPicker,
      columns,
      onConfirm,
      cancel
    };
  },
};
</script>

<style lang="scss" scoped>
.add_page {
  padding: 0 0 12px;
  .bottom_btn {
      position: fixed;
      display: flex;
      bottom: 0;
      left: 0;
      right: 0;
    //   padding: 12px;
  }
}
</style>
