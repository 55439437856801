<template>
  <div class="container">
    <div>
      <div class="order-title">
        <div class="order-title-text">订单编号：{{ item.orderNo }}</div>
        <Tag plain type="success" size="large" style="background-color:#f759ab;color: #ecf9ff" v-if="item.orderStatus==6">{{ "充电异常" }}</Tag>
        <Tag plain type="success" size="large" style="background-color:#bfbfbf;color: #ecf9ff" v-else-if="item.orderStatus==5">{{ "已取消" }}</Tag>
        <Tag plain type="success" size="large" style="background-color:#5cdbd3;color: #ecf9ff" v-else-if="item.orderStatus==4">{{ "暂停中" }}</Tag>
        <Tag plain type="success" size="large" style="background-color:#00AC50;color: #ecf9ff" v-else-if="item.orderStatus==3">{{ "充电完成" }}</Tag>
        <Tag plain type="success" size="large" style="background-color:#40a9ff;color: #ecf9ff" v-else-if="item.orderStatus==2">{{ "充电中" }}</Tag>
        <Tag plain type="success" size="large" style="background-color:#adc6ff;color: #ecf9ff" v-else-if="item.orderStatus==1">{{ "网络异常" }}</Tag>
        <Tag plain type="success" size="large" style="background-color:#d3adf7;color: #ecf9ff" v-else-if="item.orderStatus==0">{{ "待支付" }}</Tag>
      </div>
      <div class="stop-reason">
        <div class="stop-reason-text">停止原因：{{ getStatusType(item) }}</div>
      </div>
      <Cell title="设备名称" value="阳光翠庭9栋4号">{{ item.deviceName }}</Cell>
      <Cell title="设备编号" value="11111111">{{ item.deviceSn }}</Cell>
      <Cell title="充电插口" value="12">{{ item.socketNo }}</Cell>
      <Cell title="开始时间" value="2016-09-08 09:08:00">{{ item.createTime }}</Cell>
      <Cell title="结束时间" value="2016-09-08 09:08:00" v-if="item.orderStatus!=2">{{ item.finishTime }}</Cell>
      <Cell title="充电模式" value="功率模式" v-if="item.modeType==1">{{"功率模式"}}</Cell>
      <Cell title="充电模式" value="功率模式" v-if="item.modeType==2">{{"时间模式"}}</Cell>
      <Cell title="充电模式" value="功率模式" v-if="item.modeType==3">{{"电量模式"}}</Cell>
      <Cell title="订单类型" value="扫码充电">{{item.orderSource==1?"电卡充电":"扫码充电"}}</Cell>
      <Cell title="订单来源" value="力方智充小程序">{{ operatorName }}</Cell>
      <Cell title="设备类型" value="">{{ deviceTypeSeparate }}</Cell>
      <Cell title="电卡号" value="" v-if="item.orderSource==1">{{ item.cardNo }}</Cell>
      <Cell title="手机号" value="" v-if="item.orderSource==2">{{ item.memberPhone }}</Cell>
      <Cell title="免费用户" value="否">{{ item.isFree == true ? "是" : "否" }}</Cell>
      <Cell title="充电时长" value="60分钟">{{ item.chargingDuration }}分钟</Cell>
      <Cell title="消费金额" value="0.31元">{{ item.realAmount / 100 }}元</Cell>
      <Cell title="充电电量" value="0.31元">{{ item.totalEnergy?item.totalEnergy.toFixed(4):0}}度</Cell>
      <Cell title="结束状态" value="电池充满">{{ getStatusType(item) }}</Cell>
    </div>
    <div style="padding: 12px 6px;">
      <div class="content-item">
        <div class="item-title">分润信息</div>
        <div class="table-header">
          <div class="table-header-item">分润人</div>
          <div class="table-header-item">分润类型</div>
          <div class="table-header-item">分润比例</div>
          <div class="table-header-item">分润金额</div>
        </div>
        <div class="table-body">
          <div class="table-row" v-for="(zsy,index) in dataList">
            <div class="table-body-item">{{ zsy.percentSnap.userName }}</div>
            <div class="table-body-item">
              <div v-if="zsy.userType===1">
                {{ "代理商" }}
              </div>
              <div v-else-if="zsy.userType===2">
                {{ "物业" }}
              </div>
              <div v-else-if="zsy.userType===4">
                {{ "合伙人" }}
              </div>
            </div>
            <div class="table-body-item">{{ zsy.percentSnap.percent * 100 }}%</div>
            <div class="table-body-item">{{ zsy.shareAmount / 100 }}元</div>
          </div>
        </div>

      </div>
    </div>
    <div style="padding: 0 6px 24px 6px;">
      <div class="content-item">
        <div class="item-title">充电功率折线图</div>
        <div ref="chartRef" :style="{ width:'100%',height: '300px' }"></div>
      </div>
    </div>
    <div style="padding: 0 6px 24px 6px;">
      <div class="content-item">
        <div class="item-title">充电电压折线图</div>
        <div ref="chartRefVoltage" :style="{ width:'100%',height: '300px' }"></div>
      </div>
    </div>
    <div style="padding: 0 6px 50px 6px;">
      <div class="content-item">
        <div class="item-title">充电电流折线图</div>
        <div ref="chartRefCurrent" :style="{ width:'100%',height: '300px' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {ref, unref, onMounted} from 'vue';
import {Popup, Divider, Form, Field, CellGroup, Calendar, Tag, Cell} from 'vant';
import echarts from '@/components/Echarts/echarts';
import {equipmentProfit, getOperatorName, listStation} from "@/api/order";
import {deviceType} from "@/api/device";

export default {
  components: {
    Popup,
    Divider,
    Form,
    Field,
    CellGroup,
    Calendar,
    Tag,
    Cell
  },
  setup(props, {emit}) {
    const route = useRoute()
    const router = useRouter();
    const item = ref(JSON.parse(route.query.item));
    const chartRef = ref(null);
    const chartRefVoltage = ref(null)
    const chartRefCurrent = ref(null)
    const operatorName = ref();
    const powerGraph = []
    const powerGraphLength = []
    const voltageGraph = []
    const voltageGraphLength = []
    const currentGraph = []
    const currentGraphLength = []
    const deviceTypeSeparate =ref()
    let chartInstance = null;
    let chartInstanceVoltage = null;
    let chartInstanceCurrent = null;
    onMounted(() => {
      deviceType().then(res=>{
        res.rows.forEach(it => {
          if (it.id===item.value.deviceType){
            if (it.deviceType===0){
              deviceTypeSeparate.value='两轮电动车充电桩'
            }else if (it.deviceType===1){
              deviceTypeSeparate.value='汽车充电桩'
            }else {
              deviceTypeSeparate.value=''
            }
          }
        })
      })
      getOperatorName(item.value.channelId).then(response=>{
        operatorName.value=response.msg;
      })
      equipmentProfit(item.value.orderNo).then(response => {
        dataList.value = response.data.profitShareList
        console.log(response.data.profitShareList)
        //  if (response.data.cuOrder.totalEnergy != 0) {
        for (let i = 0; i < response.data.cuOrderDetail.powerGraph.length; i++) {
          powerGraphLength.push(i)
          powerGraph.push(response.data.cuOrderDetail.powerGraph[i])
        }
        for (let i = 0; i < response.data.cuOrderDetail.voltageGraph.length; i++) {
          voltageGraphLength.push(i)
          voltageGraph.push(response.data.cuOrderDetail.voltageGraph[i])
        }
        for (let i = 0; i < response.data.cuOrderDetail.currentGraph.length; i++) {
          currentGraphLength.push(i)
          currentGraph.push(response.data.cuOrderDetail.currentGraph[i])
        }
        //   }
        initData();//功率图
        initVoltage();//电压图
        initCurrent();//电流图
      })
    })
    const sharePercent = ref('')//分润比例
    const userName = ref('')//分润姓名
    const userType = ref('')//分润类型
    const shareAmount = ref('')//分润金额
    const getStatusType = (item) => {
      let endCode = item.endCode;
      if (endCode === 0) {
        return ''
      } else if (endCode === 1) {
        return "时间跑完";
      } else if (endCode === 2) {
        return "电池充满";
      } else if (endCode === 3) {
        return "用户手机端停止";
      } else if (endCode === 4) {
        return "插座超功率";
      } else if (endCode === 5) {
        return "充电桩超功率";
      } else if (endCode === 6) {
        return "插座松动";
      } else if (endCode === 7) {
        return "系统故障，继电器损坏，保险丝烧坏等";
      } else if (endCode === 8) {
        return "插座已被占用";
      } else if (endCode === 9) {
        return "电压超范围，目前有效范围为：180V~260V";
      } else if (endCode === 10) {
        return "温度超范围";
      } else if (endCode === 11) {
        return "可充插座已占满";
      } else if (endCode === 18) {
        return "设备断电";
      } else if (endCode === 20) {
        return "系统巡检";
      } else if (endCode === 64) {
        return "系统手动停止";
      } else if (endCode === 100) {
        return "业务异常：账户余额不足";
      } else if (endCode === 101) {
        return "业务异常：卡余额不足";
      } else if (endCode === 102) {
        return "业务异常：订单金额已用尽";
      } else if (endCode === 103) {
        return "业务异常：时间结束";
      } else if (endCode === 104) {
        return "业务异常：功率超限";
      } else if (endCode === 105) {
        return "业务异常：检测端口结束";
      } else if (endCode === 106) {
        return "业务异常：端口未被占用";
      } else if (endCode === 107) {
        return "业务异常：没有找到订单";
      } else if (endCode === 108) {
        return "系统结束";
      } else if (endCode === 109) {
        return "提交订单结束";
      } else if (endCode === 110) {
        return "用户手动停止";
      } else if (endCode === 111) {
        return "订单开始失败";
      } else if (endCode === 112) {
        return "未命中余额缓存";
      }
    }
    const endCode = ref('')
    const orderSource = ref()//订单来源


    const dataList = ref([])
    const initCurrent = () => {
      const option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              width: 1,
              color: '#019680',
            },
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          //data: [10, 20, 30, 40, 50],
          data: currentGraphLength,
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              type: 'solid',
              color: 'rgba(226,226,226,0.5)',
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false,
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ['rgba(255,255,255,0.2)', 'rgba(226,226,226,0.2)'],
              },
            },
          },
        ],
        legend: {
          data: ['电流(A)'],
        },
        grid: {left: '1%', right: '2%', top: '10%', bottom: 0, containLabel: true},
        series: [
          {
            name: '电流(A)',
            data: currentGraph,
            //data: [100, 140, 150, 200, 220],
            type: 'line',
            itemStyle: {
              color: '#5ab1ef',
            },
          },
        ],
      };
      if (chartInstanceCurrent) {
        chartInstanceCurrent.setOption(option);
      } else {
        chartInstanceCurrent = echarts.init(unref(chartRefCurrent));
        chartInstanceCurrent.setOption(option);
      }
    }
    const initVoltage = () => {
      const option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              width: 1,
              color: '#019680',
            },
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          //data: [10, 20, 30, 40, 50],
          data: voltageGraphLength,
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              type: 'solid',
              color: 'rgba(226,226,226,0.5)',
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false,
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ['rgba(255,255,255,0.2)', 'rgba(226,226,226,0.2)'],
              },
            },
          },
        ],
        legend: {
          data: ['电压(v)'],
        },
        grid: {left: '1%', right: '2%', top: '10%', bottom: 0, containLabel: true},
        series: [
          {
            name: '电压(v)',
            data: voltageGraph,
            //data: [100, 140, 150, 200, 220],
            type: 'line',
            itemStyle: {
              color: '#5ab1ef',
            },
          },
        ],
      };
      if (chartInstanceVoltage) {
        chartInstanceVoltage.setOption(option);
      } else {
        chartInstanceVoltage = echarts.init(unref(chartRefVoltage));
        chartInstanceVoltage.setOption(option);
      }
    }
    const initData = () => {
      const option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              width: 1,
              color: '#019680',
            },
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          //data: [10, 20, 30, 40, 50],
          data: powerGraphLength,
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              type: 'solid',
              color: 'rgba(226,226,226,0.5)',
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false,
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ['rgba(255,255,255,0.2)', 'rgba(226,226,226,0.2)'],
              },
            },
          },
        ],
        legend: {
          data: ['功率(w)'],
        },
        grid: {left: '1%', right: '2%', top: '10%', bottom: 0, containLabel: true},
        series: [
          {
            name: '功率(w)',
            data: powerGraph,
            //data: [100, 140, 150, 200, 220],
            type: 'line',
            itemStyle: {
              color: '#5ab1ef',
            },
          },
        ],
      };
      if (chartInstance) {
        chartInstance.setOption(option);
      } else {
        chartInstance = echarts.init(unref(chartRef));
        chartInstance.setOption(option);
      }

    }
    return {
      deviceTypeSeparate,
      operatorName,
      chartRef,
      chartRefVoltage,
      chartRefCurrent,
      item,
      sharePercent,
      userName,
      userType,
      shareAmount,
      endCode,
      orderSource,
      powerGraph,
      powerGraphLength,
      dataList,
      getStatusType
    };
  },
}
</script>

<style scoped>
.container {
  background-color: #f5f5f5;
  width: 100vw;
  overflow: hidden;
}

.order-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 10px;
  height: 44px;
  background: #FFFFFF;
  border-radius: 12px 12px 0 0;
}

.order-title-text {
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}

.stop-reason {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 10px;

  width: 351px;
  height: 20px;

  /* /bgf5 */

  background: #F5F5F5;
  border-radius: 2px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.stop-reason-text {
  font-size: 12px;
  color: #666666;
}

.content-item {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  /* white */

  background: #FFFFFF;
  border-radius: 8px;

}

.item-title {
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  margin: 6px 0 6px 12px;
  width: 100%;
}

.table-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}

.table-header-item {
  background: #EEEEEE;
  border: 1.5px solid #DCDCDC;
  padding: 12px 24px;
  color: #666666;
  font-size: 12px;
  width: 49px;
}

.table-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.table-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.table-body-item {
  background: #FFFFFF;
  border: 1.5px solid #DCDCDC;
  padding: 12px 24px;
  color: #666666;
  font-size: 12px;
  width: 49px;
  height: 21px;
}


</style>