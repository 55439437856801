<template>
  <div class="container">
    <div class="step-1" v-if="showFirst">
    <div class="main-area">
        <div class="area" @click="onScanQrCode">
          <van-image
            width="40px"
            height="40px"
            :src="scanSrc"
          />
        </div>
        <div class="step">
          <span>1.</span>
          扫描设备二维码
        </div>
        <div class="tips">
          提示： 请扫描设备二维码获取设备信息
        </div>
      </div>
    </div>
    <div class="step-2" v-else>
      <Loading size="24px" v-if="showLoading">更新中...</Loading>
      <div class="top-info">
        <div class="outer">
          <div class="device-name">{{deviceValue.deviceName}}</div>
          <div class="info">{{deviceValue.deviceSn}}</div>
          <div class="info">{{deviceValue.apartmentName}}</div>
          <div class="h-24"></div>
          <div class="info">{{deviceValue.provinceName}}{{deviceValue.cityName}}{{deviceValue.countyName}}</div>
          <div class="info">{{deviceValue.address}}</div>
          <div class="h-24"></div>
          <div class="info">上次更新时间：</div>
          <div class="info">{{deviceValue.updateTime}}</div>
        </div>
      </div>
      <div class="main-area">
        <div class="area">
          <van-image
            width="40px"
            height="40px"
            :src="positionSrc"
            @click="getLocation"
          />
        </div>
        <div class="step">
          <span>2.</span>
          更新定位
        </div>
        <div class="tips">
          提示：请在设备现场更新定位，以获取准确定位信息
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import {
  getAgentInfo
} from "@/api/stat";
import {devicePage,updateDevice} from "@/api/device";
import {
  Grid,
  GridItem,Dialog,Loading
} from "vant";
import { ref ,getCurrentInstance} from "vue";
import { onMounted } from "@vue/runtime-core";
import { wxConfig } from "@/utils/wx.js";


export default {
  components: {
    Grid,
    GridItem,
    Dialog,
    Loading
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const userType = ref();
    const showFirst = ref(true);
    const showLoading = ref(false);
    const deviceValue = ref({})
    const deviceSn = ref("");
    const positionSrc = require("@/assets/fixedPosition.png")
    const scanSrc = require("@/assets/scan.png")
    const getLocation = () => {
      showLoading.value = true;
      wxConfig(proxy.$wx, () => {
					proxy.$wx.getLocation({
            debug: true,
						type: 'gcj02',
						success: function(res) {
              updatePositon(res.longitude,res.latitude)
						}
					});
				});
      // let x = "118.7787";
      // let y = "31.99226";

      // updatePositon(x,y);

    }
    const updatePositon = (x,y) => {
      let data = {
       ...deviceValue.value
      };

      let simp = {
        x:x,
        y:y
      }
      data.simpleLocation = simp;
      data.simplePoint=simp;
      updateDevice(data).then((res) => {
        showLoading.value = false;
         if(res.code === 200){
           Dialog.alert({
            message: '定位更新成功',
            theme: 'round-button',
          }).then(() => {
            showFirst.value = true;
          });
         }
      })
    }
    const onScanQrCode = () => {
      wxConfig(proxy.$wx, () => {
        proxy.$wx.scanQRCode({
          needResult: 1,
          scanType: ["qrCode"],
          success: function (res) {
            const url = res.resultStr;
            //'http://charging.test.anchong001.com/web/wx?'
            let b = url.split("?");
            //['http://charging.test.anchong001.com/web/wx','sn=521200829145848$$outletNum=1']
            let c = b[1].split("="); //['sn','521200829145848$$outletNum','1']
            let d = c[1].split("$$"); //['521200829145848','outletNum']
            //调用查询详情
            deviceSn.value = d[0];
            getDetail(d[0]);
          },
        });
      });
      // deviceSn.value = "801234567891234";
      // getDetail(deviceSn.value);
    }
    const getDetail = (sn) => {
      devicePage({deviceSn:sn}).then((res) => {
        if(res.code === 200){
          if(res.rows.length > 0){
            showFirst.value = false
          deviceValue.value = res.rows[0];
          }else{
             Dialog.alert({
            message: '未查询到设备',
            theme: 'round-button',
          }).then(() => {
            
          });
          }
          
        }
      })
    }
    onMounted(() => {

    })
    return {
      userType,
      positionSrc,
      showFirst,
      scanSrc,
      onScanQrCode,
      getLocation,
      getDetail,
      deviceValue,
      updatePositon,
      deviceSn,
      showLoading
    };
  }

}
</script>

<style lang="scss" scoped>
.container {
  background-color: #ffffff;
  .step-1{
    .main-area{
      display: flex;
      flex-direction: column;
      align-items: center;
      .area{
        margin-top: 200px;
        width: 120px;
        height: 120px;
        background-color: #00d655;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .step{
        margin: 24px 0;
        span{
          font-size: 36px;
        }
        font-size: 14px;
      }
      .tips{
        font-size: 14px;
        
      }
    }
  }
  .step-2{
    .top-info{
      margin: 10px 10px;
      .outer{
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 12px;
        .device-name{
          font-size: 18px;
        }
        .h-24{
          height: 18px;
        }
        .info{
          font-size: 14px;
        }
      }
    }
    .main-area{
      display: flex;
      flex-direction: column;
      align-items: center;
      .area{
        margin-top: 80px;
        width: 120px;
        height: 120px;
        background-color: #00d655;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .step{
        margin: 24px 0;
        span{
          font-size: 36px;
        }
        font-size: 14px;
      }
      .tips{
        font-size: 14px;
        
      }
    }

  }
  
}

</style>