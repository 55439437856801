import request from '../utils/request.js'

// 查询充电站列表
export function listStation(query) {
    return request({
        url: '/device/station/list',
        method: 'get',
        params: query
    })
}

// 查询设备数量
export function aggregation(query) {
    return request({
        url: '/device/aggregation',
        method: 'get',
        params: query
    })
}

// 查询设备状态
export function socketPage(query) {
    return request({
        url: '/device/socket/page',
        method: 'get',
        params: query
    })
}

// 更新插口功率
export function socketUpdate(data) {
    return request({
        url: '/device/socket/update',
        method: 'post',
        data: data
    })
}

export function pageComb(queryParams){
    return request({
        url: '/chargeManage/combination/page',
        method: 'get',
        params: queryParams
    })
}

// 查询agent列表
export function listAgent(query) {
    return request({
        url: '/bizUser/agent/page',
        method: 'get',
        params: query
    })
}

// 查询agent列表
export function listBroker(query) {
    return request({
        url: '/bizUser/broker/page',
        method: 'get',
        params: query
    })
}

// 查询estate列表
export function listEstate(query) {
    return request({
        url: '/bizUser/estate/page',
        method: 'get',
        params: query
    })
}

// 查询estate列表
export function listPartner(query) {
    return request({
        url: '/bizUser/partner/page',
        method: 'get',
        params: query
    })
}

// 查询物业列表
export function pageEstate(query) {
    return request({
        url: '/system/estate/page',
        method: 'get',
        params: query
    })
}

// 查询合伙人列表
export function partnerList(query) {
    return request({
        url: '/system/partner/list',
        method: 'get',
        params: query
    })
}
// 代理商信息
export function getAgentInfo() {
    return request({
        url: '/getAgentInfo',
        method: 'get'
    })
}

// 修改分润
export function modify(data) {
    return request({
        url: '/profit/share/add',
        method: 'post',
        data: data
    })
}

// 修改设备
export function updateDevice(data) {
    return request({
        url: '/device/edit',
        method: 'post',
        data: data
    })
}
export function changeActiveStatus(data) {
    return request({
        url: '/device/edit/pub',
        method: 'post',
        data: data
    })
}

export function devicePage(query) {
    return request({
        url: '/device/page',
        method: 'get',
        params: query
    })
}

// 查询设备类型
export function deviceType(query) {
    return request({
        url: '/device/deviceType',
        method: 'get',
        params: query
    })
}

// 替换设备
export function deviceReplace(data) {
    return request({
        url: '/device/replace',
        method: 'post',
        data: data
    })
}

//设备绑定
export function deviceBinding(data) {
    return request({
        method: 'POST',
        url: '/device/active',
        data:data
    })
}

//查询故障设备
export function brokenSockets(data) {
    return request({
        method: 'POST',
        url: '/device/socket/sockets',
        data: data
    })
}

// 查询设备分润
export function deviceProfit(deviceSn) {
    return request({
        url: '/profit/share/device/' + deviceSn,
        method: 'get'
    })
}
// 查询设备消费模板
export function deviceStepTpl(deviceSn) {
    return request({
        url: '/device/deviceStepTpl/'+deviceSn,
        method: 'get',
    })
}

// 查询充电站消费价格详细
export function getStationTpl(id) {
    return request({
        url: '/device/station/tpl/' + id,
        method: 'get'
    })
}
// 查询设备汇总列表
export function getAgentHasRenewalDevice() {
  return request({
    url: '/device/getAgentHasRenewalDevice',
    method: 'get',
  })
}
