<template>
        <!-- <ListPage @register="register">
            <template #item="{dataList}">              
              <div :key="index" v-for="(item,index) in dataList">
                  <div class='item-content' @click="handleChecked(index)">
                      <div>
                        <div class='item-name'>王渐渐</div>
                        <div class='item-phone' style="padding:'10px';">手机号：1234567890</div>
                      </div>

                        <div class='checked-icon' v-if ="currentIndex === index">
                            <Icon name="success" color='rgb(8, 223, 134)'/>
                        </div>
                  </div>               
              </div>
            </template>
      </ListPage> -->
      <List
      ref="listRef"
      :queryList = "queryList"
      :params = "params"
      >
          <template #default>
            <div :key="index" v-for="(item,index) in dataList" style="margin-top: 12px" @click="handleChecked(item,index)">
                  <div class='item-content' :class="currentIndex === index ? 'item-content-checked' : ''">
                      <div>
                        <div class='item-name'>{{item.username}}</div>
                        <div class='item-phone'>手机号：{{item.phone}}</div>
                      </div>

                        <!-- <div class='checked-icon' v-if ="currentIndex === index">
                            <Icon name="success" color='rgb(8, 223, 134)'/>
                        </div> -->
                  </div>      
             </div>
          </template>
      </List>
      <div v-if="show" class='bottom-button'>
          <div :style="{flex:2}">
            <Button type="success" block  @click='goAssign(sample)'>分配充电站</Button>
          </div>
          <Button type="primary" @click="goEdit(sample)">编辑</Button>
          <Button type="danger" @click="handleDelete(sample)">删除</Button>
          <Dialog v-model:show="showDialog" show-cancel-button/>
      </div>
</template>

<script>
import {ref, onMounted,unref,toRaw} from 'vue';
import {SwipeCell,Button,Icon,Dialog} from 'vant';
import {useRouter} from "vue-router"
import {ListPage, useListPage} from "@/components/ListPage";
import List from './list.vue';
import {queryPartnerList,deletePartner} from '@/api/client'


export default {
    components:{
       ListPage,
       SwipeCell,
       Button,
       Icon,
       List,
       [Dialog.Component.name]: Dialog.Component,

    },

    setup(){
        const router = useRouter()
        const dataList = ref([]);
        const total = ref(0);
        const sample = ref()
        const show = ref(false);
        const listRef = ref(null)
        const showDialog = ref(false);
        const currentIndex = ref(-1);
        
        const params = {
                pageNum:0,
                pageSize:10
        }

        onMounted(() => {
            listRef.value.onLoad()
        });

        const queryList = (params) => {

    return new Promise((resolve,reject) => {
        queryPartnerList(params).then((res)=>{
            if(res.code === 200){
                total.value = res.total
                if(params.pageNum > 1){
                    dataList.value=dataList.value.concat(res.rows)
                } else{
                    dataList.value = res.rows
                }     
                resolve(
                    {
                        length:dataList.value.length,
                        total:total.value,
                        datalist:dataList.value,
                    }
                )
                    
            }
        });
            
        })
        }

        const  handleChecked = (item,index) => {
            sample.value = item
            show.value = true;
            currentIndex.value = index
        }

        const goAssign = (item) => {
            console.log('item',item.username)        
            router.push({path:`/client/distributionStation/${item.id}`,query:{username:item.username}})
        }

        const goEdit = (sample) => {     
            const item = JSON.stringify( toRaw(sample))
            router.push({path:"/client/add",query:{item:item,state:2}})
        }

        const handleDelete = (sample) => {
            showDialog.value = true
            const id = sample.id 
            Dialog.confirm({
                message:
                    '确认删除吗？',
                })
                .then(() => {
                    // on confirm
                    deletePartner(id).then(res => {
                        console.log("删除res",res)
                        if(res.code === 200){
                            console.log('数组',toRaw(dataList.value))
                         dataList.value =  dataList.value.filter((item)=>{ 
                             return item.id !== id
                        })
                        cleanStatus()
                        }
                    })

                })
                .catch(() => {
                }) 
        }
        const cleanStatus = () => {
          show.value = false;
          currentIndex.value = -1;
        }

        return {
          dataList,
          sample,
          total,
           show,
           showDialog,
           listRef,
           params,
           queryList,
           handleChecked,
           currentIndex,
           goAssign,
           goEdit,
           handleDelete,
           cleanStatus
        }
    }

}
</script>

<style lang="scss" scoped>
.item-content {
  position: relative;
  display: flex;
  padding: 12px;
  margin: 0 12px 12px 12px;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 25%);
  border-radius: 5px;
  /* border-bottom: 0.5px solid rgb(196, 191, 191); */
  .item-name {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
  }
  .item-phone {
    font-size: 13px;
    color: #666666;
    margin-top: 8px;
  }
}
.item-content-checked {
  background-color: #00d655;

  .item-name {
    color: #fff;
  }
  .item-phone {
    color: #fff;
  }
}
  .checked-icon {
      position: absolute;
      right:60px;
      top:30px;
      width:20px;
      height: 20px;
      border:1px solid rgb(8, 223, 134);
      border-radius: 10px;
      text-align: center;
  }

  .bottom-button{
      display: flex;
      width:100%;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
  }


</style>