<template>
  <div class="apply_card">
    <div>
      <span>{{ item.withdrawChannel == 1 ? "银行卡" : "支付宝" }}{{ item.cardNum }}</span>
      <span style="float:right">
        <van-tag type="primary" v-if="item.applyStatus === 10">待代理商审核</van-tag>
        <van-tag type="warning" v-if="item.applyStatus === 20">代理商拒绝审核</van-tag>
        <van-tag type="primary" v-if="item.applyStatus === 30">待核账</van-tag>
        <van-tag type="primary" v-if="item.applyStatus === 40">待打款</van-tag>
        <van-tag type="success" v-if="item.applyStatus === 50">成功</van-tag>
        <van-tag type="warning" v-if="item.applyStatus === 60">核账失败</van-tag>
        <van-tag type="warning" v-if="item.applyStatus === 61">运营商拒绝</van-tag>
        <van-tag type="primary" v-if="item.applyStatus === 70">撤销</van-tag>
      </span>
    </div>
    <div> {{item.applyUserType == 1?'代理商(ID: ':item.applyUserType == 2? '物业(ID:':'合伙人(ID: '}}
      {{ item.applyUserId}})</div>
    <div>
      <span class="time">{{ item.applyTime }}</span>
      <span class="money_text">￥<span class="money">{{ item.amount / 100 }}</span>
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: "ApplyCard",
  props: {
    item: Object,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.apply_card {
  padding: 12px;
  border-radius: 8px;
  font-size: 12px;
  color: #333;
  border: 1px solid #e0e0e0;
  margin-top: 12px;

  .time {
    color: #ccc;
  }

  .money_text {
    font-size: 18px;
    color: #333;
    margin-left: 100px;

    .money {
      color: red;
    }
  }
}
</style>
