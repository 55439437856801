<template>
  <div v-if="dataShow">
    <div>
      <van-cell-group>
        <van-cell title="申请编号" :value="data && data.applyNo" />
        <van-cell
          title="申请人信息"
          :value="
            data && data.applyUserType == 1
              ? `代理商(ID:${data.applyUserId})`
              : data.applyUserType == 2
              ? `物业(ID:${data.applyUserId})`
              : `合伙人(ID:${data.applyUserId})`
          "
        />
        <van-cell title="提现金额" :value="data.amount / 100" />
        <van-cell title="实提金额" :value="data.realAmount / 100" />
        <van-cell title="手续费" :value="data.commission / 100" />
        <van-cell
          title="提现渠道"
          :value="data && data.assetAccountAttachSnap.bankName"
        />
        <van-cell title="申请状态" :value="getDesc(data)" />
        <van-cell title="申请时间" :value="data.applyTime" />
        <van-cell title="更新时间" :value="data.updateTime" />
        <van-cell title="备注" :value="data.note" />
      </van-cell-group>
    </div>
    <div class="bottom_btn">
      <van-button
        v-show="type == 1 && data.applyStatus == 10 && data.targetUserType == userType && data.targetUserId == userId"
        plain
        type="primary"
        block
        color="#07c160"
        @click="refuse(data.id)"
        >拒绝</van-button
      >
      <van-button
        v-show="type == 1 && data.applyStatus == 10 && data.targetUserType == userType && data.targetUserId == userId"
        type="primary"
        block
        color="#07c160"
        @click="agree(data.id)"
        >通过</van-button
      >
      <van-button
        v-show="type == 1 && data.applyStatus == 30 && data.targetUserType != 1 && data.targetUserType == userType && data.targetUserId == userId"
        type="primary"
        block
        color="#07c160"
        @click="onClickValidate(data)"
        >核账</van-button
      >
      <van-button
        v-show="type == 1 && data.applyStatus == 40 && data.targetUserType != 1 && data.targetUserType == userType && data.targetUserId == userId"
        type="primary"
        block
        color="#07c160"
        @click="handleEntPayModalOpen(data)"
        >打款完成</van-button
      >
      <van-button
        v-show="type == 1 && data.applyStatus == 40 && data.targetUserType != 1 && data.targetUserType == userType && data.targetUserId == userId"
        type="danger"
        block
        color="#"
        @click="handleRefuseApplyModalOpen(data)"
        >拒绝打款</van-button
      >
      <van-button
        v-show="type == 0 && (data.applyStatus == 30 || data.applyStatus == 10 ) && data.applyUserType == userType && data.applyUserId == userId"
        type="primary"
        block
        color="#07c160"
        @click="cancel(data.id)"
        >撤销</van-button
      >
    </div>
  </div>

  <van-dialog
    v-model:show="show"
    title="拒绝理由"
    :before-close="beforeClose"
    show-cancel-button
    @confirm="onConfirm(refuseID)"
  >
    <van-cell-group inset>
      <van-field
        v-model="reason"
        label="拒绝理由:"
        placeholder="请输入拒绝理由"
        required="true"
      />
      <!--      <div class="validate-message" v-if="showMessage">请输入拒绝理由</div>-->
    </van-cell-group>
  </van-dialog>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import {
  applyAgree, applyPage,
  applyRefuse,
  cancelWithdraw,
  checkPage,
  paymentCompleted,
  validateApply,
} from "@/api/withdraw";
import { Button, Dialog, Icon, Notify, SwipeCell, Toast } from "vant";
import {getAgentInfo} from "@/api/stat";

export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const type = ref(route.query.type); // 0 申请详情 1 审核详情
    const data = ref();
    const show = ref(false);
    const dataShow = ref(false);
    const reason = ref();
    const refuseID = ref(0);
    const userType =ref();
    const userId =ref();
    // const showMessage = ref(false);
    const showConfirmButton = ref(false);
    if (!reason.value) {
      showConfirmButton.value = true;
    } else {
      showConfirmButton.value = false;
    }
    const cancel = (id) => {
      Dialog.confirm({
        title: "确认撤销？",
        message: "",
      })
        .then(() => {
          cancelWithdraw(id).then((res) => {
            if (res.code == 200) {
              router.push({ path: `/withdrawal`, query: { type: type.value } });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    };
    const agree = (id) => {
      Dialog.confirm({
        title: "确认通过？",
      })
        .then(() => {
          // on confirm
          applyAgree(id).then((res) => {
            if (res.code == 200) {
              router.push({ path: `/withdrawal`, query: { type: type.value } });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    };
    const onClickValidate = async (item) => {
      validateApply(item.id).then((res) => {
        if (res.code == "000000") {
          const params = {
            applyNo: data.value.applyNo,
          };
          checkPage(params).then((resp) => {
            if (resp.code === 200) {
              data.value = resp.rows[0];
            }
          });
        }
      });
    };
    const handleEntPayModalOpen = async (item) => {
      console.log(item);
      await Dialog.confirm({
        title: "确认打款？",
      })
        .then(() => {
          let data = {
            withdrawApplyNo: item.applyNo,
            amount: item.amount,
          };
          paymentCompleted(data).then((resp) => {
            if (resp.code === "000000") {
              router.push({ path: `/withdrawal`, query: { type: type.value } });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    };
    const handleRefuseApplyModalOpen = async (item) => {
      refuse(item.id);
    };

    //拒绝弹窗
    const refuse = (id) => {
      show.value = true;
      refuseID.value = 0;
      refuseID.value = id;
    };
    const beforeClose = (action) =>
      new Promise((resolve) => {
        if (action === "cancel") {
          resolve(true);
        } else {
          // 拦截取消操作
          if (!reason.value) {
            show.value = true;
            resolve(false);
          } else {
            show.value = true;
            resolve(true);
          }
        }
      });
    const onConfirm = (id) => {
      console.log(reason.value);
      if (reason.value != null) {
        const data = {
          applyId: id,
          note: reason.value,
        };
        applyRefuse(data).then(() => {
          // show.value = false;
          router.push({ path: `/withdrawal`, query: { type: type.value } });
        });
      } else {
        Notify({ type: "danger", message: "请填写拒绝理由!" });
      }
    };
    onMounted(() => {
      let item = JSON.parse(route.query.item);
      const params = {
        applyNo: item.applyNo,
      };
      console.log(item.applyNo,'////ces ')
      console.log(type.value,'////ces ')
      getAgentInfo().then(resp=>{
        if (resp.data.isOperator){
          userType.value = 7;
        }else {
          if (resp.data.businessUserType=='AGENT'){
            userType.value = 1;
          }else if (resp.data.businessUserType == 'ESTATE') {
            userType.value = 2;
          } else if (resp.data.businessUserType == 'PARTNER') {
            userType.value = 4;
          }
        }
        userId.value = resp.data.businessUserId;
        if (type.value==="0"){
          applyPage(params).then((res) => {
            console.log("applyPage", res);
            if (res.code === 200) {
              res.rows.forEach(it => {
                if (it.applyNo === params.applyNo) {
                  data.value = it
                }
              })
              dataShow.value = true;
            }
          });
        }else if (type.value==="1"){
          checkPage(params).then((res) => {
            console.log("checkPage", res);
            if (res.code === 200) {
              res.rows.forEach(it => {
                if (it.applyNo === params.applyNo) {
                  data.value = it
                }
              })
              dataShow.value = true;
            }
          });
        }
      })
    });

    const getDesc = (row) => {
      let status = row.applyStatus;
      if (status == 10) {
        return "待代理商审核";
      } else if (status == 20) {
        return "代理商拒绝审核";
      } else if (status == 30) {
        return "待核账";
      } else if (status == 40) {
        return "待打款";
      } else if (status == 50) {
        return "成功";
      } else if (status == 60) {
        return "核账失败";
      } else if (status == 61) {
        return "运营商拒绝";
      } else if (status == 70) {
        return "撤销";
      } else {
        return "未知";
      }
    };
    return {
      userType,
      userId,
      dataShow,
      route,
      type,
      data,
      getDesc,
      cancel,
      agree,
      refuse,
      show,
      reason,
      refuseID,
      onConfirm,
      showConfirmButton,
      beforeClose,
      onClickValidate,
      handleEntPayModalOpen,
      handleRefuseApplyModalOpen,
    };
  },
};
</script>

<style>
.validate-message {
  color: red;
  font-size: var(--van-cell-font-size);
  adding-left: 22px;
}
.bottom_btn {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
