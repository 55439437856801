<template>
  <div class="container">
    <van-list
        :loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="init"
      >
        <div class="h32"></div>
          <div class="item"  v-for="item in listData" :key="item.deviceSn">
          <div class="outer">
            <div class="left">
              <div class="deviceName">{{item.deviceName}}</div>
              <div class="deviceSn">{{item.deviceSn}}</div>
            </div>
            <div class="right">
              <van-tag type="danger" size="medium">欠费</van-tag>
            </div>

          </div>
          <div class="outer">
            <div class="left">
              <div class="deviceSn">到期时间{{item.serviceEndTime}}</div>
            </div>
            <div class="right">
              <div class="date">剩余{{item.leftDay?item.leftDay:0}}天</div>
            </div>
          </div>
        </div>
      </van-list>
      <div class="bottom-btn">
        <van-button round  type="success" block width="100%" class="submit" @click="submitAll">续费全部</van-button>

      </div>
  </div>
</template>
   
<script>
import {devicePage} from "@/api/device";

export default {
  data(){
    return{
      loading: false,
      finished: false,
      pageNum: 0,
      listData:[]
    }
  },
  methods:{
    submitAll(){
          this.$router.replace({path:"./ensurePay"})

    
      
    },
    init(){
      let params = {
        pageNum: this.pageNum + 1,
        pageSize: 10,
        deviceTypeSeparate: 0,
        renewal: 1,
        activeStatus: 1,
        renewalFlag: true
      }
      this.loading = true;
      devicePage(params).then(res => {
        if(res.code === 200){
           const list = res.rows || [];
        
           this.listData = this.listData.concat(list);
           if(this.listData.length < res.total){
             this.finished = false;
             this.pageNum = this.pageNum + 1;
           }else{
             this.finished = true;
           }
           this.loading = false;
        }
       
        
      })
    }
  },
  mounted(){

  }
}
</script>

<style lang="scss" scoped>
.container{
  height: 100vh;
  padding-bottom: 80px;
  background-color: #F5f5f5;
  .bottom-btn{
    position: fixed;
    bottom: 12px;
    left: 50%;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    margin-left: -150px;
    width: 300px;
   
  }
  .h32{
    height: 16px;
  }
  .item{
    margin: 0 12px 12px 12px;
    border-radius: 8px;
    padding: 12px;
    font-size: 12px;
    color: #333;
    background-color: #fff;
    .outer{
      display: flex;
      justify-content: space-between;
      font-size: 13px;
        color: #666666;
      .left{
       .deviceName{
         font-size: 16px;
          color: #333333;
          font-weight: 500;
          width: 270px;
          height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
       }
       .deviceSn{
        font-size: 13px;
        color: #666666;
        margin-top: 4px;
       }
        
      }
      .right{
        
      }
    }
    
    
  }
  .delDes{
    text-align: center;
    line-height: 44px;
    height: 44px;
  }
  .add{
    position: fixed;
    right: 24px;
    bottom: 68px;
  }
}
</style>