<template>
  <div class="container">
    <Search @search="onSearch" @update:model-value="onInputChange" showAction>
      <template #action>
        <div style="line-height: 0">
          <van-button size="small" plain hairline type="primary" @click="show = true;">筛选</van-button>
        </div>
      </template>
    </Search>
    <div class="statistic">
      <div class="statistic-text">
        订单总数(笔)： {{ totalOrderCount }}
        订单总额(元)： {{  totalOrderAmount ? (totalOrderAmount / 100).toFixed(2) : 0.00 }}
      </div>
      <div class="statistic-text">
        总消耗电量(度)：{{ totalOrderEnergy ? (totalOrderEnergy).toFixed(4): 0.00 }}
        我的分成(元)： {{ totalPlatformShareAmount ? (totalPlatformShareAmount/ 100).toFixed(2): 0.00 }}
      </div>
    </div>
    <ListPage @register="register" style="padding:0 12px 12px 12px">
      <template #item="{dataList}">
        <div @click="toDetail(item)" class="order-item" :key="index" v-for="(item,index) in dataList">
          <div class="order-item-title">
            订单号：{{ item.orderNo }}
            <Tag plain type="success" size="large" style="background-color:#f759ab;color: #ecf9ff"
                 v-if="item.orderStatus==6">{{ "充电异常" }}
            </Tag>
            <Tag plain type="success" size="large" style="background-color:#bfbfbf;color: #ecf9ff"
                 v-else-if="item.orderStatus==5">{{ "已取消" }}
            </Tag>
            <Tag plain type="success" size="large" style="background-color:#5cdbd3;color: #ecf9ff"
                 v-else-if="item.orderStatus==4">{{ "暂停中" }}
            </Tag>
            <Tag plain type="success" size="large" style="background-color:#00AC50;color: #ecf9ff"
                 v-else-if="item.orderStatus==3">{{ "充电完成" }}
            </Tag>
            <Tag plain type="success" size="large" style="background-color:#40a9ff;color: #ecf9ff"
                 v-else-if="item.orderStatus==2">{{ "充电中" }}
            </Tag>
            <Tag plain type="success" size="large" style="background-color:#adc6ff;color: #ecf9ff"
                 v-else-if="item.orderStatus==1">{{ "网络异常" }}
            </Tag>
            <Tag plain type="success" size="large" style="background-color:#d3adf7;color: #ecf9ff"
                 v-else-if="item.orderStatus==0">{{ "待支付" }}
            </Tag>
          </div>
          <div class="order-item-c">
            <div>
              <div class="order-item-content">
                {{ item.apartmentName }}
              </div>
              <div class="order-item-content">
                {{ item.createTime }}
              </div>
            </div>
            <div style="font-size: 16px;font-weight: 500;margin-top: 10px">
              {{ item.realAmount / 100 }}元
            </div>
          </div>
        </div>
      </template>
    </ListPage>
    <Popup position="right" style="height: 100%;width: 80%" v-model:show="show">
      <Form @submit="onSubmit">
        <CellGroup inset>
          <Field
              v-model="phoneNo"
              name="phoneNo"
              label="手机号"
              placeholder="请输入用户号码"
          />
          <Field
              v-model="cardNo"
              name="cardNo"
              label="电卡卡号"
              placeholder="请输入卡号"
          />
          <FieldPopup label="设备类型" show-text="text" back-val="type"
                      :data="deviceTypeSeparateArr" placeholder="请选择设备类型" @change="changeTypeState"/>
          <FieldPopup label="订单状态" show-text="text" back-val="state"
                      :data="orderStatusTypeArr" placeholder="请选择订单状态" @change="changeStatusState"/>
          <FieldPopup label="充电站" show-text="text" back-val="id"
                      :data="chargingStationTypeArr" placeholder="请选择充电站" @change="changeStationState"/>
          <van-cell title="选择日期区间" :value="date" @click="showDate = true"/>
          <van-calendar v-model:show="showDate" type="range" @confirm="onConfirm" :min-date="minDate"
                        allow-same-day="true"
          />
        </CellGroup>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit" @click="searchClick">
            搜索
          </van-button>
        </div>
      </Form>
    </Popup>

  </div>
</template>

<script>
import {useRouter} from "vue-router";
import {onMounted, ref} from 'vue';
import {Calendar, CellGroup, Divider, Field, Form, NoticeBar, Popup, Search, Tag} from 'vant';
import {ListPage, useListPage} from "@/components/ListPage";
import FieldPopup from "@/components/FieldPopup";
import {listStation, statOrderList} from "@/api/order";
import {formatTimeToStr} from "@/api/dateFormat";

export default {
  components: {
    NoticeBar,
    ListPage,
    Popup,
    Divider,
    Form,
    Field,
    CellGroup,
    FieldPopup,
    Calendar,
    Tag,
    Search
  },
  emits: ['search'],
  setup(props, {emit}) {
    const chargingStationTypeArr = [];
    const deviceTypeSeparateArr = [{
      text: "两轮电动车充电桩",
      type: 0
    },
      {
        text: "汽车充电桩",
        type: 1
      }];
    const orderStatusTypeArr = [
      {
        state: 0,
        text: "待支付"
      },
      {
        state: 1,
        text: "网络异常"
      },
      {
        state: 2,
        text: "充电中"
      }, {
        state: 3,
        text: "充电完成"
      }, {
        state: 4,
        text: "暂停中"
      }, {
        state: 5,
        text: "已取消"
      }, {
        state: 6,
        text: "充电异常"
      }
    ];
    const querySearch = ref();
    const startTime = ref()
    const endTime = ref()
    const stationState = ref();
    const statusState = ref();
    const deviceTypeSeparate = ref();
    const router = useRouter();
    const show = ref(false)
    const showDate = ref(false)
    const date = ref('')
    const value = ref('');
    const totalOrderAmount = ref(0);
    const totalOrderEnergy = ref(0);
    const totalPlatformShareAmount = ref(0);
    const totalOrderCount = ref(0);
    const cardNo = ref();
    const phoneNo = ref();
    const orderStatus = ref();
    const orderStatusType = ref({});
    const chargingStationType = ref('');
    const apiUrl = '/agent/order/page';
    const [register, {setProps, queryData, resetData}] = useListPage({
      apiUrl: apiUrl,
      requestParam: {}
    });

    onMounted(() => {
      startTime.value = `${formatTimeToStr(new Date(new Date().getFullYear(), new Date().getMonth(), 1), "yyyy-MM-dd 00:00:00")}`;
      endTime.value = `${formatTimeToStr(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), "yyyy-MM-dd 23:59:59")}`;
      date.value = startTime.value + ` ` + endTime.value
      let pageSize = 10;
      setProps({
        apiUrl,
        pageSize,
        requestParam: {
          minOrderFinishTime: startTime,
          maxOrderFinishTime: endTime
        }
      });
      resetData();
    });
    const searchClick = () => {
      let pageSize = 10;
      setProps({
        apiUrl,
        pageSize,
        requestParam: {
          memberPhone: phoneNo,
          cardNo: cardNo,
          apartmentId: stationState,
          orderStatus: statusState,
          minOrderFinishTime: startTime,
          maxOrderFinishTime: endTime,
          deviceTypeSeparate: deviceTypeSeparate
        }
      });
      show.value = false;
      resetData();
      statOrderList({
        memberPhone: phoneNo.value,
        cardNo: cardNo.value,
        apartmentId: stationState.value,
        orderStatus: statusState.value,
        minOrderFinishTime: startTime.value,
        maxOrderFinishTime: endTime.value,
        deviceTypeSeparate: deviceTypeSeparate.value
      }).then(response => {
        totalOrderAmount.value = response.data.totalOrderAmount
        totalOrderEnergy.value = response.data.totalOrderEnergy
        totalPlatformShareAmount.value = response.data.totalPlatformShareAmount
        totalOrderCount.value = response.data.totalOrderCount
      })
    }

    const changeTypeState = (value) => {
      deviceTypeSeparate.value = value
      statOrderList({
        cardNo: cardNo.value,
        apartmentId: stationState.value,
        orderStatus: statusState.value,
        minOrderFinishTime: startTime.value,
        maxOrderFinishTime: endTime.value,
        deviceTypeSeparate: deviceTypeSeparate.value
      }).then(response => {
        totalOrderAmount.value = response.data.totalOrderAmount
        totalOrderEnergy.value = response.data.totalOrderEnergy
        totalPlatformShareAmount.value = response.data.totalPlatformShareAmount
        totalOrderCount.value = response.data.totalOrderCount
      })
    }

    const changeStationState = (value) => {
      stationState.value = value
      statOrderList({
        cardNo: cardNo.value,
        apartmentId: stationState.value,
        orderStatus: statusState.value,
        minOrderFinishTime: startTime.value,
        maxOrderFinishTime: endTime.value
      }).then(response => {
        totalOrderAmount.value = response.data.totalOrderAmount
        totalOrderEnergy.value = response.data.totalOrderEnergy
        totalPlatformShareAmount.value = response.data.totalPlatformShareAmount
        totalOrderCount.value = response.data.totalOrderCount
      })
    }

    const changeStatusState = (value) => {
      statusState.value = value
      statOrderList({
        cardNo: cardNo.value,
        apartmentId: stationState.value,
        orderStatus: statusState.value,
        minOrderFinishTime: startTime.value,
        maxOrderFinishTime: endTime.value
      }).then(response => {
        totalOrderAmount.value = response.data.totalOrderAmount
        totalOrderEnergy.value = response.data.totalOrderEnergy
        totalPlatformShareAmount.value = response.data.totalPlatformShareAmount
        totalOrderCount.value = response.data.totalOrderCount
      })
    }

    const onConfirm = (values) => {
      const [start, end] = values;
      showDate.value = false;
      startTime.value = `${formatTimeToStr(start, "yyyy-MM-dd 00:00:00")}`;
      endTime.value = `${formatTimeToStr(end, "yyyy-MM-dd 23:59:59")}`;
      date.value = startTime.value + ` ` + endTime.value

    };

    const onSearch = (val) => {
      let pageSize = 10;
      statOrderList({orderNo: querySearch.value}).then(response => {
        if (response.data.totalOrderCount!==0){
          setProps({apiUrl, pageSize, requestParam: {orderNo: querySearch.value}});
          resetData();
          totalOrderAmount.value = response.data.totalOrderAmount
          totalOrderEnergy.value = response.data.totalOrderEnergy
          totalPlatformShareAmount.value = response.data.totalPlatformShareAmount
          totalOrderCount.value = response.data.totalOrderCount
        }else {
          setProps({apiUrl, pageSize, requestParam: {minOrderFinishTime: startTime, maxOrderFinishTime: endTime}});
          resetData();
        }
      })
      emit('search', val)
    };

    const onInputChange = (val) => {
      querySearch.value=val;
    }

    const onSubmit = () => {
    }

    statOrderList({
      minOrderFinishTime: `${formatTimeToStr(new Date(new Date().getFullYear(), new Date().getMonth(), 1), "yyyy-MM-dd 00:00:00")}`,
      maxOrderFinishTime: `${formatTimeToStr(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), "yyyy-MM-dd 23:59:59")}`
    }).then(response => {
      totalOrderAmount.value = response.data.totalOrderAmount
      totalOrderEnergy.value = response.data.totalOrderEnergy
      totalPlatformShareAmount.value = response.data.totalPlatformShareAmount
      totalOrderCount.value = response.data.totalOrderCount
    })

    listStation({pageNum:1,pageSize:10000}).then(response => {
      for (let i = 0; i < response.rows.length; i++) {
        const element = response.rows[i];
        element.text = element.apartmentName;
        chargingStationTypeArr.push(element)
      }
    })

    const toDetail = (item) => {
      console.log(item)
      router.push({path: '/order/detail', query: {item: JSON.stringify(item)}})
    }


    return {
      minDate: new Date(1),
      deviceTypeSeparate,
      startTime,
      endTime,
      cardNo,
      phoneNo,
      show,
      stationState,
      statusState,
      showDate,
      date,
      register,
      value,
      onSearch,
      onInputChange,
      onSubmit,
      onConfirm,
      toDetail,
      totalOrderAmount,
      totalOrderCount,
      totalOrderEnergy,
      totalPlatformShareAmount,
      deviceTypeSeparateArr,
      chargingStationTypeArr,
      chargingStationType,
      orderStatusTypeArr,
      orderStatusType,
      orderStatus,
      searchClick,
      changeStationState,
      changeStatusState,
      changeTypeState
    };
  },
}

</script>

<style scoped>
.container {
  background-color: #f5f5f5;
}

.statistic {
  background-color: #ecf9ff;
  padding: 8px
}

.statistic-text {
  font-size: 14px;
  color: #333333
}

.order-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;
  height: 96px;
  background: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 8px;
}

.order-item-title {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.order-item-content {
  font-size: 13px;
  color: #999999;
  margin-top: 5px;
}

.order-item-c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
</style>