<template>
  <div>
    <PullRefresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
      <List
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          v-if="!!list "
      >
        <div v-for="(item, index) in list" :key="index" style="margin-top: 12px" @click="clickItem(item,index)">
          <!-- <Icon name="passed" color='rgb(8, 223, 134)' size="25" style="margin:15px 20px"
                v-if='icon ===index'
          /> -->
          <div
              class="item-content"
              :class="icon === index ? 'item-content-checked' : ''"
          >

            <div class="item-name">{{ item ? `手机号：${item.phone}` : null }}</div>
            <div class="item-phone">{{ item ? `昵称：${item.nickname}` : null }}</div>
            <div class="item-phone">
              <span>{{ item ? `账户余额：${item.account.userAmount / 100}` : null }}</span>
              <span style='margin-left:30px'>{{ item ? `赠送余额：${item.account.presentAmount / 100}` : null }}</span>
            </div>
          </div>
        </div>
      </List>
      <Empty description="暂时没有数据" v-if="(!list || list.length === 0) && !loading"/>
            <div class='button-wrapper' @click=handleSurplus() v-if="isOperatorShow">
              <van-button v-if="visible" type="primary" block class='button'>余额增减</van-button>
            </div>
    </PullRefresh>
  </div>
  <Dialog v-model:show="show" title="标题" show-cancel-button>
  </Dialog>
</template>

<script lang="jsx">
import {ref} from 'vue';
import {Card, Cell, CellGroup, Dialog, Empty, Field, Icon, List, PullRefresh} from 'vant';
import {queryPhoneList, reloadPhoneBalance} from '@/api/consumer'
import {onMounted} from "@vue/runtime-core";
import {getAgentInfo} from "@/api/stat";

export default {
  props: ['searchContent'],
  components: {
    List,
    Cell,
    Card,
    Field,
    CellGroup,
    Icon,
    PullRefresh,
    Empty,
    [Dialog.Component.name]: Dialog.Component,
  },

  setup() {
    const isOperatorShow = ref(false);
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const visible = ref(false);
    const show = ref(false);
    const totalNum = ref(0);
    const icon = ref(-1)
    const itemChecked = ref([]);
    const params = {
      pageSize: 10,
      pageNum: 0
    };

    onMounted(() => {
      getAgentInfo().then(res => {
        if (res.data.isOperator) {
          isOperatorShow.value = true;
        }
      })
    });

    let queryList = (params) => {
      queryPhoneList(params).then(res => {
        totalNum.value = res.total
        list.value = list.value.concat(res.rows)

        loading.value = false;
        if (list?.value.length >= totalNum?.value) {
          finished.value = true;
        }
      }).catch(err => {
      })
    }

    const onLoad = () => {
      setTimeout(() => {
        if (refreshing.value) {
          refreshing.value = false;
        }
        params.pageNum += 1;
        queryList(params)
      }, 300);
    };

    const onRefresh = (arg) => {
      visible.value = false
      icon.value = -1

      // 清空列表数据
      finished.value = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      if (!arg) {
        list.value = []
        params.pageNum = 0
        onLoad();
      } else {

        if (arg.length === 11) {
          list.value = []
          let params = {phone: arg}
          queryList(params)
        }
      }
    };

    const clickItem = (item, index) => {
      visible.value = true
      itemChecked.value = item
      icon.value = index
    }

    const handleSurplus = () => {
      show.value = true
      let inputContent = ['', '']
      const item = itemChecked.value
      Dialog.confirm({
        messageAlign: 'left',
        message: () => {
          return (
              <div>
                <div style='margin-top:10px'>手机号：{item.phone}</div>
                <div style='margin-top:10px'>账户余额：
                  <input id='account'
                         style='border:0px; border-bottom:0.5px gray solid;margin-left:10px;width:100px; text-align:center '
                         autocomplete="off"
                         type='number'
                      // value={item?.account?.userAmount / 100}
                         value={0}
                         onBlur={(e) => {
                           inputContent[0] = Number(e.target.value)
                         }}/> 元
                </div>
                <div style='margin-top:10px'>赠送余额：
                  <input
                      style='border:0px ; border-bottom:0.5px gray solid;margin-left:10px;width:100px; text-align:center'
                      autocomplete="off"
                      type='number'
                      // value={item?.account?.presentAmount / 100}
                      value={0}
                      onBlur={(e) => {
                        inputContent[1] = Number(e.target.value)
                      }}
                  /> 元
                </div>
              </div>
          )
        },
      })
          .then(() => {
            // on confirm
            visible.value = false
            icon.value = -1

            if (inputContent[0] === '') {
              inputContent[0] = 0;
            }
            if (inputContent[1] === '') {
              inputContent[1] = 0;
            }
            let data = {
              accountNo: item.account.accountNo,
              giftBalance: inputContent[1] * 100,
              memberId: item.userId,
              realBalance: inputContent[0] * 100
            }
            reloadPhoneBalance(data).then(res => {

              if (res.code === 200) {
                item.account.userAmount = item.account.userAmount + inputContent[0] * 100
                item.account.presentAmount = item.account.presentAmount + inputContent[1] * 100
              }

            }).catch(() => {
            })

          })
          .catch(() => {
            // on cancel
            visible.value = false
            icon.value = -1
          });
    }

    return {
      list,
      onLoad,
      icon,
      loading,
      finished,
      onRefresh,
      refreshing,
      clickItem,
      handleSurplus,
      visible,
      show,
      itemChecked,
      isOperatorShow
    }
  }

}
</script>

<style lang="scss" scoped>
.item-content {
  position: relative;
  padding: 12px;
  margin: 0 12px 12px 12px;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 25%);
  border-radius: 5px;
  /* border-bottom: 0.5px solid rgb(196, 191, 191); */
  .item-name {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
  }

  .item-phone {
    font-size: 13px;
    color: #666666;
    margin-top: 8px;
  }
}

.item-content-checked {
  background-color: #00d655;

  .item-name {
    color: #fff;
  }

  .item-phone {
    color: #fff;
  }
}

.custom-title {
  vertical-align: middle;
  margin: 5px 0 5px 0;
}

.cell-wrapper {
  display: flex;
}

.button-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99
}

.button {
  background-color: rgb(8, 223, 134);
  border: 0px black solid;
  color: white;
}

</style>