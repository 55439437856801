<template>
  <div>
    <Form @submit="onSubmit">
      <CellGroup inset>
        <Field
            label-width="130"
            v-model="apartmentName"
            name="apartmentName"
            label="充电站名称"
            placeholder="请输入充电站名称"
            required="true"
            :rules="[{ required: true, message: '请填写充电站名称' }]"
        />
        <Field
            v-model="result"
            label-width="130"
            is-link
            readonly
            name="area"
            label="充电站地址"
            placeholder="点击选择省市区"
            required="true"
            @click="showArea = true"
            :rules="[{ required: true, message: '请选择省市区' }]"
        />
        <van-popup v-model:show="showArea" position="bottom">
          <van-area
              :area-list="areaList"
              @confirm="onConfirmArea"
              @cancel="showArea = false"
          />
        </van-popup>
        <Field
            v-model="address"
            name="address"
            label="详细地址"
            placeholder="请输入详细地址"
            required="true"
            label-width="130"
            :rules="[{ required: true, message: '请输入详细地址' }]"
        />
        <div style="height: 44px;margin: 12px;">
          <van-button style="float: right;width: 100%;" plain icon="location" type="success" @click="positon">定位到此地址</van-button>
        </div>
        <FieldPopup label="充电站物业" show-text="text" back-val="id" :data="estateData" @change="changeEstate"/>
<!--        <FieldPopup label="充电站分类" show-text="text" back-val="regionId" :data="regionList" @change="changeRegion"/>-->
        <Field
            v-model="managerUsername"
            name="managerUsername"
            label="充电站联系人"
            label-width="130"
            placeholder="请输入充电站联系人"
        />
        <van-field
            v-model="managerPhone"
            name="managerPhone"
            label="充电站站内联系电话"
            label-width="130"
            placeholder="请输入充电站站内联系电话"
        />
        <van-field
            v-model="hotLine"
            name="hotLine"
            label="充电站服务电话"
            label-width="130"
            placeholder="请输入充电站服务电话"
        />
        <van-field
            v-model="description"
            name="description"
            label="备注"
            placeholder="请输入备注"
            label-width="130"
        />
        <div id="map"></div>
      </CellGroup>
      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit" color="#07c160">
          保存
        </van-button>
        <van-button block type="default" @click="cancel">
          取消
        </van-button>
      </div>
    </Form>
  </div>
</template>

<script>
import {getCurrentInstance, ref} from 'vue';
import {areaList} from '@vant/area-data';
import {CellGroup, Dialog, Field, Form, Notify, Toast} from 'vant';
import FieldPopup from "@/components/FieldPopup";
import {onMounted} from "@vue/runtime-core";
import {addStation, listEstate, listRegion} from '@/api/station'
import {useRoute,useRouter} from "vue-router";
import {wxConfig} from "@/utils/wx";
import {getLocation} from "@/api/public";
let MultiMarker,map
export default {
  components: {
    Form, Field, CellGroup, FieldPopup
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute()
    const stationKindType = ref(JSON.parse(route.query.stationKindType));
    const router = useRouter()
    const result = ref('');
    const showArea = ref(false);
    const apartmentName = ref('');
    const address = ref('');
    const managerUsername = ref('');
    const managerPhone = ref('');
    const hotLine = ref('');
    const description = ref('');
    const regionList = ref([]);
    const estateData = ref([]);
    const showPicker = ref(false);
    const provinceCode = ref('');
    const provinceName = ref('');
    const cityCode = ref('');
    const cityName = ref('');
    const countyCode = ref('');
    const countyName = ref('');
    const estate = ref({});
    const estateId = ref();
    const estateName = ref('');
    const regionId = ref(0);
    const simpleLocation=ref({})

    const changeEstate = (id) => {
      estate.value = null;
      estateId.value = null;
      estateName.value = '';
      estateData.value.forEach(function (value, index, array) {
        if (id == value.id) {
          estate.value = value;
          estateId.value = value.id;
          estateName.value = value.text;
        }
      })

    }
    const changeRegion = (id) => {
      regionId.value = id;
    }

    onMounted(() => {
      // wxConfig(proxy.$wx, () => {
      //   proxy.$wx.getLocation({
      //     debug: true,
      //     type: 'gcj02',
      //     success: function(res) {
      //       initMap(res.longitude,res.latitude)
      //     },
      //     error:function (res){
      //       initMap(0,0)
      //     }
      //   });
      // });
      initMap(116.41624,39.92835)
      listRegion().then(res => {
        regionList.value = res.rows;
        for (let i = 0; i < regionList.value.length; i++) {
          regionList.value[i].text = regionList.value[i].regionName;
        }
        listEstate({pageSize: 10000, pageNum: 0}).then(response => {
          const estateList = response.rows;
          estateList.forEach(item => {
            estateData.value.push({
              "id": item.id,
              "text": item.username
            })
          })
        });
      })
    })
    const onConfirmArea = (areaValues) => {
      provinceCode.value = areaValues[0].code;
      provinceName.value = areaValues[0].name;
      cityCode.value = areaValues[1].code;
      cityName.value = areaValues[1].name;
      countyCode.value = areaValues[2].code;
      countyName.value = areaValues[2].name;
      showArea.value = false;
      result.value = areaValues
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
      positon();
    };

    const cancel = () => {
      router.push({path: '/rechargeStation'})
    };

    const initMap = (x, y) => {
      let info = document.getElementById("map");
      let center = new window.TMap.LatLng(y, x);
      //初始化地图
      map = new window.TMap.Map(info, {
        zoom: 17.2, //设置地图缩放级别
        center: center //设置地图中心点坐标
      });
      makeMapInfo(x, y);
    };
    const makeMapInfo = (x, y) => {
      let tencentmap=map;
      let e = new window.TMap.LatLng(y, x)

      //监听点击事件添加marker
      MultiMarker = new window.TMap.MultiMarker({
        id: 'marker-layer',
        styles: {
          'highlight': new window.TMap.MarkerStyle({
            'src': 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png'
          })
        },
        map: tencentmap
      })
      const point = {
        id: new Date(),
        position: e
      }

      MultiMarker.setGeometries([])
      MultiMarker.add(point)
    };
    const positon=async()=>{
      let addressList = "";
      if (!provinceName.value || !cityName.value || !countyName.value) {
        Toast('请选择省市区');
        return;
      }
      addressList = provinceName.value + cityName.value + countyName.value + address.value;
      let params = {
        key: 'O7GBZ-P7GKX-DBT4Q-7CFKN-JL2NJ-NWBG2',
        address: addressList,
        output: 'jsonp'
      }
      const data = await getLocation(params)
      map.setCenter(new window.TMap.LatLng(data.result.location.lat, data.result.location.lng))
      map.setZoom('17.2')
      simpleLocation.value =
          {
            y: Number(data.result.location.lat.toFixed(5)),
            x: Number(data.result.location.lng.toFixed(5))
          }
      const point = {
        id: new Date(),
        position: new window.TMap.LatLng(data.result.location.lat, data.result.location.lng)
      }
      MultiMarker.setGeometries([])
      MultiMarker.add(point)
    }

    const onSubmit = (values) => {
      values.provinceCode = provinceCode.value;
      values.provinceName = provinceName.value;
      values.cityCode = cityCode.value;
      values.cityName = cityName.value;
      values.countyCode = countyCode.value;
      values.countyName = countyName.value;
      values.estate = estate.value;
      values.estateId = estateId.value;
      values.estateName = estateName.value;
      values.regionId = regionId.value;
      values.estate = {
        username: estateName.value,
        id: estateId.value
      }
      values.stationKindType = stationKindType.value;
      values.description = description.value;
      values.simpleLocation = simpleLocation.value;
      values.simplePoint = simpleLocation.value
      if (JSON.stringify(values.simplePoint)=="{}"){
        Toast('请点击定位获取地址信息');
        return;
      }else if (values.simplePoint.x==0||values.simplePoint.y==0){
        Toast('设备定位信息有误,请点击定位获取地址信息');
        return;
      }
      addStation(values).then(result => {
        if (result.code == 200) {
          // 成功通知
          Notify({type: 'success', message: '新增电站成功'});
          Dialog.alert({
            title: '充电站创建成功',
            message: '请登录网页端完善充电站配置及价格信息后使用。',
            theme: 'round-button',
          }).then(() => {
            // on close
            router.push({path: `/rechargeStation`});
          });
        } else {
          Notify({type: 'danger', message: '新增电站失败'});
        }
      })
    };

    return {
      apartmentName,
      onSubmit,
      showPicker,
      onConfirmArea,
      result,
      showArea,
      areaList,
      address,
      managerUsername,
      managerPhone,
      hotLine,
      description,
      regionList,
      estateData,
      cancel,
      changeEstate,
      changeRegion,
      positon
    };
  },
}
</script>

<style>
#map{
  height: 300px;
}
</style>