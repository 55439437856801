import request from '../utils/request.js'

// 查询订单统计
export function statOrderList(params) {
    return request({
        url: '/stat/orderList/numeric',
        method: 'get',
        params
    })
}



// 查询充电站列表
export function listStation(query) {
    return request({
        url: '/device/station/list',
        method: 'get',
        params: query
    })
}

//查看设备分润信息
export function equipmentProfit(deviceSn) {
    return request({
        method: 'get',
        url: `agent/order/detail/${deviceSn}`,
    })
}

export function getOperatorName(channelId) {
    return request({
        method: 'get',
        url: `/agent/order/operatorName/`+ channelId,
    })
}
// 续费全部 提交
export function renewalAll (data) {
  return request({
      method: 'post',
      url: `/agent/order/renewal`,
      data: data
  })
}