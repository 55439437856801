<template>
  <div class="container">
    <van-form input-align="right">
      <van-cell-group inset>
        <van-field
          v-model="item.deviceSn"
          label="充电桩编号"
          disabled="true"
        />
        <van-field
          v-model="item.deviceName"
          label="充电桩名称"
          placeholder="充电桩名称"
          :rules="[{ required: true, message: '请填写充电桩名称' }]"
        />
        <van-field
          v-model="item.apartmentName"
          label="充电站"
          is-link
          readonly
          @click="loadStation"
        />
        <van-field
          is-link
          readonly
          v-model="item.provinceName"
          label="省"
          @click="showProvince"
        />
        <van-field
          is-link
          readonly
          v-model="item.cityName"
          label="市"
          placeholder="请选择市"
          @click="showCity"
        />
        <van-field
          is-link
          readonly
          v-model="item.countyName"
          label="区"
          placeholder="请选择区"
          @click="showArea"
        />
        <van-field
          v-model="item.address"
          label="详细地址"
          :rules="[{ required: true, message: '请填写详细地址' }]"
          @change="addressChange"
        />
        <div style="height: 44px;margin: 12px;">
            <van-button style="float: right;width: 100%;" plain icon="location" type="success" @click="positon" native-type="submit">定位到此地址</van-button>
        </div>
        <div id="map"></div>
       
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit" @click="submit">
          提交
        </van-button>
      </div>
    </van-form>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="stationList"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model:show="showProvinceFlag" position="bottom">
      <van-picker
      title="省/自治区/直辖市"
      :columns="provinceNameList"
      @cancel="showProvinceFlag = false"
      @confirm="confirmProvince"
      />
    </van-popup>
    <van-popup v-model:show="showCityFlag" position="bottom">
      <van-picker 
      title="市" 
      :columns="cityNameList" 
      @cancel="showCityFlag = false" 
      @confirm="confirmCity" />
    </van-popup>
    <van-popup v-model:show="showAreaFlag" position="bottom">
      <van-picker 
      title="区" 
      :columns="areaNameList" 
      @cancel="showAreaFlag = false" 
      @confirm="confirmArea" />
    </van-popup>

  </div>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import { unref, onMounted, nextTick} from 'vue';

import { ref } from 'vue';
import {Notify, Toast} from 'vant';
import {getStationTpl, listStation, updateDevice} from "@/api/device";
import { areaList } from '@vant/area-data';
let TMap, map, marker;
import {listCityList, listCountyList, listProvinceList,getLocation} from '@/api/public.js'
import router from "@/router";


export default {
  data(){
    return{
      map: null,
      MultiMarker: null,
      geometriesList: [],
      setInterval: null, // 定时器
      showProvinceFlag: false,
      provinceList:[],
      provinceNameList:[],
      provinceCode: "",
      showCityFlag: false,
      cityList:[],
      cityNameList:[],
      cityCode: "",
      showAreaFlag: false,
      areaList:[],
      areaNameList:[],
      areaCode: "",
      simpleLocation:{}
    }
  },
  setup() {
    const router = useRouter();
    const route = useRoute()
    const item = ref(JSON.parse(route.query.item));
    const stationList = ref([])
    const stationData = ref([])
    const showPicker = ref(false);
    const apartmentId = ref(0)
    const loadStation =()=>{
      let stationKindType;
      if (item.value.deviceTypeName=="汽车充电桩"){
        stationKindType=1;
      }else if (item.value.deviceTypeName=="两轮电动车充电桩"){
        stationKindType=0;
      }
      let data = {
        pageNum: 1,
        pageSize: 1000,
        stationKindType:stationKindType
      }
      listStation(data).then(res => {
        stationData.value = res.rows;
        let rows = res.rows;
        let list = rows.map((val)=>{
          return val.apartmentName
        })
        stationList.value = list;
        showPicker.value = true;
      })
    }
   
    const onConfirm = (val) => {
      stationData.value.map((item) => {
        if(val === item.apartmentName){
          apartmentId.value = item.id;
        }
      })
      item.value.apartmentName = val;
      item.value.apartmentId = apartmentId.value;
      showPicker.value = false;

    }
    return {
      item,
      stationList,
      showPicker,
      loadStation,
      onConfirm,
      apartmentId,
      stationData,
      router,
    };
  },
  mounted(){
    let val = JSON.parse(this.$route.query.item);
    this.initMap(val.x,val.y);
  },
  methods:{
    confirmProvince(val){
      //过滤 provinceCode
      this.provinceList.map((item) => {
        if(item.name === val){
          this.provinceCode = item.code;
        }
      })
      //如果 更改了省 对应的 市区 信息清空
      if(val !== this.item.provinceName){
        this.item.provinceName = val;
        this.item.cityName = "";
         this.item.countyName = "";
      }
      this.showProvinceFlag = false;
      this.positon();
    },
    showProvince (){
      //查询省列表
      listProvinceList().then(res => {
        let backList = res.data;
        this.provinceList = res.data
        let list = backList.map((item)=>{
          return item.name
        })
        this.provinceNameList = list;
       this.showProvinceFlag = true;
      })
    },
    confirmCity(val){
      //过滤 cityCode
      this.cityList.map((item) => {
        if(item.name === val){
          this.cityCode = item.code;
        }
      })
      //如果 更改了省 对应的 市区 信息清空
      if(val !== this.item.cityName){
        this.item.cityName = val;
         this.item.countyName = "";
      }
      this.showCityFlag = false;
      this.positon();
    },
    showCity(){
      let provinceCode = this.provinceCode ? this.provinceCode : this.item.provinceCode;
      //查询市列表
      listCityList({provinceCode}).then(res => {
        let backList = res.data;
        this.cityList = res.data
        let list = backList.map((item)=>{
          return item.name
        })
        this.cityNameList = list;
       this.showCityFlag = true;
      })
    },
    confirmArea(val){
      //过滤 areaCode
      this.areaList.map((item) => {
        if(item.name === val){
          this.areaCode = item.code;
        }
      })
      //如果 更改了省 对应的 市区 信息清空
      if(val !== this.item.countyName){
        this.item.countyName = val;
      }
      this.showAreaFlag = false;
      this.positon();
    },
    addressChange(){
      this.positon();
    },
    showArea(){
      let cityCode = this.cityCode ? this.cityCode : this.item.cityCode;
      //查询区列表
      listCountyList({cityCode}).then(res => {
        let backList = res.data;
        this.areaList = res.data
        let list = backList.map((item)=>{
          return item.name
        })
        this.areaNameList = list;
       this.showAreaFlag = true;
      })
    },
    submit(){
      if(!this.item.cityName){
        Toast('请选择市');
        return;
      }
      if(!this.item.countyName){
        Toast('请选择区');
        return;
      }
      this.item.x=this.simpleLocation.x;
      this.item.y=this.simpleLocation.y;
      this.item.simplePoint=this.simpleLocation
      if (JSON.stringify(this.item.simplePoint)=="{}"){
        Toast('请点击定位获取地址信息');
        return;
      }else if (this.item.simplePoint.x==0||this.item.simplePoint.y==0){
        Toast('设备定位信息有误,请点击定位获取地址信息');
        return;
      }
      if (this.item.apartmentId!=null || this.item.apartmentId!=0){
        getStationTpl(this.item.apartmentId).then(res=>{
          if (res.data.id==null){
            Notify({type: 'danger', message: '该充电站尚未设置消费价格，请设置消费价格后再启用'});
            return
          }else {
            updateDevice(this.item).then(res => {
              if (res.code == 200) {
                Notify({ type: 'success', message: '修改成功' });
                this.$router.push({path: '/device/list'});
              }else {
                Notify({ type: 'danger', message: '修改失败' });
              }
            })
          }
        })
      }
      // let apartmentId = this.apartmentId?this.apartmentId:this.item.apartmentId;
      // let provinceCode = this.provinceCode ? this.provinceCode : this.item.provinceCode;
      // let provinceName = this.item.provinceName;
      // let cityCode = this.cityCode ? this.cityCode : this.item.cityCode;
      // let cityName = this.item.cityName;
      // let countryCode = this.areaCode ? this.areaCode : this.item.countyCode;
      // let countryName = this.item.countyName;
      // let address = this.item.provinceName+this.item.cityName+this.item.countyName+this.item.address;
      // let deviceSn = this.item.deviceSn;
      // let data = {
      //   apartmentId: apartmentId,
      //   deviceSn: deviceSn,
      //   provinceCode: provinceCode,
      //   provinceName: provinceName,
      //   cityCode: cityCode,
      //   cityName: cityName,
      //   countryCode: countryCode,
      //   countryName: countryName,
      //   address: address
      // }
      // console.log("充电站id",apartmentId)
      // console.log("充电桩名称",this.item.deviceName)
      // console.log("省市区code",provinceCode,cityCode,areaCode)
      // console.log("详细地址",address)
      // console.log("充电桩编号",this.item.deviceSn)


    },
    initMap(x,y){
      const that = this;
      let info = document.getElementById("map");
      let center = new window.TMap.LatLng(y,x);
      //初始化地图
      this.map = new window.TMap.Map(info, {
        zoom: 17.2, //设置地图缩放级别
        center: center //设置地图中心点坐标
      });
      this.makeMapInfo(x,y);
    },
    makeMapInfo(x,y){
      let tencentmap = this.map;
      const that = this;
      let info = document.getElementById("info");
      let e = new window.TMap.LatLng(y, x)

      //监听点击事件添加marker
      this.MultiMarker = new window.TMap.MultiMarker({
        id: 'marker-layer',
        styles: {
          'highlight': new window.TMap.MarkerStyle({
            'src': 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png'
          })
        },
        map: tencentmap
      })
      const point= {
        id: new Date(),
        position: e
      }

      this.MultiMarker.setGeometries([])
      this.MultiMarker.add(point)
    },
    async positon(){
      let address = "";
      if(!this.item.cityName){
        Toast('请选择市');
        return;
      }
      if(!this.item.countyName){
        Toast('请选择区');
        return;
      }
      address = this.item.provinceName+this.item.cityName+this.item.countyName+this.item.address;
      console.log(address)
      let params = {
        key: 'O7GBZ-P7GKX-DBT4Q-7CFKN-JL2NJ-NWBG2',
        address: address,
        output: 'jsonp'
      }
      const data = await getLocation(params)
      let tencentmap = this.map;
      this.map.setCenter(new window.TMap.LatLng(data.result.location.lat, data.result.location.lng))
      this.map.setZoom('17.2')
      this.simpleLocation =
        {
          y: Number(data.result.location.lat.toFixed(5)),
          x: Number(data.result.location.lng.toFixed(5))
        }
      const point = {
        id: new Date(),
        position: new window.TMap.LatLng(data.result.location.lat, data.result.location.lng)
      }
      this.MultiMarker.setGeometries([])
      this.MultiMarker.add(point)
    }
  }
};
</script>

<style scoped>
.container {
  background-color: #f5f5f5;
  position: relative;
  height: 100%;
}
.van-field__error-message{
  text-align: center;
}
.van-form{
  padding-top: 12px;
}
#map{
  height: 300px;
}

</style>