<template>
  <div>
    <List
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="queryData"
        v-if="dataList && dataList.length > 0"
    >
      <slot name="item" v-bind:dataList="dataList"></slot>
    </List>
    <Empty description="暂时没有数据" v-if="!dataList || dataList.length === 0"/>
  </div>
</template>

<script>
import {defineComponent, ref, watch, unref} from 'vue';
import {List, Empty} from 'vant';
import {loadData} from "@/api/common";

export default defineComponent({
  name: "ListPage",
  components: {
    List,
    Empty
  },
  emits: ['register'],
  props: {
    pageSize: {
      type: Number,
      default: 10
    },
    apiUrl: {
      type: String,
      default: ''
    },
    requestParam: {
      type: Object,
      default: {}
    }
  },
  setup(props, {emit}) {
    const loading = ref(false);
    const dataList = ref([]);
    const finished = ref(false);
    const pageNum = ref(0)
    const propData = ref({});

    const setProps = (data) => {
      const ret = {};
      Object.keys(unref(data)).map((key) => {
        ret[key] = data[key];
      });
      propData.value = ret;
    }
    const resetData = () => {
      pageNum.value = 0;
      dataList.value = [];
      finished.value = false;
      queryData({...unref(propData).requestParam});
    }

    const deleteItem = (key, value) => {
      dataList.value = dataList.value.filter(item => item[key] !== value);
    }

    const queryData = async (params) => {
      try {
        loading.value = true;
        const res = await loadData(unref(propData).apiUrl, {
          ...unref(propData).requestParam,
          pageNum: pageNum.value + 1,
          pageSize: unref(propData).pageSize
        });
        if (res) {
          const list = res.rows || [];
          if (pageNum.value === 0) {
            dataList.value = list;
          } else {
            dataList.value = dataList.value.concat(list);
          }

          if (dataList.value.length < res.total) {
            finished.value = false;
            pageNum.value = pageNum.value + 1;
            // this.status = 'loading';
          } else {
            finished.value = true;
          }
        }
      } finally {
        loading.value = false;
      }
    };

    const getAllData = () => {
      return dataList.value;
    }

    const getItem = (key, value) => {
      return dataList.value.find(item => item[key] === value)
    }

    const replaceItem = (index, newItem) => {
      dataList.value[index] = newItem;
    }

    const addItem = (newItem) => {
      dataList.value = dataList.value.concat(newItem);
    }

    const delItem = (index) => {
      dataList.value.splice(index, 1);
    }

    emit('register', {resetData, queryData, deleteItem, setProps, getAllData, getItem, replaceItem, addItem, delItem})

    return {
      dataList,
      queryData,
      resetData,
      loading,
      finished,
      propData,
      getAllData,
      addItem,
      getItem,
      delItem,
      deleteItem,
      replaceItem
    };
  },
});
</script>

<style scoped>

</style>