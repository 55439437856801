import request from '../utils/request.js'


// 删除充电站
export function delStation(id) {
    return request({
        url: '/device/station/del/' + id,
        method: 'post'
    })
}

// 查询区域列表
export function listRegion(query) {
    return request({
        url: '/device/region/list',
        method: 'get',
        params: query
    })
}

// 查询物业列表
export function listEstate(query) {
    return request({
        url: '/system/estate/list',
        method: 'get',
        params: query
    })
}

// 新增充电站
export function addStation(data) {
    return request({
        url: '/device/station/add',
        method: 'post',
        data: data
    })
}

// 充电站黑白名单详情
export function apartmentConfig(id) {
    return request({
        url: '/device/station/apartmentConfig/detail/' + id,
        method: 'get'
    })
}
// 修改充电站
export function updateApartmentConfig(data) {
  return request({
      url: '/device/station/apartmentConfig/set',
      method: 'post',
      data: data
  })
}

export function getStation(id) {
    return request({
        url: '/device/station/' + id,
        method: 'get'
    })
}

// 修改充电站
export function updateStation(data) {
    return request({
        url: '/device/station',
        method: 'put',
        data: data
    })
}

// 查询区域详细
export function getRegion(regionId) {
    return request({
        url: '/device/region/' + regionId,
        method: 'get'
    })
}

