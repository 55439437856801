import axios from "axios"        //引入axios
import { Toast} from 'vant';
import { useRouter } from "vue-router";
const router = useRouter()

const baseUrl = process.env.VUE_APP_API_URL
const service=axios.create({
  baseURL:baseUrl,
  timeout:60000,     //访问超时的时间限制
  responseType:'json',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});
// http request拦截器 添加一个请求拦截器
service.interceptors.request.use(
  config => {
      let token =  window.localStorage.getItem('accessToken');
      // console.log(token);
      if (token!==null && token !=='') config.headers['Authorization'] = token;
      return config;
  },
  error => {
      console.log("请求出错，错误信息如下：");
      console.log(error);
      Promise.reject(error);
  });
service.interceptors.response.use(response=>{
  // console.log('*******',response)
  //接收返回数据..
    const res = response.data
    if(res.code === 401){
      
      router.push({
        path: "/login"
      })
      return;
    }
  //判断返回数据是否存在状态code和错误提示信息..
    if(res.code!==200  && res.code!== "000000"){
      return Toast(res.msg)
    }

    
  //判断状态code是否为指定数值(000000)..
    // if(res.code  !== "000000"){
    //   return Toast(res.msg)
    // }
  return res
},err=>{
  return Toast(err.message)
})
export default service;