import request from '../utils/request.js'

export function getTemplateTypeList(params) {
    return request({
        url: '/chargeManage/template/page',
        method: 'get',
        params: params
    })
}

export function getStepGroupTypeList(params) {
    return request({
        url: '/chargeManage/stepGroup/page',
        method: 'get',
        params: params
    })
}


export function addCombination(data) {
    return request({
        method: 'POST',
        url: '/chargeManage/combination/add',
        data
    })

}