<template>
    <Form @submit="onSubmit">
      <CellGroup inset>
        <Field
          v-model="carType"
          is-link
          readonly
          name="carType"
          label="用户类型"
          @click="showPicker = true"
        />
        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker
            :columns="carTypeArr"
            @confirm="onConfirm"
            value-key="text"
            @cancel="showPicker = false"
          />
        </van-popup>
        <Field
          v-model="phone"
          name="phone"
          label="手机号码"
          type="number"
          maxlength=11
          autocomplete="off"
          placeholder="请输入手机号码"
          :rules="[{ required: true, message: '请填写正确手机号码', pattern:/^1[3|4|5|6|7|8|9][0-9]{9}$/,trigger:'blur'}]"
          onChan
        />
          <Field
            v-model="username"
            name="username"
            label="用户名称"
            autocomplete="off"
            placeholder="请输入用户姓名"
        />
        <Field
          v-model="result"
          is-link
          readonly
          name="area"
          label="地区选择"
          placeholder="点击选择省市区"
          @click="showArea = true"
          :rules="[{ required: true, message: '请选择省市区' }]"
        />
        <van-popup v-model:show="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="onConfirmArea"
            @cancel="showArea = false"
          />
        </van-popup>
          <Field
            v-model="address"
            name="address"
            label="详细地址"
            placeholder="请输入详细地址"
        />


      </CellGroup>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </Form>
</template>

<script>
import { ref } from 'vue';
import { Form, Field, CellGroup } from 'vant';
import {useRoute, useRouter} from 'vue-router'
import { areaList } from '@vant/area-data';
import FieldPopup from "@/components/FieldPopup";
import {addProperty,addPartner,editProperty,editPartner} from '@/api/client'

export default {
  components:{
    Form, Field, CellGroup,FieldPopup
  },
  setup(){
    const router = useRouter()
    const route = useRoute();
    const result = ref('');
    const showArea = ref(false);
    const username = ref('');
    const carType = ref('');
    const phone = ref();
    const address = ref('');
    const item = ref({})

    const showPicker = ref(false);
    const carTypeArr = [
      {
        state: 1,
        text: "物业"
      },
      {
        state: 2,
        text: "合伙人"
      },
    ]


    const data = {
      address: "",
      // bindAgentInfoId: window.localStorage.getItem('agentId'),
      // bindAgentInfoId: 66,
      id:0,
      cityCode: 0,
      cityName: "",
      countyCode: 0,
      countyName: "",
      phone: "",
      provinceCode: 0,
      provinceName: "",
      username: "",
    }

    if(route.query.item && route.query.state){
      item.value = JSON.parse(route.query.item)
      console.log('传递过来的参数2',item.value )
      if(Number(route.query.state) === 1){
        carType.value = carTypeArr[0].text
      }else{
        carType.value = carTypeArr[1].text
      }
      phone.value = item.value.phone
      username.value = item.value.username
      data.id = item.value.id
      data.provinceName = item.value.provinceName &&  item.value.provinceName
      data.provinceCode = item.value.provinceCode &&  item.value.provinceCode
      data.cityName = item.value.cityName &&  item.value.cityName
      data.cityCode = item.value.cityCode &&  item.value.cityCode
      data.countyName = item.value.countyName &&  item.value.countyName
      data.countyCode = item.value.countyCode &&  item.value.countyCode
      result.value = item.value.provinceName && item.value.cityName && item.value.countyName ?
               `${item.value.provinceName}/${item.value.cityName}/${item.value.countyName}` : null
      address.value = item.value.address ? item.value.address : null

    }


    const onConfirm = (value) => {
      console.log("value",value)
      carType.value = value.text
      showPicker.value = false
    };
     const onConfirmArea = (areaValues) => {
       showArea.value = false
       console.log("地区",areaValues)
       data.provinceCode = Number(areaValues[0].code)
       data.provinceName = areaValues[0].name
       data.cityCode = Number(areaValues[1].code)
       data.cityName = areaValues[1].name
       data.countyCode = Number(areaValues[2].code)
       data.countyName = areaValues[2].name
       result.value = areaValues
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
    };

    const onSubmit = (values) => {
      if(values){
        console.log('values',values)
        const {carType,phone,username,address} = values
        data.phone = phone
        data.username = username
        data.address = address
        // data.bindAgentInfoId = window.localStorage.getItem('agentId')

        console.log("data!!!!!!",data)

        if(carType === "物业"){
          // 新增物业客户
          console.log("是否为物业新增",route.query.add)
          if(route.query.add){
            addProperty(data).then(res => {
            console.log("res",res)
             if (res.code==200){
               router.push({path: `/client`})
             }
          })}else{
            console.log("修改物业信息")
            editProperty(data).then(res =>{
              console.log("res",res)
              if (res.code==200){
                router.push({path: `/client`})
              }
            })
          }

        }else{
          // 新增合伙人客户
          // console.log("新增合伙人客户")
          console.log("是否为合伙人新增",route.query.add)
          if(route.query.add){
            addPartner(data).then(res => {
            console.log("res",res)
              if (res.code==200){
                router.push({path: `/client`})
              }
          })}else{
            console.log("修改合伙人信息")
            editPartner(data).then(res=>{
              console.log("res修改合伙人信息返回值",res)
              if (res.code==200){
                router.push({path: `/client`})
              }
            })
          }

        }
      }
    };

    return {
      username,
      onSubmit,
      onConfirm,
      showPicker,
      onConfirmArea,
      carTypeArr,
      phone,
      address,
      carType,
      result,
      showArea,
      areaList
    };
  }

}
</script>

<style>

</style>