<template>
  <Form @submit="onSubmit">
    <CellGroup inset>
      <Field
          v-model="apartmentName"
          name="apartmentName"
          label="充电站名称"
          autocomplete="off"
          label-width="130"
          placeholder="请输入充电站名称"
          :rules="[{ required: true, message: '请填写充电站名称'}]"
      />
      <Field
          v-model="result"
          is-link
          readonly
          name="area"
          label="充电站地址"
          label-width="130"
          placeholder="点击选择省市区"
          @click="showArea = true"
          :rules="[{ required: true, message: '请选择省市区' }]"
      />
      <van-popup v-model:show="showArea" position="bottom">
        <van-area
            :area-list="areaList"
            @confirm="onConfirmArea"
            @cancel="showArea = false"
        />
      </van-popup>
      <van-field
          v-model="address"
          label="详细地址"
          placeholder="请输入详细地址"
          label-width="130"
          :rules="[{ required: true, message: '请填写详细地址' }]"
      />
      <div style="height: 44px;margin: 12px;">
        <van-button style="float: right;width: 100%;" plain icon="location" type="success" @click="positon">定位到此地址</van-button>
      </div>
<!--      <Field-->
<!--          v-model="regionName"-->
<!--          is-link-->
<!--          readonly-->
<!--          name="regionName"-->
<!--          label="充电站分类"-->
<!--          placeholder="选择充电站名称"-->
<!--          label-width="130"-->
<!--          @click="showPicker = true"-->
<!--      />-->
      <van-popup v-model:show="showPicker" position="bottom">
        <van-picker
            :columns="apartmentNameArr"
            @confirm="onConfirm"
            value-key="text"
            label-width="130"
            @cancel="showPicker = false"
        />
      </van-popup>
      <Field
          v-model="property"
          is-link
          readonly
          name="property"
          label="充电站物业"
          placeholder="选择充电站物业名称"
          label-width="130"
          @click="showPropertyPicker = true"
      />
      <van-popup v-model:show="showPropertyPicker" position="bottom">
        <van-picker
            :columns="propertyArr"
            @confirm="onConfirmProperty"
            value-key="text"
            @cancel="showPropertyPicker = false"
            label-width="130"
        />
      </van-popup>
      <Field
          v-model="contact"
          name="contact"
          label="充电站联系人"
          autocomplete="off"
          placeholder="请输入充电站联系人"
          label-width="130"
          :rules="[{ required: true, message: '请输入充电站联系人'}]"
      />
      <Field
          v-model="managerPhone"
          name="managerPhone"
          label="充电站站内联系电话"
          autocomplete="off"
          placeholder="请输入充电站站内联系电话"
          label-width="130"
          :rules="[{ required: true, message: '请填写充电站站内联系电话'}]"
      />
      <Field
          v-model="hotLine"
          name="hotLine"
          label="充电站服务电话"
          autocomplete="off"
          placeholder="请输入充电站服务电话"
          label-width="130"
          :rules="[{ required: true, message: '请填写充电站服务电话'}]"
      />
      <Field
          v-model="description"
          name="description"
          label="充电站备注"
          autocomplete="off"
          placeholder="请输入充电站备注"
          label-width="130"
      />
      <div id="map"></div>
    </CellGroup>
    <div style="margin: 16px;">
      <van-button block type="primary" native-type="submit" color="#07c160">
        修改
      </van-button>
      <van-button block type="default" @click="cancel">
        取消
      </van-button>
    </div>
  </Form>
</template>

<script>
import {onMounted, ref} from "vue";
import {CellGroup, Dialog, Field, Form, Notify, Toast} from "vant";
import FieldPopup from "@/components/FieldPopup";
import {areaList} from "@vant/area-data";
import {useRouter, useRoute} from "vue-router";
import {getRegion, getStation, listEstate, listRegion, updateStation} from "@/api/station";
import {getLocation} from "@/api/public";
let MultiMarker,map

export default {
  components: {
    Form, Field, CellGroup, FieldPopup
  },
  setup() {
    onMounted(() => {
      initMap(item.value.x,item.value.y);
      listRegion({regionId:item.value.regionId}).then(res=>{
        regionName.value = res.rows[0].regionName
      })
      apartmentName.value = item.value.apartmentName;
      result.value = item.value.provinceName + '/' + item.value.cityName + '/' + item.value.countyName;
      address.value = item.value.address;
      managerPhone.value = item.value.managerPhone;
      property.value = item.value.estateName;
      contact.value = item.value.managerUsername;
      hotLine.value = item.value.hotLine;
      description.value = item.value.description;

      listRegion().then(res => {
        for (let i = 0; i < res.rows.length; i++) {
          apartmentNameArr.value.push(res.rows[i].regionName);
        }
      });

      if (item.value.regionId!=null){
        getRegion(item.value.regionId).then(res => {
          for (let i = 0; i < res.rows.length; i++) {
            regionName.value=res.rows[i].regionName;
          }
        });
      }

      listEstate().then(res => {
        for (let i = 0; i < res.rows.length; i++) {
          propertyArr.value.push(res.rows[i].username);
        }
      })
    });
    const route = useRoute()
    const router = useRouter();
    const item = ref(JSON.parse(route.query.item));
    const apartmentName = ref();
    const property = ref();
    const estateId = ref();
    const regionName = ref();
    const regionId = ref();
    const address = ref();
    const contact = ref();
    const managerPhone = ref();
    const hotLine = ref();
    const description = ref();
    const result = ref('');
    const apartmentNameArr = ref([]);
    const propertyArr = ref([]);
    const showArea = ref(false);
    const showPicker = ref(false);
    const showPropertyPicker = ref(false);
    const simpleLocation=ref({})

    const onSubmit = () => {
      let data = {
        id:item.value.id,
        apartmentName: apartmentName.value,
        provinceCode: provinceCode.value,
        provinceName: provinceName.value,
        cityCode: cityCode.value,
        cityName: cityName.value,
        countyCode: countyCode.value,
        countyName: countyName.value,
        address:address.value,
        regionName:regionName.value,
        regionId:regionId.value,
        estateName:property.value,
        estateId:estateId.value,
        managerUsername:contact.value,
        managerPhone:managerPhone.value,
        hotLine:hotLine.value,
        description:description.value,
        simpleLocation:simpleLocation.value,
        simplePoint:simpleLocation.value
      };
      console.log(data)
      if (JSON.stringify(data.simplePoint)=="{}"){
        Toast('请点击定位获取地址信息');
        return;
      }else if (data.simplePoint.x==0||data.simplePoint.y==0){
        Toast('设备定位信息有误,请点击定位获取地址信息');
        return;
      }
      updateStation(data).then(res=>{
        if (res.code==200){
          Notify({type: 'success', message: '修改成功'});
          Dialog.alert({
            title: '充电站修改成功',
            message: '请登录网页端完善充电站配置及价格信息后使用。',
            theme: 'round-button',
          }).then(() => {
            // on close
            router.push({path: '/rechargeStation'})
          });
        }
      })
    };
    const initMap = (x, y) => {
      let info = document.getElementById("map");
      let center = new window.TMap.LatLng(y, x);
      //初始化地图
      map = new window.TMap.Map(info, {
        zoom: 17.2, //设置地图缩放级别
        center: center //设置地图中心点坐标
      });
      makeMapInfo(x, y);
    };
    const makeMapInfo = (x, y) => {
      let tencentmap=map;
      let e = new window.TMap.LatLng(y, x)

      //监听点击事件添加marker
      MultiMarker = new window.TMap.MultiMarker({
        id: 'marker-layer',
        styles: {
          'highlight': new window.TMap.MarkerStyle({
            'src': 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/marker-pink.png'
          })
        },
        map: tencentmap
      })
      const point = {
        id: new Date(),
        position: e
      }

      MultiMarker.setGeometries([])
      MultiMarker.add(point)
    };
     const positon=async()=>{
      let addressList = "";
       provinceName.value=item.value.provinceName?item.value.provinceName:provinceName.value
       cityName.value=item.value.cityName?item.value.cityName:cityName.value
       countyName.value=item.value.countyName?item.value.countyName:countyName.value
       if ((!provinceName.value || !cityName.value || !countyName.value)) {
         Toast('请选择省市区');
         return;
       }
       addressList = provinceName.value + cityName.value + countyName.value + address.value;
       let params = {
         key: 'O7GBZ-P7GKX-DBT4Q-7CFKN-JL2NJ-NWBG2',
         address: addressList,
         output: 'jsonp'
       }
      const data = await getLocation(params)
       map.setCenter(new window.TMap.LatLng(data.result.location.lat, data.result.location.lng))
       map.setZoom('17.2')
       simpleLocation.value =
          {
            y: Number(data.result.location.lat.toFixed(5)),
            x: Number(data.result.location.lng.toFixed(5))
          }
      const point = {
        id: new Date(),
        position: new window.TMap.LatLng(data.result.location.lat, data.result.location.lng)
      }
       MultiMarker.setGeometries([])
       MultiMarker.add(point)
    }

    const onConfirm = (value) => {
      regionName.value = value;
      showPicker.value = false;
      listRegion({regionName: value}).then(res => {
        regionId.value = res.rows[0].regionId;
      })
    };

    const onConfirmProperty = (value) => {
      property.value = value;
      showPropertyPicker.value = false;
      listEstate({username: value}).then(res => {
        estateId.value = res.rows[0].id;
      })
    };

    const cancel = () => {
      router.push({path: '/rechargeStation'})
    };
    const provinceCode = ref();
    const provinceName = ref();
    const cityCode = ref();
    const cityName = ref();
    const countyCode = ref();
    const countyName = ref();

    const onConfirmArea = (areaValues) => {
      showArea.value = false
      console.log("地区", areaValues)
      provinceCode.value = Number(areaValues[0].code)
      provinceName.value = areaValues[0].name
      cityCode.value = Number(areaValues[1].code)
      cityName.value = areaValues[1].name
      countyCode.value = Number(areaValues[2].code)
      countyName.value = areaValues[2].name
      item.value.provinceName=areaValues[0].name;
      item.value.provinceCode = areaValues[0].name
      item.value.cityCode = Number(areaValues[1].code)
      item.value.cityName = areaValues[1].name
      item.value.countyCode = Number(areaValues[2].code)
      item.value.countyName = areaValues[2].name
      result.value = areaValues
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
      positon();
    };
    return {
      apartmentName,
      contact,
      managerPhone,
      description,
      hotLine,
      property,
      estateId,
      regionId,
      regionName,
      address,
      result,
      showArea,
      showPicker,
      showPropertyPicker,
      apartmentNameArr,
      propertyArr,
      onSubmit,
      onConfirm,
      onConfirmArea,
      onConfirmProperty,
      cancel,
      areaList,
      positon,
      makeMapInfo
    }
  },
}
</script>

<style scoped>
#map{
  height: 300px;
}
</style>