<template>
  <List ref="listRef" :queryList="queryList" :params="params">
    <template #default>
      <div
        :key="index"
        v-for="(item, index) in dataList"
        style="margin-top: 12px"
        @click="handleChecked(item, index)"
      >
        <div
          class="item-content"
          :class="currentIndex === index ? 'item-content-checked' : ''"
        >
          <div>
            <div class="item-name">{{ item.username }}</div>
            <div class="item-phone" style="padding: '10px'">
              手机号：{{ item.phone }}
            </div>
          </div>

          <!-- <div class='checked-icon' v-if ="currentIndex === index">
                            <Icon name="success" color='rgb(8, 223, 134)'/>
                        </div> -->
        </div>
      </div>
    </template>
  </List>

  <div v-if="show" class="bottom-button">
    <Button
      type="primary"
      block
      style="flex: 1; background: skygreen"
      size="large"
      @click="goEdit(sample)"
      >编辑</Button
    >
    <Button
      type="danger"
      block
      style="flex: 1"
      size="large"
      @click="handleDelete(sample)"
      >删除</Button
    >
    <Dialog v-model:show="showDialog" show-cancel-button />
  </div>
</template>

<script>
import { ref, onMounted, toRaw, watch } from "vue";
import { useRouter } from "vue-router";
import { SwipeCell, Icon, Button, Dialog } from "vant";
import { ListPage, useListPage } from "@/components/ListPage";
import List from "./list.vue";
import { queryPropertyList, deleteProperty } from "@/api/client";

export default {
  components: {
    SwipeCell,
    Icon,
    Button,
    List,
    [Dialog.Component.name]: Dialog.Component,
    ListPage,
  },
  setup(props) {
    const router = useRouter();
    const dataList = ref([]);
    const listRef = ref(null);
    const sample = ref();
    const total = ref(0);
    const show = ref(false);
    const showDialog = ref(false);
    const currentIndex = ref(-1);

    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    let params = ref({
      pageNum: 0,
      pageSize: 10,
    });

    onMounted(() => {
      listRef.value.onLoad();
    });

    const queryList = (params) => {
      return new Promise((resolve, reject) => {
        queryPropertyList(params).then((res) => {
          if (res.code === 200) {
            total.value = res.total;
            if (params.pageNum > 1) {
              dataList.value = dataList.value.concat(res.rows);
            } else {
              dataList.value = res.rows;
            }
            resolve({
              length: dataList.value.length,
              total: total.value,
              datalist: dataList.value,
            });
          }
        });
      });
    };
    const cleanStatus = () => {
      show.value = false;
      currentIndex.value = -1;
    }

    const handleChecked = (item, index) => {
      sample.value = item;
      show.value = true;
      currentIndex.value = index;
      console.log("show", show.value);
    };
    const goEdit = (sample) => {
      const item = JSON.stringify(toRaw(sample));
      router.push({ path: "/client/add", query: { item: item, state: 1 } });
    };

    const handleDelete = (sample) => {
      showDialog.value = true;
      const id = sample.id;
      Dialog.confirm({
        message: "确认删除吗？",
      })
        .then(() => {
          // on confirm
          deleteProperty(id).then((res) => {
            if (res.code === 200) {
              dataList.value = toRaw(dataList.value).filter((item) => {
                return item.id !== id;
              });
              cleanStatus()
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    };

    return {
      show,
      showDialog,
      currentIndex,
      sample,
      listRef,
      queryList,
      goEdit,
      handleChecked,
      handleDelete,
      dataList,
      list,
      params,
      total,
      loading,
      finished,
      cleanStatus
    };
  },
};
</script>

<style lang="scss" scoped>
.item-content {
  position: relative;
  display: flex;
  padding: 12px;
  margin: 0 12px 12px 12px;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 25%);
  border-radius: 5px;
  /* border-bottom: 0.5px solid rgb(196, 191, 191); */
  .item-name {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
  }
  .item-phone {
    font-size: 13px;
    color: #666666;
    margin-top: 8px;
  }
}
.item-content-checked {
  background-color: #00d655;

  .item-name {
    color: #fff;
  }
  .item-phone {
    color: #fff;
  }
}

.checked-icon {
  position: absolute;
  right: 60px;
  top: 30px;
  width: 20px;
  height: 20px;
  border: 1px solid rgb(8, 223, 134);
  border-radius: 10px;
  text-align: center;
}
.custom-title {
  margin-right: 4px;
  vertical-align: middle;
}

.bottom-button {
  display: flex;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>