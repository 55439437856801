<template>
  <div class="container">
    <div class="step-1" v-if="showFirst">
      <div class="main-area">
        <div class="area" @click="onScanQrCode">
          <van-image
              width="40px"
              height="40px"
              :src="scanSrc"
          />
        </div>
        <div class="step">
          扫描设备二维码
        </div>
        <div style="text-align: center;margin-top: 50px"> 或手动输入设备序列号获取设备信息</div>
        <van-cell-group inset>
          <van-field v-model="deviceSn"
            name="deviceSn"
            class="input-s"
            placeholder="请输入或扫描设备序列号"/>
        </van-cell-group>
      </div>
      <div style="position: fixed;bottom: 0;width: 100%;height: 48px">
        <van-button color="#00d655" block @click="onClick">确定</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAgentInfo
} from "@/api/stat";
import {devicePage,updateDevice} from "@/api/device";
import {
  Grid,
  GridItem, Dialog, Loading, Toast, Notify
} from "vant";
import { ref ,getCurrentInstance} from "vue";
import { onMounted } from "@vue/runtime-core";
import { wxConfig } from "@/utils/wx.js";
import {useRouter} from "vue-router";


export default {
  components: {
    Grid,
    GridItem,
    Dialog,
    Loading
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const showFirst = ref(true);
    const showLoading = ref(false);
    const deviceValue = ref({})
    const deviceSn = ref();
    const positionSrc = require("@/assets/fixedPosition.png")
    const scanSrc = require("@/assets/scan.png")
    const onClick=()=>{
      let numReg = /^[0-9]*$/
      let numRe = new RegExp(numReg)
      if (numRe.test(deviceSn.value)&& deviceSn.value.length>0){
        devicePage({deviceSn:deviceSn.value}).then( res => {
          if (res.rows.length > 0) {
            Toast.loading({
              message: '设备已存在,即将跳转到设备编辑页面!',
              forbidClick: true,
              onClose: function () {
                router.push({path: '/device/detail', query: {item: JSON.stringify(res.rows[0])}})
              }
            });
          }else {
            Dialog.alert({
              message: '未查询到设备',
              theme: 'round-button',})
          }
        })
      }else {
        Notify({type: 'danger', message: '请输入正确的设备序列号 !'});
      }
    }
    const onScanQrCode = () => {
      wxConfig(proxy.$wx, () => {
        proxy.$wx.scanQRCode({
          needResult: 1,
          scanType: ["qrCode"],
          success: function (res) {
            const url = res.resultStr;
            //'http://charging.test.anchong001.com/web/wx?'
            let b = url.split("?");
            //['http://charging.test.anchong001.com/web/wx','sn=521200829145848$$outletNum=1']
            let c = b[1].split("="); //['sn','521200829145848$$outletNum','1']
            let d = c[1].split("$$"); //['521200829145848','outletNum']
            //调用查询详情
            deviceSn.value = d[0];
            devicePage({deviceSn:deviceSn.value}).then( res => {
              if (res.rows.length > 0) {
                Toast.loading({
                  message: '设备已存在,即将跳转到设备编辑页面!',
                  forbidClick: true,
                  onClose: function () {
                    router.push({path: '/device/detail', query: {item: JSON.stringify(res.rows[0])}})
                  }
                });
              }else {
                Dialog.alert({
                  message: '未查询到设备',
                  theme: 'round-button',})
              }
            })
          },
        });
      });
      // deviceSn.value = "801234567891234";
      // getDetail(deviceSn.value);
    }
    onMounted(() => {

    })
    return {
      onClick,
      positionSrc,
      showFirst,
      scanSrc,
      onScanQrCode,
      deviceValue,
      deviceSn,
      showLoading
    };
  }

}
</script>

<style lang="scss" scoped>
.container {
  background-color: #ffffff;
  //justify-content: center;
  //padding-top: 150px;
  .step-1{
    .main-area{
      display: flex;
      flex-direction: column;
      align-items: center;
      .area{
        margin-top: 200px;
        width: 120px;
        height: 120px;
        background-color: #00d655;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .step{
        margin: 24px 0;
        span{
          font-size: 36px;
        }
        font-size: 14px;
      }
      .tips{
        font-size: 14px;

      }
    }
  }
  .input-s {
    border: 1px solid #999999;
    margin-top: 10px;
    min-width: 260px;
  }

}

</style>