<template>
    <PullRefresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
        <List
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            v-if="list && list.length > 0 "
        >
        <slot> </slot>                  
        </List>
        <Empty description="暂时没有数据" v-if="(!list || list.length === 0) && !loading"/>
    </PullRefresh>
  
</template>

<script>
    import {  ref,reactive,unref, toRaw} from 'vue';
    import {List,Cell, Card,Dialog, Field, PullRefresh,Empty,} from 'vant';
export default {
    components:{
        List,
        Dialog,
        PullRefresh,
        Empty,
    },
    props:{
        // list:[],
        // total:Number,
        queryList:Function,
        params:{}
    },
    setup (props) {
        const loading = ref(false);
        const finished = ref(false);
        const refreshing = ref(false);
        const list = ref([]);

        const onLoad = () => {
            loading.value = true

            if (refreshing.value) {
                refreshing.value = false;
            }
            props.params.pageNum +=1
            props.queryList  &&  props.queryList(props.params).then(res => {
                if(res ){
                    list.value = toRaw(res.datalist) 
                    console.log("res.dataList",toRaw(res.datalist))
                loading.value =false
                if (res.length >= res.total) {
                    finished.value = true;
                }
                }
            })    
            
        };

        const onRefresh = () => {
            // 清空列表数据
            finished.value = false;

            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            loading.value = true;
            props.params.pageNum = 0
            console.log("刷新值",props.params.pageNum)
            onLoad();
        };


            return {
            list,
            onLoad,
            loading,
            finished,
            onRefresh,
            refreshing,

            }
    }

}
</script>

<style>

</style>