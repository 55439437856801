<template>
  <div class="mine_page">
    <div class="head_card">
      <div class="flex_label">
        <span>{{ company }}</span>
        <span>{{userType}}</span>
      </div>
      <div class="flex_label">
        <span>{{ phone }}</span>
        <span>主账号</span>
      </div>
      <!--      <div>
              <span>地址：</span>
              <span>{{ info.province + info.city + info.discrt }}</span>
            </div>-->
      <div>
        <span>注册时间：</span>
        <span>{{ createTime }}</span>
      </div>
    </div>
    <div>
      <!-- <van-cell-group> -->
        <van-cell
          title="提现账号"
          size="large"
          icon="balance-list"
          to="/withdrawAccount"
      ></van-cell>
<!--      <van-cell
          title="提现列表"
          size="large"
          icon="gold-coin"
          to="/withdrawal"
      ></van-cell>-->
      <van-cell
          title="联系客服"
          size="large"
          icon="phone"
          @click="phoneShow = true"
      ></van-cell>
      <!-- </van-cell-group> -->
    </div>
    <van-popup v-model:show="phoneShow" round position="bottom">
      <div class="popup_c">
        <van-cell :title="phoneNumber"/>
        <van-cell @click="callPhone" title="呼叫"/>
      </div>

      <div class="popup_c pop_cancel" @click="phoneShow = false">
        <van-cell title="取消"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {getUser, testPost} from "@/api/account";
import {onMounted} from "@vue/runtime-core";
import {ref} from "vue";
import {getUserProfile} from "@/api/user";
import {getAgentInfo} from "@/api/stat";

export default {
  setup() {
    /*const info = {
      company: "新能源",
      phone: 13223232323,
      province: "江苏省",
      city: "南京市",
      discrt: "高淳区",
      registerTime: "2022-01-01 09:00:47",
    };*/
    const phoneShow = ref(false);
    const phone = ref("");
    const phoneNumber = ref("4006568997");
    const company = ref("");
    const createTime = ref("");
    const userType = ref();
    const callPhone = () => {
      window.location.href = "tel:/" + phoneNumber.value;
    };


    onMounted(() => {
      // 获取用户信息
      /* getUser(1).then((res) => {
         console.log("res", res);
       });*/

      getAgentInfo().then(res => {
        if (res.data.businessUserType == 'ADMIN') {
          userType.value = '运营商';
        } else if (res.data.businessUserType == 'AGENT') {
          userType.value = '代理商';
        } else if (res.data.businessUserType == 'ESTATE') {
          userType.value = '物业';
        } else if (res.data.businessUserType == 'BROKER') {
          userType.value = '服务商';
        } else if (res.data.businessUserType == 'PARTNER') {
          userType.value = '合伙人';
        }
      })
    });
    getUserProfile().then(response => {
      console.log(response)
      phone.value = response.data.phonenumber
      company.value = response.data.nickName
      createTime.value = response.data.createTime
    });
    return { /*info, */phoneShow, phone, phoneNumber,callPhone, company, createTime,userType};
  },
};
</script>

<style lang="scss" scoped>
.mine_page {
  padding: 12px;

  .head_card {
    padding: 12px;
    border-radius: 8px;
    line-height: 32px;
    box-shadow: 0 1px 8px #e0e0e0;
    margin-bottom: 12px;
  }

  .flex_label {
    display: flex;
    justify-content: space-between;
  }

  .van-cell {
    position: relative;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      content: "";
      height: 1px;
      background-color: #e0e0e0;
    }
  }

  .popup_c {
    v:deep .van-cell__title {
      text-align: center !important;
    }
  }

  .pop_cancel {
    border-top: 4px solid #f5f5f5;
  }
}
</style>
