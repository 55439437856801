<template>
  <div class="box">
    <div class="top">
      <div class="top-search">
        <van-search
          v-model="name"
          placeholder="请输入充电站名称"
          @search="searchClick"
        />
      </div>
    </div>

    <van-checkbox-group
      v-for="(item, index) in dataList"
      v-model="idList"
      style="margin-left: 12px;"
      >
      <van-checkbox :name="item.id" shape="square" style="margin-top: 12px;"
        >{{ item.apartmentName }}{{ "(ID:" + item.id + ")" }}
      </van-checkbox>
    </van-checkbox-group>
    <div class="bottom_btn">
      <van-button
        block
        type="primary"
        color="#07c160"
        @click="onClick"
      >
        应用
      </van-button>
      <van-button block type="default" @click="cancel"> 取消 </van-button>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import ListPage from "@/components/ListPage/ListPage";
import { useListPage } from "@/components/ListPage";
import { useRoute, useRouter } from "vue-router";
import { listStation } from "@/api/order";
import { applyComb } from "@/api/priceTemplate";
import { Notify} from "vant";


export default {
  components: { ListPage },
  setup() {
    const route = useRoute();
    const id = ref(JSON.parse(route.query.id));
    const stationKindType = ref(JSON.parse(route.query.stationKindType));
    const apiUrl = ref("/device/station/list");

    const router = useRouter();
    const name = ref();
    const temporarilyList = ref([]);
    const temporarilyList1 = ref([]);
    const checked = ref();
    const isChecked = ref([]);
    const groupChecked = ref([]);
    const idList = ref([]);
    const dataList = ref([]);
    onMounted(() => {
      queryData();
    });
    const queryData = () => {
      listStation({pageNum:1,pageSize:10000,stationKindType:stationKindType.value}).then((res) => {
        if(res.code === 200){
          dataList.value = res.rows;
        }
      });
      listStation({ combId: id.value ,stationKindType:stationKindType.value}).then((resp) => {
        if(resp.code === 200){
          let data = resp.rows;
          let idArray = [];
          data.map((item)=>{
            idArray.push(item.id);
          })
          console.log('resp',idArray);
          idList.value = idArray;
        }
      });
    };

    const searchClick = (name) => {
      let pageSize = 10;
      setProps({ apiUrl, pageSize, requestParam: { apartmentName: name } });
      resetData();
    };

    const changeList = (item) => {
      console.log("groupChecked", item);
      
    };

    const checkedList = () => {
      console.log(checked.value);
    };

    const onClick = () => {
      
      let staIds = Object.values(idList.value);
      let data = {
        id: id.value,
        staIds: staIds,
      };
      console.log('submit',data);
      applyComb(data).then((res) => {
        if(res.code === 200){
          Notify({ type: 'success', message: '应用成功' });
          router.back()
        }
      });
    };

    const cancel = () => {
      router.push({ path: "/priceTemplate" });
    };

    return {
      checked,
      isChecked,
      dataList,
      name,
      searchClick,
      cancel,
      onClick,
      checkedList,
      changeList,
      groupChecked,
      queryData,
      idList
    };
  },
};
</script>

<style scoped>
</style>