import { Toast} from 'vant';
import axios from "axios" 

export function wxConfig(wxRef, cb) {
	let url;
	let ua = navigator.userAgent.toLowerCase();
	if (/iphone|ipad|ipod/.test(ua)) {
		url = window.location.href.split("#")[0];
	} else if (/android/.test(ua)) {
		url = window.location.href;
	}
  // let url =  /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.localStorage.getItem('scanUrl');
	let data = {
		url: "https://lfzc.lifang001.com",
		//取href
		realUrl: url
	}
  axios.post('https://soc.lifang001.com/toc/init/mpConfig',data).then((resp) => {
    
		if (resp.data.code === "000000") {
			wxRef.config({
				// debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: resp.data.data.appId, // 必填，公众号的唯一标识
				timestamp: Number(resp.data.data.timestamp), // 必填，生成签名的时间戳
				nonceStr: resp.data.data.nonceStr, // 必填，生成签名的随机串
				signature: resp.data.data.signature, // 必填，签名
				jsApiList: ['checkJsApi',"getLocation","scanQRCode","chooseWXPay"] // 必填，需要使用的JS接口列表
			})
			wxRef.ready( () => {
				cb && cb()
			})
		}else{
      Toast('获取微信接口失败')
		}
	});
}
