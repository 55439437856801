import request from '../utils/request.js'

// 物业列表(分页)
export function queryPropertyList(params){
    return request({
        method:'GET', 
        url:'/system/estate/list',
        params      
    })
}

// 添加物业
export function addProperty(data){
    return request({
        method:'POST',
        url:'/system/estate/add',
        data
    })
}

//编辑物业
export function editProperty(data){
    return request({
        method:'POST',
        url:'/system/estate/edit',
        data
    })

}

// 删除物业
export function deleteProperty(id){
    return request({
        method:'POST',
        url:  `/system/estate/del/${id}`,
    })  
}


//充电站列表
export function chargeStationList(params){
    return request({
        method:'GET',
        url:'/device/station/list',
        params
    })
}

// 合伙人列表(分页)
export function queryPartnerList(params){
    return request({
        method:'GET',
        url:'/system/partner/list',
        params
    })
}

// 新增合伙人
export function addPartner(data){
    return request({
        method:'POST',
        url:'/system/partner/add',
        data
    })
}

// 编辑合伙人
export function editPartner(data){
    return request({
        method:'POST',
        url:'/system/partner/update',
        data
    })
}

// 删除合伙人
export function deletePartner(id){
    return request({
        method:'POST',
        url:`/system/partner/del/${id}`,
    })  
}

// 合伙人充电站列表(分页)
export function queryStation(partnerId,params){
    return request({
        method:'GET',
        url:  `/system/partner/relPartnerApartment/list/${partnerId}`,
        params,
    }) 
}

// 分配充电站列表
export function assignStation(params){
    return request({
        method:'GET',
        url:'/system/partner/bind/apartment',
        params,
    })   
}

// 合伙人绑定充电站
export function bindPartnerStation(data){
    return request({
        method:'POST',
        url:'/system/partner/bind/apartment',
        data
    })
}

// 合伙人解绑充电站
export function unbindPartnerStation(data){
    return request({
        method:'POST',
        url:'/system/partner/unbind/apartment',
        data
    })
}

//查看上级利润
export function checkedProfit(data){
    return request({
        method:'POST',
        url:'/system/partner/ratioCheckStatus',
        data
    })

}
