<template>
  <div class="container">
    <div class="top-item-tab">
      <Tabs v-model:active="active" color="#00D655" swipeable>
        <Tab title="运营概况" >
          <div class="item-list">
            <Grid :column-num="2" :border="false" :center="false">
              <GridItem>
                <div class="top-list">
                  <div class="top-list-item">今日收入</div>
                  <div class="top-list-item-text">
                    {{ thisDayIncomeAmount / 100 }}
                    <span>元</span>
                  </div>
                </div>
              </GridItem>
              <GridItem>
                <div class="top-list">
                  <div class="top-list-item">账户余额</div>
                  <div :class="balance > 9999999 ? 'top-list-item-text-money' :'top-list-item-text'">
                    {{ balance / 100 }}
                    <span>元</span>
                  </div>
                </div>
              </GridItem>
              <GridItem>
                <div class="top-list">
                  <div class="top-list-item">今日订单</div>
                  <div class="top-list-item-text">
                    {{ totalCurrDayOrderCount }}
                    <span>笔</span>
                  </div>
                </div>
              </GridItem>
              <GridItem>
                <div class="top-list">
                  <div class="top-list-item">总收益</div>
                  <div :class="totalIncomeAmount > 9999999 ? 'top-list-item-text-money' :'top-list-item-text'">
                    {{ totalIncomeAmount / 100 }}
                    <span>元</span>
                  </div>
                </div>
              </GridItem>
            </Grid>
          </div>
        </Tab>
        <Tab title="设备概况">
          <div class="item-list">
            <Grid :column-num="3" :border="false" :center="false">
              <GridItem>
                <div class="top-list">
                  <div class="top-list-item">在线</div>
                  <div class="top-list-item-text">
                    {{ onlineDeviceCount }}
                    <span>台</span>
                  </div>
                </div>
              </GridItem>
              <GridItem>
                <!-- <div class="top-list-item">
                <div class="top-list-item" @click="toOfflineDevices">
                  离线
                  <div class="top-list-item-text">
                    {{ offlineDeviceCount }}
                  </div>
                </div> -->
                <div class="top-list" @click="toOfflineDevices">
                  <div class="top-list-item">离线</div>
                  <div class="top-list-item-text">
                    {{ offlineDeviceCount }}
                    <span>台</span>
                  </div>
                </div>
              </GridItem>
              <GridItem>
                <!-- <div class="top-list-item">
                  维护
                  <div class="top-list-item-text">
                    {{ isPubDeviceCount }}
                  </div>
                </div> -->
                <div class="top-list">
                  <div class="top-list-item">维护</div>
                  <div class="top-list-item-text">
                    {{ isPubDeviceCount }}
                    <span>台</span>
                  </div>
                </div>
              </GridItem>
              <GridItem>
                <!-- <div class="top-list-item">
                  总数
                  <div class="top-list-item-text">
                    {{ totalDeviceCount }}
                  </div>
                </div> -->
                <div class="top-list">
                  <div class="top-list-item">总数</div>
                  <div class="top-list-item-text">
                    {{ totalDeviceCount }}
                    <span>台</span>
                  </div>
                </div>
              </GridItem>
              <GridItem>
                <!-- <div class="top-list-item">
                  已安装
                  <div class="top-list-item-text">
                    {{ totalDeviceCount - notActiveDeviceCount }}
                  </div>
                </div> -->
                <div class="top-list">
                  <div class="top-list-item">已安装</div>
                  <div class="top-list-item-text">
                    {{ totalDeviceCount - notActiveDeviceCount }}
                    <span>台</span>
                  </div>
                </div>
              </GridItem>
              <GridItem>
                <!-- <div class="top-list-item">
                  未安装
                  <div class="top-list-item-text">
                    {{ notActiveDeviceCount }}
                  </div>
                </div> -->
                <div class="top-list">
                  <div class="top-list-item">未安装</div>
                  <div class="top-list-item-text">
                    {{ notActiveDeviceCount }}
                    <span>台</span>
                  </div>
                </div>
              </GridItem>
            </Grid>
          </div>
        </Tab>
        <Tab title="插座概况">
          <div class="item-list">
            <Grid :column-num="2" :border="false" :center="false">
              <GridItem>
                <!-- <div class="top-list-item">
                  充电中
                  <div class="top-list-item-text">
                    {{ chargingSockets }}
                  </div>
                </div> -->
                <div class="top-list">
                  <div class="top-list-item">充电中</div>
                  <div class="top-list-item-text">
                    {{ chargingSockets }}
                    <span>个</span>
                  </div>
                </div>
              </GridItem>
              <GridItem>
                <!-- <div class="top-list-item">
                  空闲
                  <div class="top-list-item-text">
                    {{ freeSockets }}
                  </div>
                </div> -->
                <div class="top-list">
                  <div class="top-list-item">空闲</div>
                  <div class="top-list-item-text">
                    {{ freeSockets }}
                    <span>个</span>
                  </div>
                </div>
              </GridItem>
              <GridItem @click="toBrokenSockets">
                <!-- <div class="top-list-item">
                  故障
                  <div class="top-list-item-text">
                    {{ brokenSockets }}
                  </div>
                </div> -->
                <div class="top-list">
                  <div class="top-list-item">异常</div>
                  <div class="top-list-item-text">
                    {{ brokenSockets }}
                    <span>个</span>
                  </div>
                </div>
              </GridItem>
              <GridItem>
                <!-- <div class="top-list-item">
                  关闭
                  <div class="top-list-item-text">
                    {{ closeSocketCount }}
                  </div>
                </div> -->
                <div class="top-list">
                  <div class="top-list-item">关闭</div>
                  <div class="top-list-item-text">
                    {{ closeSocketCount }}
                    <span>个</span>
                  </div>
                </div>
              </GridItem>
            </Grid>
          </div>
        </Tab>
      </Tabs>
    </div>

    <!-- <Swipe class="my-swipe" :autoplay="false" indicator-color="rgb(0, 214, 85)">
        <SwipeItem class="item">1</SwipeItem>
        <SwipeItem>2</SwipeItem>
        <SwipeItem>3</SwipeItem>
      </Swipe> -->

    <div class="top-item">
      <div class="second-floor">
        <div class="left">
          <div class="top">插座概况</div>
          <div class="content">
            <div class="item">
              <div class="tit">空闲</div>
              <div class="num green">{{ freeSockets }}</div>
            </div>
            <div class="item">
              <div class="tit">充电</div>
              <div class="num orange">{{ chargingSockets }}</div>
            </div>
            <div class="item"  @click="toBrokenSockets">
              <div class="tit">异常</div>
              <div class="num red">{{ brokenSockets }}</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="top">设备概况</div>
          <div class="content">
            <div class="item" @click="toOfflineDevices">
              <div class="tit">
                <van-image width="22px" height="22px" :src="offlineSrc" />
                <span>离线</span>
              </div>
              <div class="num gray">{{ offlineDeviceCount }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="top-item">
      <div class="item-list">
        <Grid :column-num="4" :border="false">
          <GridItem icon="cluster" text="充电站" to="/rechargeStation"/>
          <GridItem icon="graphic" text="设备" to="/device/list"/>
          <GridItem icon="after-sale" text="价格" to="/priceTemplate" v-if="userType"/>
          <GridItem icon="balance-list" text="财务" to="/withdrawal"/>
          <GridItem icon="bars" text="订单" to="/order/list"/>
          <GridItem icon="friends-o" text="客户" to="/client" v-if="userType"/>
          <GridItem icon="manager" text="用户" to="/consumer" v-if="userType"/>
        </Grid>
      </div>
    </div>
    <div class="top-item">
      <div class="item-list">
        <Grid :column-num="4" :border="false">
          <GridItem icon="shield-o" text="设备绑定" to="/deviceBind" v-if="userType"/>
          <GridItem icon="exchange" text="设备替换" to="/deviceReplace" v-if="userType"/>
        </Grid>
      </div>
    </div> -->
    <div class="top-item">
      <div class="card-search">
        <Tabs
          v-model:active="activeEchart"
          @click-tab="onClickTab"
          style="width: 140px"
          color="#00D655"
        >
          <Tab title="收入"></Tab>
          <Tab title="订单"></Tab>
          <Tab title="电量" v-if="userType"></Tab>
        </Tabs>
        <div class="picker">
          <div class="picker-search">
            <DropdownMenu>
              <DropdownItem
                v-model="dateVal"
                :options="dateOption"
                @change="dateChange"
              ></DropdownItem>
            </DropdownMenu>
          </div>
        </div>
      </div>

      <div
        ref="chartRef"
        :style="{ width: '100%', height: '300px', marginBottom: '100px' }"
      ></div>
    </div>
    <div>
    <van-dialog v-model:show="msgInfo"
     title="尊敬的用户"
     :cancelButtonColor="dialogCloseFlag ? '#c8c9cc':'#ee0a24'"
     :cancelButtonText="dialogCloseFlag ? `关闭（剩余${countDown}秒）`:'关闭'" 
     :closeOnClickOverlay="false"
     :showCancelButton="true"
     :cancelButtonDisabled="dialogCloseFlag"
     confirmButtonText="去续费"
     :confirmButtonColor="'#1989fa'"
     @confirm="msgDialogConfirm"
     @cancel="msgDialogClose"
     >
      <div class="notice-info-dialog">
        <div class="des">您有充电桩设备已到服务期，</div>
        <div class="des">为防止业务受影响，请及时续费！</div>
      </div>
    </van-dialog>
  </div>
  </div>
  
  
</template>

<script>
import { useRouter } from "vue-router";
import { ref, unref } from "vue";
import {
  Grid,
  GridItem,
  Tab,
  Tabs,
  DropdownMenu,
  DropdownItem,
  Swipe,
  SwipeItem,
  Dialog
} from "vant";
import echarts from "@/components/Echarts/echarts";
import {
  indexAggregate,
  indexNumeric,
  deviceCount,
  getAgentInfo,
  getIncome,
  getOrder,
  getElect
} from "@/api/stat";
import {getAgentHasRenewalDevice} from "@/api/device";
import { onMounted } from "@vue/runtime-core";
import moment from "moment";

export default {
  components: {
    Grid,
    GridItem,
    Tab,
    Tabs,
    DropdownMenu,
    DropdownItem,
    Swipe,
    SwipeItem,
    Dialog
  },
  setup() {
    const offlineSrc = require("@/assets/offline.png");
    const activeEchart = ref(0);
    const active = ref(0);
    const dateVal = ref(7);
    const countDown = ref(60);
    const countDownFlag = ref(false);
    const dialogCloseFlag = ref(true);
    const dateOption = [
      { text: "最近7天", value: 7 },
      { text: "最近30天", value: 29 },
      { text: "最近90天", value: 90 },
    ];
    const router = useRouter();
    const thisDayIncomeAmount = ref(0);
    const balance = ref(0);
    const totalCurrDayOrderCount = ref(0);
    const totalCurrDayEnergyCount = ref(0);
    const totalIncomeAmount = ref(0);
    const onlineDeviceCount = ref(0);
    const offlineDeviceCount = ref(0);
    const isPubDeviceCount = ref(0);
    const totalDeviceCount = ref(0);
    const notActiveDeviceCount = ref(0);
    const closeSocketCount = ref(0);
    const chargingSockets = ref(0);
    const freeSockets = ref(0);
    const brokenSockets = ref(0);
    const userType = ref();
    const params = ref([]);
    const chartRef = ref(null);
    const beginDate = ref("");
    const msgInfo = ref(false);
    const today = ref("");
    const powerGraph = [];
    const powerGraphLength = [];
    let chartInstance = null;
    const onClickTab = (val) => {
      activeEchart.value = val.name;
      getDate(dateVal.value);
    };
    const msgDialogClose = () =>{
      clearInterval(countDownFlag.value)
    }
    const msgDialogConfirm = () => {
      clearInterval(countDownFlag.value)
      router.push({path: `/renewal`})

    }
    
    const dateChange = (val) => {
      if (val === 29) {
        getDate(29);
        dateVal.value = 29;
      } else if (val === 90) {
        getDate(90);
        dateVal.value = 90;
      } else if (val === 7) {
        getDate(7);
        dateVal.value = 7;
      }
    };

    const initData = (x, y, val) => {
      const option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              width: 1,
              color: "#019680",
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: x,
          // data: powerGraphLength,
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              type: "solid",
              color: "rgba(226,226,226,0.5)",
            },
          },
          axisLabel: {
            interval: 0,
            showMaxLabel: true,
          },
          axisTick: {
            show: false,
          },
          name: "日期"
        },
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
            splitArea: {
              // show: true,
              // areaStyle: {
              //   color: ["rgba(255,255,255,0.2)", "rgba(226,226,226,0.2)"],
              // },
              interval: 1, //显示间隔的规律
              show: true,
              areaStyle: {//分隔区域的样式
                  color: ['rgba(255,255,255,0.03)', 'rgba(255,255,255,0)']
              }

            },
            splitLine: {  //坐标轴在grid区域中的分隔线（网格中的横线）
                show: true,
                lineStyle: {
                    color: ['#CCCCCC'],
                    width: 1,
                    type: 'dashed',
                }
            },
            axisLine: {
                show: false,
                minInterval:1,
                lineStyle: {
                    type: 'solid',
                    color: '#CCCCCC',//左边线的颜色
                    width: '1'//坐标线的宽度
                }
            },
            axisLabel: {
                textStyle: {
                    color: '#5A5A5A',//坐标值得具体的颜色

                }
            },
          },
        ],
        legend: {
           orient: 'horizontal'
        },
        grid: {
          left: "1%",
          right: "4%",
          top: "14%",
          bottom: 0,
          containLabel: true,
        },
        series: [
          {
            name: val,
            data: y,
            type: "line",
            itemStyle: {
              color: "#5ab1ef",
            },
          },
        ],
      };
      if (chartInstance) {
        chartInstance.setOption(option);
      } else {
        chartInstance = echarts.init(unref(chartRef));
        chartInstance.setOption(option);
      }
    };
    onMounted(() => {
      // 查询设备续费
      getAgentHasRenewalDevice().then((response) => {
          if (response.data) {
            msgInfo.value = true;
            countDownFlag.value = setInterval(()=>{
              countDown.value --;
              if(countDown.value === 0){
                clearInterval(countDownFlag.value)
                dialogCloseFlag.value = false;
              }
            },1000)
          }
        })
      getAgentInfo().then((res) => {
        if (res.data.businessUserType == "ESTATE") {
          userType.value = false;
        } else if (res.data.businessUserType == "PARTNER") {
          userType.value = false;
        } else {
          userType.value = true;
        }
      });

      indexAggregate().then((res) => {
        thisDayIncomeAmount.value = res.data.thisDayIncomeAmount;
        balance.value = res.data.balance;
        totalIncomeAmount.value = res.data.totalIncomeAmount;
      });
      indexNumeric().then((res) => {
        totalCurrDayOrderCount.value = res.data.totalCurrDayOrderCount;
        totalCurrDayEnergyCount.value =
          res.data.totalCurrDayEnergyCount.toFixed(2);
        onlineDeviceCount.value = res.data.onlineDeviceCount;
        offlineDeviceCount.value = res.data.offlineDeviceCount;
        isPubDeviceCount.value = res.data.isPubDeviceCount;
        totalDeviceCount.value = res.data.totalDeviceCount;
        notActiveDeviceCount.value = res.data.notActiveDeviceCount;
      });
      deviceCount().then((res) => {
        params.value = [];
        let list = res.rows;
        for (let i = 0; i < list.length; i++) {
          const item = list[i];
          if (item.activeStatus == 1) {
            /*  chargingSockets.value += item.busySocketCount;
              brokenSockets.value += item.expSocketCount;
              closeSocketCount.value += item.closeSocketCount
              freeSockets.value += (item.socketCount - item.busySocketCount - item.expSocketCount - item.closeSocketCount);*/
            chargingSockets.value += item.busySocketCount;
            brokenSockets.value += item.expSocketCount;
            closeSocketCount.value += item.closeSocketCount;
            freeSockets.value +=
              item.socketCount -
              item.busySocketCount -
              item.expSocketCount -
              item.closeSocketCount;
            if (item.expSocketCount != 0) {
              let obj = {
                apartmentId: item.apartmentId,
                apartmentName: item.apartmentName,
                device: [
                  { deviceName: item.deviceName, deviceSn: item.deviceSn },
                ],
              };
              if (params.value.length < 1) {
                params.value.push(obj);
              } else {
                let res = false;
                params.value.forEach((it) => {
                  if (it.apartmentId == obj.apartmentId) {
                    it.device.push({
                      deviceName: item.deviceName,
                      deviceSn: item.deviceSn,
                    });
                    res = true;
                  }
                });
                if (!res) {
                  params.value.push(obj);
                }
              }
            }
          }
        }
      });
      getDate(7);
    });
    const getDate = (dateNum) => {
      const time = Math.round(new Date() / 1000);
      if (!today.value) {
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const date = new Date().getDate();
        today.value = year + "/" + month + "/" + date;
      }
      const time2 = time - 60 * 60 * 24 * dateNum;
      const year2 = new Date(time2 * 1000).getFullYear();
      const month2 = new Date(time2 * 1000).getMonth() + 1;
      const date2 = new Date(time2 * 1000).getDate();

      beginDate.value = moment(year2 + "/" + month2 + "/" + date2).format(
        "YYYY-MM-DD"
      );
      let data = {
        dateEnum: "DAY",
        startTime: beginDate.value + " " + "00:00:00",
        endTime: moment(today.value).format("YYYY-MM-DD") + " " + "23:59:59",
      };
      console.log("data", data);
      let dateArr = new Date().getTimeListFromTwoDate(
        beginDate.value,
        moment(today.value).format("YYYY-MM-DD"),
        "DAY"
      );
      let dateArr1 = [];
      let x = [];
      let y = [];
      const num = Math.floor(dateArr.length / 8);
      let a = dateArr.forEach((item, index) => {
          // dateArr1.push(item);

        if ((index + 1) % num == 0) {
          dateArr1.push(item);
        }
      });

      if (activeEchart.value === 0) {
        //收入统计
        getIncome(data).then((res) => {
          dateArr1.map((item, index) => {
            let inde = res.data.filter(
              (it) => it.date == item + " " + "00:00:00"
            );
            if (inde.length > 0) {
              x.push(item.substring(5, item.length).replace("-", "/"));
              y.push(inde[0].intResult / 100);
            } else {
              x.push(item.substring(5, item.length).replace("-", "/"));
              y.push(0);
            }
          });
          initData(x, y, "收入(元)");
        });
      } else if (activeEchart.value === 1) {
        //订单统计
        getOrder(data).then((res) => {
          dateArr1.map((item, index) => {
            let inde = res.rows.filter(
              (it) => it.date == item + " " + "00:00:00"
            );
            if (inde.length > 0) {
              x.push(item.substring(5, item.length).replace("-", "/"));
              y.push(inde[0].intResult);
            } else {
              x.push(item.substring(5, item.length).replace("-", "/"));
              y.push(0);
            }
          });
          console.log("order", x, y);
          initData(x, y, "订单(个)");
        });
      }else if(activeEchart.value === 2){
        //电量统计
        getElect(data).then((res) => {
          dateArr1.map((item, index) => {
            let inde = res.data.filter(
              (it) => it.date == item + " " + "00:00:00"
            );
            if (inde.length > 0) {
              x.push(item.substring(5, item.length).replace("-", "/"));
              y.push((inde[0].doubleResult).toFixed(4));
            } else {
              x.push(item.substring(5, item.length).replace("-", "/"));
              y.push(0);
            }
          });
          console.log("order", x, y);
          initData(x, y, "电量(度)");
        });
      }
    };
    const toBrokenSockets = () => {
      router.push({
        path: "/brokenStation",
        query: { deviceSns: JSON.stringify(params.value) },
      });
    };

    const toOfflineDevices = () =>{
      router.push({ path: "/device/list" ,query:{deviceStatus:1}})
    }

    return {
      userType,
      thisDayIncomeAmount,
      balance,
      totalCurrDayOrderCount,
      totalCurrDayEnergyCount,
      onlineDeviceCount,
      offlineDeviceCount,
      isPubDeviceCount,
      totalDeviceCount,
      notActiveDeviceCount,
      chargingSockets,
      freeSockets,
      brokenSockets,
      closeSocketCount,
      toBrokenSockets,
      chartRef,
      powerGraph,
      powerGraphLength,
      today,
      beginDate,
      dateVal,
      dateOption,
      dateChange,
      activeEchart,
      onClickTab,
      offlineSrc,
      totalIncomeAmount,
      toOfflineDevices,
      active,
      msgInfo,
      countDown,
      countDownFlag,
      msgDialogClose,
      dialogCloseFlag,
      msgDialogConfirm
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  background-color: #ffffff;
}
.notice-info-dialog{
  margin: 16px 12px;
  .des{
    color: #ee0a24;
    font-size: 16px;
  }
  .bottom{
    font-size: 14px;
    color: #c8c9cc;
    line-height: 28px;
  }
}
.notice-info{
  margin: 16px 12px;
  .des{
    color: #ee0a24;
    font-size: 16px;
  }
  .bottom{
    font-size: 14px;
    color: #c8c9cc;
    line-height: 28px;
  }
}
.my-swipe {
  margin: 0 10px !important;
  .van-swipe-item {
    width: 370px !important;
    min-height: 150px;

    border: 1px solid rgb(0, 214, 85);
    border-radius: 5px;
  }
  .van-swipe-item:first-child {
    margin-right: 20px;
  }
  .van-swipe-item:nth-child(2) {
    margin-left: -18px;
  }
}

.card-search {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .picker {
    .picker-search {
      height: 100%;
      width: 100px;
    }
  }
}
.second-floor {
  display: flex;
  .left {
    flex: 3;
    border: 1px solid rgb(0, 214, 85);
    border-radius: 5px;
    .top {
      color: #ffffff;
      width: 60px;
      font-size: 12px;
      line-height: 24px;
      padding: 0 12px;
      text-align: center;
      border-bottom-right-radius: 5px;
      background-color: rgb(0, 214, 85);
    }
    .content {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      font-size: 14px;
      .item {
        flex: 1;
        text-align: center;
        .tit {
          height: 22px;
          line-height: 22px;
        }
        .num {
          font-size: 24px;
          padding-bottom: 8px;
          span {
            padding-left: 6px;
            font-size: 14px;
            color: #000001;
          }
        }
        .green {
          color: rgb(0, 214, 85);
        }
        .orange {
          color: #ff976a;
        }
        .red {
          color: #ee0a24;
        }
      }
    }
  }
  .right {
    margin-left: 12px;
    flex: 1;
    border: 1px solid rgb(0, 214, 85);
    border-radius: 5px;
    .top {
      color: #ffffff;
      width: 60px;
      font-size: 12px;
      line-height: 24px;
      padding: 0 12px;
      text-align: center;
      border-bottom-right-radius: 5px;
      background-color: rgb(0, 214, 85);
    }
    .content {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      font-size: 14px;
      .item {
        flex: 1;
        text-align: center;
        .tit {
          display: flex;
          justify-content: center;
          span {
            padding-left: 4px;
            padding-top: 2px;
          }
        }
        .num {
          font-size: 24px;
          padding-bottom: 8px;
          span {
            padding-left: 6px;
            font-size: 14px;
            color: #000001;
          }
        }
        .gray {
          color: #c8c9cc;
        }
      }
    }
  }
}
.top-item {
  background-color: #ffffff;
  padding: 10px 10px;
}
.top-item-tab{
  padding: 10px 0 0 10px;
}

.top-list {
  text-align: left;
  font-size: 14px;
  
  background-color: #00d655;
  margin: 0 12px 12px 0;
    color: #ffffff;
    text-align: center;
    padding: 12px 0;
    border-radius: 5px;

  .top-list-item {
  }

  .top-list-item-text {
    margin-top: 4px;
    font-size: 24px;
    color: #ffffff;
    span {
      padding-left: 2px;
      font-size: 14px;
      color: #ffffff;
    }
  }
  .top-list-item-text-money{
    margin-top: 4px;
    font-size: 18px;
    color: #ffffff;
    line-height: 26px;
    span {
      padding-left: 2px;
      font-size: 12px;
      color: #ffffff;
    }
  }
  .green {
    color: rgb(0, 214, 85);
  }
  .orange {
    color: #ff976a;
  }
  .red {
    color: #ee0a24;
  }
  .gray {
    color: #c8c9cc;
  }
  .blue{
    color: #1989fa;
  }
}
.top-list:nth-child(even) {
  margin: 0 0 12px 0!important;
}

.item-list {
  // border: 1px solid rgb(0, 214, 85);
  border-radius: 5px;
  padding: 1px;
  ::v-deep .van-grid-item__content{
    padding: 0;
  }
}
</style>