import request from '../utils/request.js'

export function getUserProfile() {
    return request({
        url: '/system/user/profile',
        method: 'get'
    })
}
//请求提现账号列表
export function getAttachList() {
  return request({
      url: '/agent/asset/attach/list',
      method: 'get'
  })
}
//请求银行列表
export function getBankList() {
  return request({
      url: '/transfer/channel/list',
      method: 'get'
  })
}
//新增提现账号
export function addAttachList(data) {
  return request({
      url: '/agent/asset/attach/add',
      method: 'post',
      data: data
  })
}
//修改提现账号
export function updateAttachList(data) {
  return request({
      url: '/agent/asset/attach/update',
      method: 'post',
      data: data
  })
}
//删除提现账号
export function delAttachList(id) {
  return request({
      url: '/agent/asset/attach/del/'+ id,
      method: 'post'
  })
}