<template>
  <van-form @submit="onSubmit">
    <van-cell-group inset>
      <van-field
          name="groupName"
          label="阶梯名称"
          placeholder="请输入阶梯名称"
          input-align="right"
          v-model="groupName"
          :rules="[{ required: true, message: '请填写阶梯名称' }]"
      />
      <van-field
          v-model="modeType"
          is-link
          readonly
          input-align="right"
          name="modeType"
          label="模式类型"
          placeholder="点击选择模式类型"
          @click="showPicker = true"
      />
      <van-field v-show="modeType=='电量模式'"
                 v-model="currentStartTime"
                 is-link
                 readonly
                 input-align="right"
                 name="currentStartTime"
                 label="开始时间"
                 placeholder="点击选择开始时间"
                 @click="showStartPopupType"
      />
      <van-popup v-model:show="showStartPopup" position="bottom" :style="{ height: '30%' }">
        <van-datetime-picker
            v-model="currentStartTime"
            type="time"
            title="开始时间"
            :min-hour="0"
            :max-hour="24"
            @click="showStart = true"
            @confirm="onStartConfirm"
            @cancel="showStartPopup=false"
        />
      </van-popup>
      <van-field v-show="modeType=='电量模式'"
                 v-model="currentEndTime"
                 is-link
                 readonly
                 input-align="right"
                 name="currentEndTime"
                 label="结束时间"
                 placeholder="点击选择结束时间"
                 @click="showEndPopupType"
      />
      <van-popup v-model:show="showEndPopup" position="bottom" :style="{ height: '30%' }">
        <van-datetime-picker
            v-model="currentEndTime"
            type="time"
            title="结束时间"
            :min-hour="0"
            :max-hour="24"
            @click="showEnd = true"
            @confirm="onEndConfirm"
            @cancel="showEndPopup=false"
        />
      </van-popup>


      <van-field v-if="modeType=='电量模式'"
                 v-model="tag"
                 is-link
                 readonly
                 input-align="right"
                 name="tag"
                 label="分时类型"
                 placeholder="点击选择分时类型"
                 @click="typeShow = true"
      />


      <van-field v-if="modeType=='功率模式'"
                 show-text="text" back-val="state"
                 v-model="maxPower"
                 name="maxPower"
                 label="最大功率"
                 placeholder="请输入最大功率"
                 input-align="right"
                 type="number"
                 :rules="[{ required: true, message: '请填写最大功率' },{pattern:/^(0\.?\d{0,2}|[1-9]\d*\.?\d{0,2})$/,message:'请输入正数'}]"
      />
      <van-field name="billSteps" label="梯度" label-class="td" input-align="right">
        <template #input>
          <div class="list" v-if="modeType=='功率模式'">
            <div class="item" v-for="(item, index) in ladderList" :key="index">
              <div class="lf">
                <span>小于</span>
                <van-field input-align="center" v-model="ladderList[index].w" type="number"
                           :rules="[{pattern:/^(0\.?\d{0,2}|[1-9]\d*\.?\d{0,2})$/,message:'请输入正数'}]"/>
                <span>W</span>
                <van-field input-align="center" v-model="ladderList[index].p" type="number"
                           :rules="[{pattern:/^(0\.?\d{0,2}|[1-9]\d*\.?\d{0,2})$/,message:'请输入正数'}]"/>
                <span>元/小时</span>
              </div>
              <van-icon
                  v-if=" index
                < ladderList.length - 1"
                  @click="ladderListDelete(index)"
                  class="add"
                  name="close"
              />
              <van-icon
                  v-else
                  @click="ladderListAdd"
                  class="add"
                  name="add-o"
              />
            </div>
          </div>
          <div class="list" v-if="modeType=='时间模式'">
            <div class="item" v-for="(item, index) in ladderList" :key="index">
              <div class="lf">
                <span>小于</span>
                <van-field input-align="center" v-model="ladderList[index].w"
                           :rules="[{pattern:/^(0\.?\d{0,2}|[1-9]\d*\.?\d{0,2})$/,message:'请输入正数'}]"/>
                <span>小时</span>
                <van-field input-align="center" v-model="ladderList[index].p"
                           :rules="[{pattern:/^(0\.?\d{0,2}|[1-9]\d*\.?\d{0,2})$/,message:'请输入正数'}]"/>
                <span>元</span>
              </div>
              <van-icon
                  v-if="index < ladderList.length - 1"
                  @click="ladderListDelete(index)"
                  class="add"
                  name="close"
              />
              <van-icon
                  v-else
                  @click="ladderListAdd"
                  class="add"
                  name="add-o"
              />
            </div>
          </div>
          <div class="list" v-if="modeType=='电量模式'">
            <div class="item" v-for="(item, index) in ladderList" :key="index">
              <div class="lf lf_two">
                <span>基础电费(元/度)</span>
                <van-field
                    input-align="center"
                    v-model="ladderList[index].w"
                    :rules="[{pattern:/^(0\.?\d{0,2}|[1-9]\d*\.?\d{0,2})$/,message:'请输入正数'}]"/>
                <span>服务费(元/度)</span>
                <van-field
                    input-align="center"
                    v-model="ladderList[index].p"
                    :rules="[{pattern:/^(0\.?\d{0,2}|[1-9]\d*\.?\d{0,2})$/,message:'请输入正数'}]"
                />
              </div>
              <van-icon
                  v-if="index < ladderList.length - 1"
                  @click="ladderListDelete(index)"
                  class="add"
                  name="close"
              />
              <van-icon
                  v-else
                  @click="ladderListAdd"
                  class="add"
                  name="add-o"
              />
            </div>
          </div>
        </template>
      </van-field>
    </van-cell-group>
    <div style="margin: 16px">
      <van-button round block type="primary" native-type="submit" @submit="onSubmit">
        确定
      </van-button>
      <van-button round block type="default" @click="cancel">
        取消
      </van-button>
    </div>

    <van-popup v-model:show="showPicker" position="bottom" round>
      <van-picker
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model:show="typeShow" position="bottom" round>
      <van-picker
          title="分时类型"
          :columns="['尖', '峰', '平', '谷']"
          @confirm="onTypeConfirm"
          @cancel="typeShow = false"
      />
    </van-popup>
  </van-form>
</template>

<script>
import {onMounted, ref} from 'vue';
import moment from 'moment';
import {addStepGroup, editStepGroup, getTemplateType, queryStepGroup} from "@/api/programmeAdd";
import {Notify} from "vant";
import {useRoute, useRouter} from "vue-router";

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const id = ref(JSON.parse(route.query.id))
    const groupName = ref('');
    const tag = ref('');
    const modeType = ref('');
    const maxPower = ref('');
    const showPicker = ref(false)
    const checked = ref(1);
    const ladderList = ref([/*{w: '', p: ''}*/])
    const columns = []
    const typeShow = ref(false)
    const showStart = ref(false)
    const showEnd = ref(false)
    const showStartPopup = ref(false)
    const showEndPopup = ref(false)
    const currentStartTime = ref('00:00');
    const currentEndTime = ref('23:59');

    const resultValues = {
      billSteps: [],
      // values: []
    }
    const isDel = ref();
    const ownerId = ref();
    const ownerType = ref();
    const updateId = ref();
    const createId = ref();

    onMounted(() => {
      queryStepGroup(id.value).then(res => {
        groupName.value = res.data.groupName;
        maxPower.value = res.data.billSteps[res.data.billSteps.length-1].end;
        isDel.value = res.data.isDel;
        ownerId.value = res.data.ownerId;
        ownerType.value = res.data.ownerType;
        updateId.value = res.data.updateId;
        createId.value = res.data.createId;
        if (res.data.modeType == 1) {
          modeType.value = '功率模式';
          res.data.billSteps.forEach(item => {
            ladderList.value.push({
              w: item.end,
              p: item.price / 100
            })
          })
        } else if (res.data.modeType == 2) {
          modeType.value = '时间模式';
          res.data.billSteps.forEach(item => {
            ladderList.value.push({
              w: item.end / 60,
              p: item.price / 100
            })
          })
        } else if (res.data.modeType == 3) {
          modeType.value = '电量模式';
          res.data.billSteps.forEach(item => {
            if (item.tag == 1) {
              tag.value = '尖';
            } else if (item.tag == 2) {
              tag.value = '峰';
            } else if (item.tag == 3) {
              tag.value = '平';
            } else if (item.tag == 4) {
              tag.value = '谷';
            }
            ladderList.value.push({
              w: item.price / 100,
              p: item.servicePrice / 100
            })
          })
        }
      })

    })


    const showStartPopupType = () => {
      showStartPopup.value = true;
    };
    const showEndPopupType = () => {
      showEndPopup.value = true;
    };
    const onConfirm = (value) => {
      modeType.value = value;
      showPicker.value = false;
    }
    const ladderListAdd = () => {
      const list = ladderList.value;
      list.push({w: '', p: ''});
      ladderList.value = list;
    }
    const ladderListDelete = (index) => {
      const list = ladderList.value;
      list.splice(index, 1);
      ladderList.value = list;
    }
    const onTypeConfirm = (value) => {
      tag.value = value
      typeShow.value = false;
    }
    const onStartConfirm = (value) => {
      currentStartTime.value = value
      showStart.value = false;
      showStartPopup.value = false
    }
    const onEndConfirm = (value) => {
      currentEndTime.value = value
      showEnd.value = false;
      showEndPopup.value = false
    }
    getTemplateType().then(res => {
      for (let i = 0; i < res.data.length; i++) {
        columns.push(res.data[i].dictLabel)
      }
    })

    const onSubmit = (values) => {
      if (values.tag == '尖') {
        values.tag = 1
      } else if (values.tag == '峰') {
        values.tag = 2
      } else if (values.tag == '平') {
        values.tag = 3
      } else if (values.tag == '谷') {
        values.tag = 4
      }
      for (let i = 0; i < ladderList.value.length; i++) {
        if (values.modeType == '功率模式') {
          if (maxPower.value < ladderList.value[ladderList.value.length - 1].w) {
            Notify({type: 'danger', message: '梯度功率不能大于' + maxPower.value + 'W !'});
          }
          if (maxPower.value !== ladderList.value[ladderList.value.length - 1].w) {
            Notify({type: 'danger', message: '梯度功率必须设置到' + maxPower.value + 'W !'});
          }
          if (i == 0) {
            resultValues.billSteps.push({
              end: ladderList.value[i].w,
              price: ladderList.value[i].p * 100,
              servicePrice: '',
              start: 0,
              tag: tag.value
            })
          } else {
            resultValues.billSteps.push({
              end: ladderList.value[i].w,
              price: ladderList.value[i].p * 100,
              start: ladderList.value[i - 1].w,
            })
          }
        } else if (values.modeType == '时间模式') {
          resultValues.billSteps.push({
            end: ladderList.value[i].w * 60,
            price: ladderList.value[i].p * 100,
            start: ladderList.value[i].w * 60,
          })
        } else if (values.modeType == '电量模式') {
          resultValues.billSteps.push({
            start: moment.duration(currentStartTime.value).as('minutes'),
            end: moment.duration(currentEndTime.value).as('minutes'),
            price: ladderList.value[i].w * 100,
            servicePrice: ladderList.value[i].p * 100,
            tag: values.tag
          })
        }
      }
      resultValues.groupName = values.groupName;
      resultValues.maxPower = values.maxPower;
      if (parseInt(resultValues.billSteps[resultValues.billSteps.length-1].end) > parseInt(resultValues.maxPower)){
        Notify({type: 'danger', message: '功率不能大于最大功率 !'});
        resultValues.billSteps=[]
        return;
      }else if (parseInt(resultValues.billSteps[resultValues.billSteps.length-1].end) < parseInt(resultValues.maxPower)){
        Notify({type: 'danger', message: parseInt(resultValues.billSteps[resultValues.billSteps.length-1].end)+'到'+parseInt(resultValues.maxPower)+'功率阶段未设置 !'});
        resultValues.billSteps=[]
        return;
      }
      if (values.modeType == '功率模式') {
        resultValues.modeType = 1;
      } else if (values.modeType == '时间模式') {
        resultValues.modeType = 2;
      } else if (values.modeType == '电量模式') {
        resultValues.modeType = 3;
      }
      resultValues.id = id.value;
      resultValues.updateId = updateId.value;
      resultValues.createId = createId.value;
      resultValues.ownerId = ownerId.value;
      resultValues.ownerType = ownerType.value;
      resultValues.isDel = isDel.value;
      editStepGroup(resultValues).then(result => {
        if (result.code == 200) {
          router.push({path: `/priceTemplate`});
          // 成功通知
          Notify({type: 'success', message: '修改阶梯组成功'});
        } else {
          Notify({type: 'danger', message: '修改阶梯组失败'});
        }
      })
    }
    const cancel = () => {
      router.push({path: '/priceTemplate'})
    };
    return {
      showStartPopup,
      showEndPopup,
      showStartPopupType,
      showEndPopupType,
      modeType,
      tag,
      currentStartTime,
      currentEndTime,
      groupName,
      maxPower,
      showPicker,
      checked,
      columns,
      ladderList,
      typeShow,
      showStart,
      showEnd,
      cancel,
      onSubmit,
      onConfirm,
      onTypeConfirm,
      onStartConfirm,
      onEndConfirm,
      ladderListDelete,
      ladderListAdd
    };
  },
}
</script>


<style lang="scss" scoped>
.list {
  width: 100%;

  .item {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    position: relative;

    .lf {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .van-field {
        padding: 0 !important;
        width: 70px;
      }

      & > span {
        font-size: 12px;
      }
    }

    .lf_two {
      .van-field {
        padding: 0 !important;
        width: 50px;
      }
    }

    .add {
      position: absolute;
      right: -16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .label {
    margin-top: -10px;
    margin-right: 10px;
    font-size: var(--van-cell-font-size);
    color: var(--van-field-label-color);
  }
}

:deep(.td) {
  width: 30px !important;
}
</style>
