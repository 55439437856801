import request from '../utils/request.js'

// 查询手机用户列表
export function queryPhoneList(params){

    return request({
        method:'GET',
        url:'/member/insiders/page', 
        params      
    })
}

// 搜索手机用户
export function phoneSearch(phone,params){
    return request({
        method:'GET',
        url:`/member/find/${phone}`, 
        params  
    })
}

// 手机用户余额刷新
export function reloadPhoneBalance(data){
    return request({
        method:'POST',
        url:'/member/update/asset',
        data
    })
}

// 查询电卡用户列表
export function queryCardList(params){
    return request({
        method:'GET',
        url:'/chargeCard/insiders/page',
        params
    })
}

// 搜索电卡
export function searchCardNum(cardNo,params){
    return request({
        method:'GET',
        url:`/chargeCard/find/${cardNo}`,
        params
    })
}

// 电卡用户余额刷新
export function reloadCardBalance(data){
    return request({
        method:'POST',
        url:'/chargeCard/update/asset',
        data
    })
}

